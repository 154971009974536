import React, { createContext, useContext } from 'react';
import { Provider, FetchProviderProps } from 'use-http';
import createPersistedState from 'use-persisted-state';
import Login from './Login';

export const url =
  process.env.NODE_ENV === 'production'
    ? 'https://api.sbly.com'
    : 'http://localhost:3000';

export const key = 'sbly-tools:accessToken';
const USER_TYPE_KEY = 'userType';

const useAccessToken = createPersistedState(key);
const useUserType = createPersistedState(USER_TYPE_KEY);

interface AuthorizationProps {
  children: JSX.Element;
}

interface AuthContextType {
  userType: string | null;
  accessToken: string | null;
  handleLogin: (userType: string, accessToken: string) => void;
  handleLogout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  userType: null,
  accessToken: null,
  handleLogin: () => {},
  handleLogout: () => {},
});

export default function Authorization({ children }: AuthorizationProps) {
  const [accessToken, setAccessToken] = useAccessToken<string | null>(null);
  const [userType, setUserType] = useUserType<string | null>(null);

  const handleLogin = (userType: string, accessToken: string) => {
    setUserType(userType);
    setAccessToken(accessToken);
    window.location.reload();
  };

  const handleLogout = () => {
    setUserType(null);
    setAccessToken(null);
    window.location.reload();
  };

  const options: FetchProviderProps['options'] = {
    interceptors: {
      request({ options }: { options: RequestInit }) {
        if (accessToken) {
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }
        return options;
      },
    },
  };

  if (accessToken) {
    return (
      <AuthContext.Provider
        value={{ userType, accessToken, handleLogin, handleLogout }}
      >
        <Provider url={url} options={options}>
          {children}
        </Provider>
      </AuthContext.Provider>
    );
  }

  return (
    <Login
      onSubmit={(userType, accessToken) => handleLogin(userType, accessToken)}
    />
  );
}

export const useAuth = () => useContext(AuthContext);
