import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { arrayIncludesObject } from 'pages/Dashboard/utils/arrayHelpers';
import { GraphKeyType } from 'pages/Dashboard/models/GraphData';
import { formatLabel } from 'pages/Dashboard/utils/format';

interface GraphKeysSelectionProps {
  graphKeyTypes: GraphKeyType[];
  keyType: string;
  leftAxisKeys: GraphKeyType[];
  rightAxisKeys: GraphKeyType[];
  handleUpdateGraph: (newMetricGraph: GraphKeyType) => void;
}

function GraphKeysSelection({
  leftAxisKeys,
  rightAxisKeys,
  graphKeyTypes,
  keyType,
  handleUpdateGraph,
}: GraphKeysSelectionProps) {
  return (
    <Box>
      <Typography variant="button">{keyType}</Typography>
      {graphKeyTypes
        .filter(key => key.type === keyType)
        .map(key => {
          return (
            <Box key={key.name} sx={{ display: 'flex', height: 20 }}>
              <Checkbox
                size="small"
                checked={
                  arrayIncludesObject(leftAxisKeys, key) ||
                  arrayIncludesObject(rightAxisKeys, key)
                }
                onChange={() => handleUpdateGraph(key)}
              />
              <Typography variant="body2">{formatLabel(key.name)}</Typography>
            </Box>
          );
        })}
    </Box>
  );
}

export default GraphKeysSelection;
