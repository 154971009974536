import React from 'react';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

interface ControlledDateRangePickerProps {
  dateRange: DateRange<Dayjs>;
  onDateRangeChange: (newRange: DateRange<Dayjs>) => void;
}

function ControlledDateRangePicker({
  dateRange,
  onDateRangeChange,
}: ControlledDateRangePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={dateRange}
        onChange={newRange => onDateRangeChange(newRange)}
        sx={{ width: 365 }}
      />
    </LocalizationProvider>
  );
}

export default ControlledDateRangePicker;
