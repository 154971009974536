import React, { useEffect, useState } from 'react';
import find from 'lodash/find';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { dashboardService } from 'pages/Dashboard';
import { FilterColumn } from 'pages/Dashboard/models/Settings';
import { Segment } from 'pages/Dashboard/models/CustomDimension';
import FilterSelection from '../filter/FilterSelection';

interface EditSegmentsProps {
  open: boolean;
  onClose: (close: boolean) => void;
  dimension: string;
  segments: Segment[];
  onSegmentsUpdate: (segments: Segment[]) => void;
  columns: FilterColumn[];
  onDelete: () => void;
}

function EditSegments({
  segments,
  onSegmentsUpdate,
  dimension,
  open,
  onClose,
  columns,
  onDelete,
}: EditSegmentsProps) {
  const [currSegments, setCurrSegments] = useState<Segment[]>(segments);
  const [newSegName, setNewSegName] = useState('');
  const column = find(columns, column => column.key === segments[0]?.columnKey);

  useEffect(() => {
    setCurrSegments(segments);
  }, [segments]);

  const handleUpdateSegment = (
    idx: number,
    predicate: string,
    value: string | number,
  ) => {
    const updatedSegments = [...currSegments];
    updatedSegments[idx]['filterKey'] = predicate;
    updatedSegments[idx]['value'] = value;
    setCurrSegments(updatedSegments);
  };

  const handleDeleteSegment = (idx: number) => {
    const newSegments = [...currSegments];
    newSegments.splice(idx, 1);
    setCurrSegments(newSegments);
  };

  const handleAddNewSegment = (
    filter: FilterColumn,
    predicate: string,
    value: string | number,
  ) => {
    setCurrSegments([
      ...currSegments,
      {
        dimensionName: dimension,
        segmentName: newSegName,
        filterKey: predicate,
        columnKey: filter.key,
        columnName: filter.name,
        value: value,
      },
    ]);
    setNewSegName('');
  };

  const handleApply = () => {
    onSegmentsUpdate(currSegments);
    onClose(true);
  };

  const handleDeleteDimension = () => {
    onDelete();
    onClose(true);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { borderRadius: '0.75rem' } }}
      >
        <DialogContent>
          <Typography variant="h6">Custom Dimension: {dimension}</Typography>
          {currSegments.map((segment, idx) => {
            return (
              <Box key={segment.segmentName} sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography fontSize={15} variant="button">
                    Segment: {segment.segmentName}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    color="error"
                    onClick={() => handleDeleteSegment(idx)}
                    sx={{ ml: 1 }}
                  >
                    delete segment
                  </Button>
                </Box>
                <FilterSelection
                  filters={column ? [column] : columns}
                  filterOperations={dashboardService.settings.filterOperations}
                  filterType={'column'}
                  onAddFilter={(filter, predicate, value) =>
                    handleUpdateSegment(idx, predicate, value)
                  }
                  label="update"
                  presetPredicate={segment.filterKey}
                  presetValue={segment.value}
                />
              </Box>
            );
          })}
          <Divider sx={{ pt: 1 }} />
          <TextField
            id="new-segment-name"
            label="new segment name"
            value={newSegName}
            onChange={e => {
              setNewSegName(e.target.value);
            }}
            sx={{ width: 240, mt: 3 }}
          />
          <FilterSelection
            filters={column ? [column] : columns}
            filterOperations={dashboardService.settings.filterOperations}
            filterType={'column'}
            onAddFilter={(filter, predicate, value) =>
              handleAddNewSegment(filter, predicate, value)
            }
            label="new"
          />
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleApply}
              sx={{ width: 175 }}
            >
              apply updates
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteDimension}
              sx={{ width: 175 }}
            >
              remove dimension
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EditSegments;
