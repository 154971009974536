import React, { useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@material-ui/core/Typography';

import { FilterColumn } from 'pages/Dashboard/models/Settings';

interface SelectedDimensionsProps {
  dimensions: FilterColumn[];
  onDimensionsChange: (newDimensionFilters: FilterColumn[]) => void;
}

function SelectedDimensions({
  dimensions,
  onDimensionsChange,
}: SelectedDimensionsProps) {
  const [addDimensionsDialogOpen, setAddDimensionsDialogOpen] = useState(false);

  const handleAdd = () => {
    setAddDimensionsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setAddDimensionsDialogOpen(false);
  };

  const handleCheckedChange = (dimensionKey: string) => {
    const updatedDimensions = dimensions.map(dimension => {
      if (dimension.key === dimensionKey) {
        dimension.checked = !dimension.checked;
      }
      return dimension;
    });
    onDimensionsChange(updatedDimensions);
  };

  return (
    <Box mt="0">
      <Typography variant="overline">selected dimensions</Typography>
      <Box>
        {dimensions
          .filter(dimension => dimension.checked)
          .map(dimension => {
            const label = `${dimension.name}`;
            return (
              <Chip
                sx={{ mr: 0.5, mb: 0.5 }}
                size="small"
                variant="outlined"
                label={label}
                key={dimension.key}
                onDelete={() => handleCheckedChange(dimension.key)}
              />
            );
          })}
        <IconButton onClick={handleAdd} size="small" component="label">
          <AddCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Box>
      <Dialog onClose={handleDialogClose} open={addDimensionsDialogOpen}>
        <DialogTitle>
          <Typography variant="button">select dimension</Typography>
        </DialogTitle>
        <List>
          {dimensions
            .filter(dimension => !dimension.checked)
            .map(dimension => (
              <ListItem
                key={dimension.key}
                button
                onClick={() => {
                  handleCheckedChange(dimension.key);
                }}
              >
                <ListItemText>{dimension.name}</ListItemText>
              </ListItem>
            ))}
        </List>
      </Dialog>
    </Box>
  );
}

export default SelectedDimensions;
