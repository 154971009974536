type NestedObject = { [key: string]: NestedObject | string[] };

export function gatherArrayValuesInNestedObj(obj: NestedObject): string[] {
  let result: string[] = [];

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      result = result.concat(obj[key] as string[]);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      const nestedArrayValues = gatherArrayValuesInNestedObj(
        obj[key] as NestedObject,
      );
      result = result.concat(nestedArrayValues);
    }
  }

  return result;
}

export function getNodeAtLastArrayItem(groups: any[], templateGroup: any): any {
  const [currKey, ...remainingKeys] = groups;
  const currObj = templateGroup[currKey];

  if (remainingKeys.length === 0) {
    return currObj;
  }

  return getNodeAtLastArrayItem(remainingKeys, currObj);
}

export function generatePaths(header: string[], nestedObj: any) {
  const allHeaders: string[][] = [header];

  const startingNode = getNodeAtLastArrayItem(header, nestedObj);
  function traverse(prevHeader: string[], node: any): void {
    if (Array.isArray(node)) return;

    const keys = Object.keys(node);
    keys.forEach(key => {
      const currHeader = [...prevHeader, key];
      allHeaders.push(currHeader);
      traverse(currHeader, node[key]);
    });
  }
  traverse(header, startingNode);

  return allHeaders;
}
