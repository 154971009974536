import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';

import {
  FACEBOOK_DATA_SOURCE,
  OUTBRAIN_DATA_SOURCE,
  QUORA_DATA_SOURCE,
  TABOOLA_DATA_SOURCE,
  TABOOLA_REAL_TIME_DATA_SOURCE,
  TWITTER_DATA_SOURCE,
} from 'pages/Dashboard/utils/constants';
import Alert from 'pages/Dashboard/components/helpers/Alert';

interface DataSourcesProps {
  dataSources: string[];
  onDataSourcesChange: (dataSources: string[]) => void;
}

export default function DataSources({
  dataSources,
  onDataSourcesChange,
}: DataSourcesProps) {
  const [minDataSourcesAlertOpen, setMinDataSourcesAlertOpen] = useState(false);
  const [sources, setSources] = useState(dataSources);

  const handleDataSource = (
    event: React.MouseEvent<HTMLElement>,
    newDataSources: string,
  ) => {
    if (newDataSources === null) {
      setMinDataSourcesAlertOpen(true);
    } else {
      setMinDataSourcesAlertOpen(false);
      onDataSourcesChange([newDataSources]);
    }
    setSources([newDataSources]);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={minDataSourcesAlertOpen}
        onClose={() => setMinDataSourcesAlertOpen(false)}
      >
        <Alert
          onClose={() => setMinDataSourcesAlertOpen(false)}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Please select at least one data source.
        </Alert>
      </Snackbar>
      <Typography variant="overline">Data Sources</Typography>
      <Box>
        <ToggleButtonGroup
          exclusive
          value={sources[0]}
          size="small"
          onChange={handleDataSource}
          aria-label="text alignment"
        >
          <ToggleButton
            value={FACEBOOK_DATA_SOURCE}
            aria-label={FACEBOOK_DATA_SOURCE}
          >
            {FACEBOOK_DATA_SOURCE}
          </ToggleButton>
          <ToggleButton
            value={OUTBRAIN_DATA_SOURCE}
            aria-label={OUTBRAIN_DATA_SOURCE}
          >
            {OUTBRAIN_DATA_SOURCE}
          </ToggleButton>
          <ToggleButton
            value={QUORA_DATA_SOURCE}
            aria-label={QUORA_DATA_SOURCE}
          >
            {QUORA_DATA_SOURCE}
          </ToggleButton>
          <ToggleButton
            value={TABOOLA_DATA_SOURCE}
            aria-label={TABOOLA_DATA_SOURCE}
          >
            {TABOOLA_DATA_SOURCE}
          </ToggleButton>
          <ToggleButton
            value={TABOOLA_REAL_TIME_DATA_SOURCE}
            aria-label={TABOOLA_REAL_TIME_DATA_SOURCE}
          >
            Taboola (RT)
          </ToggleButton>
          <ToggleButton
            value={TWITTER_DATA_SOURCE}
            aria-label={TWITTER_DATA_SOURCE}
          >
            {TWITTER_DATA_SOURCE}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}
