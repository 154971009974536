import React from 'react';
import { TextField } from '@material-ui/core';

import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  detailsTextField: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

interface TextInputProps {
  value: string;
  name: string;
  label: string;
  fontSize: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TextInput(props: TextInputProps) {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      margin="none"
      size="small"
      label={props.label}
      name={props.name}
      value={props.value}
      className={classes.detailsTextField}
      onChange={props.onChange}
      inputProps={{style: {fontSize: props.fontSize}}}
      InputLabelProps={{style: {fontSize: props.fontSize}}}
    />
  )
}
