import { CurrencyType, NumberType, PercentType } from 'utils/valueTypes';

const MetricValueTypes = {
  adset_budget: CurrencyType,
  budget_utilization: PercentType,
  amazon_revenue: CurrencyType,
  clicks: NumberType,
  conversion_clicks: NumberType,
  conversions_value: CurrencyType,
  conversion_views: NumberType,
  cost_per_click: CurrencyType,
  cost_per_impression: CurrencyType,
  cpa_actions_num: NumberType,
  effective_rpm: CurrencyType,
  impressions: NumberType,
  imps_per_page_view: NumberType,
  link_ctr: PercentType,
  outbrain_revenue: CurrencyType,
  page_rpm: CurrencyType,
  page_views: NumberType,
  primis_revenue: CurrencyType,
  profit: CurrencyType,
  profit_margin: PercentType,
  purchase_value: CurrencyType,
  revenue: CurrencyType,
  revenue_impressions: NumberType,
  spend: CurrencyType,
  spent: CurrencyType,
  visible_impressions: NumberType,
};

export default MetricValueTypes;
