import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@mui/material/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RemoveIcon from '@material-ui/icons/Remove';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { key } from 'pages/Authorization';
import { sblyApiService } from 'pages/PostScheduler/service';
import DateTime from 'pages/PostScheduler/Facebook/components/DateTimePicker';
import FacebookPages from 'pages/VideoScheduler/components/FacebookPages';
import ScheduledVideos from 'pages/VideoScheduler/components/ScheduledVideos';
import Timeslots from 'pages/VideoScheduler/components/Timeslots';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  accordionRoot: {
    width: '100%',
  },
  accordionHeader: {
    fontSize: theme.typography.pxToRem(16),
  },
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  linkTextField: {
    marginBottom: theme.spacing(3),
    width: 485,
  },
  pageDropdown: {
    width: 100,
  },
  messageTextField: {
    marginLeft: theme.spacing(1),
    width: 600,
  },
  fieldsContainer: {
    display: 'flex',
  },
}));

const defaultPageFields = [
  { id: uuid(), page: '', message: '', pubTime: '' },
  { id: uuid(), page: '', message: '', pubTime: '' },
  { id: uuid(), page: '', message: '', pubTime: '' },
  { id: uuid(), page: '', message: '', pubTime: '' },
];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FacebookVideoScheduler() {
  const classes = useStyles();

  const accessToken = localStorage.getItem(key)?.replace(/['"]+/g, '');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageFields, setPageFields] = useState<{ [key: string]: string }[]>(
    JSON.parse(JSON.stringify(defaultPageFields)),
  );

  function handleInputChange(id: string, input: string, type: string) {
    const newPageFields = pageFields.map(field => {
      if (id === field.id) {
        field[type] = input;
      }
      return field;
    });
    setPageFields(newPageFields);
  }

  function handleAddFields() {
    setPageFields([
      ...pageFields,
      { id: uuid(), page: '', message: '', pubTime: '' },
    ]);
  }

  function handleRemoveFields(id: string) {
    const values = [...pageFields];
    values.splice(
      values.findIndex(value => value.id === id),
      1,
    );
    setPageFields(values);
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessAlertOpen(false);
    setErrorAlertOpen(false);
    setErrorMessage('');
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const completedPageFields = pageFields.filter(pageField =>
      Object.values(pageField).every(value => value !== ''),
    );

    let data = {
      link,
      details: completedPageFields,
    };

    let failed: [];
    const resp = await sblyApiService.post(
      'facebook-videos/schedule-videos',
      data,
      {
        headers,
      },
    );
    ({ failed } = resp.data);

    if (failed.length === 0) {
      setSuccessAlertOpen(true);
      setLink('');
      setPageFields([
        { id: uuid(), page: '', message: '', pubTime: '' },
        { id: uuid(), page: '', message: '', pubTime: '' },
        { id: uuid(), page: '', message: '', pubTime: '' },
        { id: uuid(), page: '', message: '', pubTime: '' },
      ]);
    } else {
      setErrorAlertOpen(true);
    }

    setLoading(false);
  }

  return (
    <div>
      <header className={classes.header}>
        <Typography variant="h5">Facebook Video Scheduler</Typography>
      </header>
      <Paper>
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="link"
            name="link"
            className={classes.linkTextField}
            value={link}
            type="url"
            label="video link"
            onChange={e => setLink(e.target.value)}
          />
          {pageFields.map(pageField => (
            <div key={pageField.id} className={classes.fieldsContainer}>
              <FacebookPages
                page={pageField.page}
                onChange={(page: string) => {
                  handleInputChange(pageField.id, page, 'page');
                }}
              />
              <DateTime
                onChange={(time: string) => {
                  handleInputChange(pageField.id, time, 'pubTime');
                }}
                size="medium"
              />
              <TextField
                id="message"
                name="message"
                className={classes.messageTextField}
                value={pageField.message}
                onChange={e =>
                  handleInputChange(pageField.id, e.target.value, 'message')
                }
                type="text"
                variant="outlined"
                label="message"
              />
              <IconButton onClick={handleAddFields}>
                <AddIcon />
              </IconButton>
              <IconButton
                disabled={pageFields.length === 1}
                onClick={() => handleRemoveFields(pageField.id)}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            endIcon={<PostAddIcon />}
            sx={{ mb: 2, mt: 1 }}
          >
            Schedule
          </Button>
        </form>
      </Paper>
      <div className={classes.accordionRoot}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-timeslot-content"
            id="panel-timeslot-header"
          >
            <Typography className={classes.accordionHeader}>
              Schedule By Timeslots
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Timeslots
              headers={headers}
              setSuccess={setSuccessAlertOpen}
              setError={setErrorAlertOpen}
              setErrorMessage={setErrorMessage}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-scheduled-content"
            id="panel-scheduled-header"
          >
            <Typography className={classes.accordionHeader}>
              Scheduled Videos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ScheduledVideos headers={headers} />
          </AccordionDetails>
        </Accordion>
      </div>
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Success! Video scheduled.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Scheduling error. {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
