import React from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';

import CustomDatePicker from 'pages/Creator/components/CustomDatePicker';

interface DateRangePickerProps {
  width: number;
  size?: 'small' | 'medium';
  startDate: Dayjs;
  endDate: Dayjs;
  onStartDateChange: (date: Dayjs) => void;
  onEndDateChange: (date: Dayjs) => void;
}

function DateRangePicker({
  width,
  size,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: DateRangePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction={'row'} spacing={1} width={width}>
        <CustomDatePicker
          label="start"
          date={startDate}
          size={size}
          onChange={(newDate: Dayjs) => {
            onStartDateChange(newDate);
          }}
        />
        <CustomDatePicker
          label="end"
          date={endDate}
          size={size}
          onChange={(newDate: Dayjs) => {
            onEndDateChange(newDate);
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
