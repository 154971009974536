import React, { useEffect, useState } from 'react';
import every from 'lodash/every';

import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridSelectionModel,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import SearchBar from 'material-ui-search-bar';

import GraphData from 'pages/Dashboard/models/GraphData';
import ScalingDiagnostic, { ScalingDiagnosticsQueryData } from 'pages/Dashboard/models/ScalingDiagnostics'
import GridCellExpand from 'pages/Dashboard/components/dataTable/GridCellExpand';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';
import setTableColumns from 'pages/Dashboard/utils/dataTableHelpers';
import TableToolbar from 'pages/Dashboard/components/dataTable/toolbar/TableToolbar';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';

const COLUMN_VISIBILITY_MODEL_KEY = 'columnVisibilityModelKey';

interface DataTableProps {
  dataSource: string;
  loading: boolean;
  metricsData: PerformanceData[];
  getGraphData: () => Promise<GraphData>;
  getScalingDiagnostics: (data: ScalingDiagnosticsQueryData) => Promise<ScalingDiagnostic[]>;
}

function renderCellExpand(params: GridRenderCellParams<string>) {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
    />
  );
}

function createColumnVisibilityModel(
  columnsData: PerformanceData,
  localStorageKey: string,
) {
  const visbilityModel: GridColumnVisibilityModel = {};
  const storedModel = localStorageGet(localStorageKey);
  const selectedColumns = Object.keys(columnsData);

  if (storedModel) {
    const storedColumns = Object.keys(storedModel);
    const newColumns = selectedColumns.filter(
      col => !storedColumns.includes(col),
    );

    newColumns.forEach(column => {
      visbilityModel[column] = true;
    });
    storedColumns.forEach(column => {
      if (selectedColumns.includes(column)) {
        visbilityModel[column] = storedModel[column];
      }
    });
  } else {
    selectedColumns.forEach(column => {
      visbilityModel[column] = true;
    });
  }

  return visbilityModel;
}

function DataTable({
  dataSource,
  loading,
  metricsData,
  getGraphData,
  getScalingDiagnostics
}: DataTableProps) {
  const columnVisibilityByDataSourceKey = `${COLUMN_VISIBILITY_MODEL_KEY}-${dataSource}`;

  const [rows, setRows] = useState(metricsData);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    createColumnVisibilityModel(
      metricsData[0],
      columnVisibilityByDataSourceKey,
    ),
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [searchTerms, setSearchTerms] = useState('');
  const columns: GridColDef[] = setTableColumns(
    Object.keys(columnVisibilityModel),
    renderCellExpand,
  );

  useEffect(() => {
    setRows(metricsData);
    setSearchTerms('');
    setColumnVisibilityModel(
      createColumnVisibilityModel(
        metricsData[0],
        columnVisibilityByDataSourceKey,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData]);

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel,
  ) => {
    const visibleColumns = Object.entries(newModel).filter(
      ([column, visible]) => {
        return visible;
      },
    );
    const hiddenColumns = Object.entries(newModel).filter(
      ([column, visible]) => !visible,
    );

    const hiddenColumnsLastModel = Object.fromEntries([
      ...visibleColumns,
      ...hiddenColumns,
    ]);

    localStorageSet(columnVisibilityByDataSourceKey, hiddenColumnsLastModel);
    setColumnVisibilityModel(hiddenColumnsLastModel);
  };

  const handleSearch = (searchVal: string) => {
    const valuesToMatch = searchVal.split(' ');
    const matchedRows = metricsData.filter(dataRow => {
      const rowContents = Object.values(dataRow).join(' ');
      return every(valuesToMatch, word => {
        return rowContents.toLowerCase().includes(word.toLowerCase());
      });
    });
    setRows(matchedRows);
  };

  const cancelSearch = () => {
    setSearchTerms('');
    setRows(metricsData);
  };

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <SearchBar
        value={searchTerms}
        onChange={searchVal => {
          setSearchTerms(searchVal);
          handleSearch(searchVal);
        }}
        onCancelSearch={cancelSearch}
      />
      <Box sx={{ width: '100%', height: 1000 }}>
        <DataGrid
          checkboxSelection
          disableSelectionOnClick
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
          loading={loading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel =>
            handleColumnVisibilityChange(newModel)
          }
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: TableToolbar,
          }}
          componentsProps={{
            toolbar: {
              metricsData,
              selectionModel,
              columnVisibilityModel,
              getGraphData,
              getScalingDiagnostics,
              onColumnOrderChange: handleColumnVisibilityChange,
            },
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            '& > :first-of-type': {
              position: 'absolute',
              left: 5,
              top: 5,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1,
            },
            '& .MuiDataGrid-footerContainer': {
              pb: 2,
              pt: 2,
              borderBottom: '1px solid lightgray',
            },
            '& .MuiDataGrid-selectedRowCount': {
              mt: '-50px',
            },
          }}
        />
      </Box>
    </Paper>
  );
}

export default DataTable;
