import React from 'react';

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface RemoveButtonProps extends LoadingButtonProps {
  loading: boolean;
  onClick: () => void;
}

function RemoveButton({ loading, onClick, sx, ...rest }: RemoveButtonProps) {
  return (
    <LoadingButton
      variant="contained"
      color="secondary"
      loading={loading}
      onClick={onClick}
      endIcon={<DeleteOutlineIcon />}
      sx={sx}
      {...rest}
    >
      Remove
    </LoadingButton>
  );
}

export default RemoveButton;
