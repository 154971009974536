import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface URLFormProps {
  url: string;
  onURLUpdate: (urls: string) => void;
  size?: 'small' | 'medium';
}

function URLForm({ url, onURLUpdate, size }: URLFormProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%', mt: 2 }}>
          <TextField
            fullWidth
            id="urlInput"
            label="URL"
            variant="outlined"
            value={url}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onURLUpdate(event.target.value);
            }}
            inputProps={{
              autoComplete: 'off',
            }}
            size={size ?? 'medium'}
            sx={{ mb: 2 }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default URLForm;
