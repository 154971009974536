import { formatCurrency, formatAsPercent } from 'utils/formatNumber';
import { formatDate } from 'utils/formatDate';
import ArticleCandidate from 'pages/Creator/models/ArticleCandidate';

export default function formatCandidates(candidates: ArticleCandidate[]) {
  return candidates?.map(candidate => {
    return {
      ...candidate,
      posted_at_pst: formatDate(candidate.posted_at_pst),
      organic_reach: candidate.organic_reach?.toLocaleString('en-US'),
      ctr: formatAsPercent(candidate.ctr),
      cpm: formatCurrency(candidate.cpm),
      rpm: formatCurrency(candidate.rpm),
      total_revenue: formatCurrency(candidate.total_revenue),
      percent_reach: formatAsPercent(candidate.percent_reach),
    };
  });
}
