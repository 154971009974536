import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

interface MultiURLFormProps {
  urls: string[];
  onURLsUpdate: (urls: string[]) => void;
  size?: 'small' | 'medium';
}

function MultiURLForm({ urls, onURLsUpdate, size }: MultiURLFormProps) {
  const handleURLUpdate = (url: string, idx: number) => {
    const updatedURLs = [...urls];
    updatedURLs[idx] = url;
    onURLsUpdate(updatedURLs);
  };

  const handleAddURL = () => {
    onURLsUpdate([...urls, '']);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '100%', mt: 2 }}>
        {urls.map((url, idx) => {
          return (
            <TextField
              fullWidth
              key={idx}
              id="urlInput"
              label="URL"
              variant="outlined"
              value={url}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleURLUpdate(event.target.value, idx);
              }}
              inputProps={{
                autoComplete: 'off',
              }}
              size={size ?? 'medium'}
              sx={{ mb: 2 }}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          width: '2%',
          display: 'flex',
          pl: 1,
          pb: 1.5,
          alignItems: 'end',
        }}
      >
        <IconButton onClick={handleAddURL}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MultiURLForm;
