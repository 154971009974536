import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import find from 'lodash/find';
import round from 'lodash/round';
import utcPlugin from 'dayjs/plugin/utc';

import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowId,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Caption } from 'pages/PostScheduler/Facebook/models/Caption';
import { formatDateTimeUTC } from 'utils/formatDate';
import { sblyApiService } from 'pages/PostScheduler/service';
import { pages } from 'pages/PostScheduler/Facebook/fields/pages';
import ControlledDatePicker from 'shared/components/ControlledDatePicker';
import ControlledDateTimePicker from 'shared/components/ControlledDateTimePicker';
import MessageTextField from 'pages/PostScheduler/Facebook/components/MessageTextField';
import PageTimeSlots from 'pages/PostScheduler/Facebook/components/PageTimeSlots';
import RemoveButton from 'shared/components/RemoveButton';
import SchedulingError from 'pages/PostScheduler/Facebook/components/SchedulingError';

dayjs.extend(utcPlugin);

interface Posts {
  id: string;
  queued_page: string;
  domain: string;
  slug: string;
  original_author?: string;
  previous_pages?: string;
  link: string;
  message: string;
  impressions?: string;
  clicks?: string;
  ctr?: string;
  publishTime?: string;
}

interface QueuedPostsProps {
  headers: { Authorization: string };
  onAddCaptions: (post: Caption) => void;
}

interface TimeSlots {
  id: string;
  name: string;
  time_slots: string[];
}

interface TimeSlotsUsage {
  [postID: string]: {
    available: GridRowId[];
    used: GridRowId[];
  };
}

export default function QueuedPosts(props: QueuedPostsProps) {
  const columns: GridColDef[] = [
    {
      field: 'queued_page',
      headerName: 'Queued Page',
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        (find(pages, { id: params.row.queued_page }) as any).name,
    },
    { field: 'slug', headerName: 'Name', width: 400 },
    {
      field: 'clicks',
      headerName: 'Clicks',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 95,
    },
    {
      field: 'time_slot',
      headerName: 'Time Slot',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const pageID = params.row.queued_page;
        const postID = params.row.id;
        const pageTimeSlots = find(timeSlots, { id: pageID })?.time_slots || [];
        const noPageIDSelectionModel = selectionModel.map(post => {
          const stringPostId = post as string;
          return stringPostId.split('-')[0];
        });
        return (
          <PageTimeSlots
            rowSelected={noPageIDSelectionModel.includes(postID)}
            timeSlots={pageTimeSlots}
            timeSlotsUsage={timeSlotsUsage[pageID]}
            onChange={time => {
              handleTimeChange(time, postID);
            }}
            onUsageChange={pageUsage => {
              setTimeSlotsUsage({
                ...timeSlotsUsage,
                ...{ [pageID]: pageUsage },
              });
            }}
          />
        );
      },
    },
    {
      field: 'caption',
      headerName: 'New Caption',
      width: 500,
      renderCell: (params: GridRenderCellParams) => {
        const postID = params.row.id;
        return (
          <MessageTextField
            message={params.row.message}
            label="caption"
            onChange={caption => {
              onCaptionChange(caption, postID);
            }}
          />
        );
      },
    },
    { field: 'previous_message', headerName: 'Previous Caption', width: 500 },
    {
      field: 'available_messages',
      headerName: 'All',
      width: 30,
      renderCell: (params: GridRenderCellParams) => {
        const availableMessages = params.row.available_messages || [];
        return (
          <Tooltip
            title={
              <List>
                {availableMessages.map((message: string, index: number) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${index + 1}. ${message}`} />
                  </ListItem>
                ))}
              </List>
            }
          >
            <Typography>{availableMessages.length}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'publish_time',
      headerName: 'Publish Time',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TableCell
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <ControlledDateTimePicker
              label="Set Date Time"
              onValueChange={newDateTime =>
                handleTimeChange(newDateTime, params.row.id, true)
              }
              sx={{ '& .MuiOutlinedInput-input': { pt: 1, pb: 0.5 } }}
            />
          </TableCell>
        );
      },
    },
    { field: 'previous_pages', headerName: 'Page History', width: 300 },
    {
      field: 'ctr',
      headerName: 'CTR',
      valueGetter: (params: GridValueGetterParams) =>
        `${round(params.row.ctr ?? 0, 2)}%`,
      width: 95,
    },
    { field: 'impressions', headerName: 'Reach', type: 'number', width: 95 },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [pageInput, setPageInput] = useState('');
  const [facebookPage, setFacebookPage] = useState(pages[0]);
  const [queuedPosts, setQueuedPosts] = useState<Posts[]>([]);
  const [timeSlots, setTimeSlots] = useState<TimeSlots[]>([]);
  const [timeSlotsUsage, setTimeSlotsUsage] = useState<TimeSlotsUsage>({});
  const [date, setDate] = useState<Dayjs>(dayjs().add(1, 'day'));

  const [postsLoading, setPostsLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const getQueuedPosts = useCallback(async () => {
    setPostsLoading(true);
    const response = await sblyApiService.get('facebook-posts/queued-posts', {
      headers: props.headers,
    });
    setQueuedPosts(response.data);
    setPostsLoading(false);
  }, [props.headers]);

  useEffect(() => {
    getQueuedPosts();

    const getTimeSlots = async () => {
      const response = await sblyApiService.get('facebook-posts/time-slots', {
        headers: props.headers,
      });
      return response.data;
    };

    getTimeSlots().then((timeSlots: TimeSlots[]) => {
      const newTimeSlotsUsage: TimeSlotsUsage = {};
      timeSlots.forEach(pageTimeSlots => {
        newTimeSlotsUsage[pageTimeSlots.id] = {
          available: [...pageTimeSlots.time_slots],
          used: [],
        };
      });

      setTimeSlots(timeSlots);
      setTimeSlotsUsage(newTimeSlotsUsage);
    });
  }, [getQueuedPosts, props.headers]);

  function getSelectedPosts() {
    return selectionModel.map(postId => {
      const stringPostId = postId as string;
      return find(
        queuedPosts,
        (post: any) => post.id === stringPostId.split('-')[0],
      );
    });
  }

  async function handleSchedulePost() {
    setErrorMessage('');
    setPostsLoading(true);

    const postsToSchedule = getSelectedPosts();

    const data = { posts: postsToSchedule };

    try {
      await sblyApiService.post('facebook-posts/schedule-posts', data, {
        headers: props.headers,
      });
    } catch (error: any) {
      const errors = error.response.data.errors;
      if (errors && errors[0].messages[0].includes('publishTime')) {
        setErrorMessage('Please make sure all posts have publish time.');
      } else {
        setErrorMessage('Scheduling unsuccessful.');
      }
      setPostsLoading(false);
      return;
    }

    const postToDeleteSlugs = postsToSchedule.map((post: any) => post.slug);
    await sblyApiService.delete('facebook-posts/remove-from-queue', {
      headers: props.headers,
      data: { posts: postToDeleteSlugs },
    });

    const unscheduledPosts = queuedPosts.filter((post: any) => {
      return !postsToSchedule.includes(post);
    });

    setQueuedPosts(unscheduledPosts);
    setPostsLoading(false);
  }

  async function handleRemoveFromQueue() {
    setRemoveLoading(true);

    const postsToRemove = getSelectedPosts().map(post => post?.slug);

    const response = await sblyApiService.delete(
      'facebook-posts/remove-from-queue',
      {
        headers: props.headers,
        data: { posts: postsToRemove },
      },
    );

    if (response.status === 200) {
      getQueuedPosts();
      setSelectionModel([]);
    }
    setRemoveLoading(false);
  }

  async function handleUpdatePageQueue() {
    setPostsLoading(true);

    const postsToUpdate = getSelectedPosts().map(post => post?.slug);
    const response = await sblyApiService.delete(
      'facebook-posts/remove-from-queue',
      {
        headers: props.headers,
        data: { posts: postsToUpdate },
      },
    );

    if (response.status === 200) {
      const postsToQueue = getSelectedPosts();

      const data = {
        pageId: facebookPage.id,
        postsToQueue: postsToQueue,
      };
      const response = await sblyApiService.post(
        'facebook-posts/add-post-to-queue',
        data,
        {
          headers: props.headers,
        },
      );

      if (response.status === 200) {
        getQueuedPosts();
        setPageInput('');
        setFacebookPage(pages[0]);
        setSelectionModel([]);
      }
    }
    setPostsLoading(false);
  }

  function handleTimeChange(time: Dayjs, postID: string, dateTime = false) {
    const queuedPostsWithPublishTimes = queuedPosts.map((post: Posts) => {
      if (post.id === postID) {
        if (!dateTime) {
          post.publishTime = date
            .set('hour', time.hour())
            .set('minute', time.minute())
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');
        } else {
          post.publishTime = formatDateTimeUTC(time);
        }
      }
      return post;
    });

    setQueuedPosts(queuedPostsWithPublishTimes);
  }

  function onCaptionChange(caption: string, postID: string) {
    const queuedPostsWithCaptions = queuedPosts.map((post: Posts) => {
      if (post.id === postID) {
        post.message = caption;

        props.onAddCaptions({
          caption,
          domain: post.domain,
          slug: post.slug,
          url: post.link.split('?')[0],
        });
      }
      return post;
    });
    setQueuedPosts(queuedPostsWithCaptions);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" justifyContent="space-between">
        <ControlledDatePicker
          label="Set Date"
          date={date}
          onDateChange={newDate => setDate(newDate)}
        />
        <Button onClick={getQueuedPosts}>
          <RefreshIcon />
        </Button>
      </Stack>
      {errorMessage && (
        <SchedulingError
          errorMessage={errorMessage}
          resetErrorMessage={() => {
            setErrorMessage('');
          }}
        />
      )}
      <Box sx={{ height: '905px', width: '100%', my: 2 }}>
        <DataGrid
          checkboxSelection
          columns={columns}
          rows={queuedPosts}
          loading={postsLoading}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getRowId={row => `${row.id}-${row.queued_page}`}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel =>
            setColumnVisibilityModel(newModel)
          }
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSchedulePost}
            endIcon={<PostAddIcon />}
          >
            Schedule
          </Button>
          <RemoveButton
            loading={removeLoading}
            onClick={handleRemoveFromQueue}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            id="page"
            value={facebookPage}
            onChange={(_event, value) => setFacebookPage(value!)}
            inputValue={pageInput}
            onInputChange={(_event, newInputValue) => {
              setPageInput(newInputValue);
            }}
            options={pages}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="page" variant="outlined" />
            )}
            sx={{ width: 300 }}
          />
          <Button
            disabled={facebookPage === pages[0]}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleUpdatePageQueue}
            endIcon={<ImportExportIcon />}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
