import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface ImagesNamesListProps {
  images: File[];
  onImageClick: (image: File) => void;
}

function ImagesNamesList({ images, onImageClick }: ImagesNamesListProps) {
  return (
    <List dense={true}>
      {images.map((image, idx) => (
        <ListItem key={idx} sx={{ py: 0 }}>
          <IconButton onClick={() => onImageClick(image)} sx={{ py: 0.5 }}>
            <AddIcon />
          </IconButton>
          <ListItemText primary={image.name} />
        </ListItem>
      ))}
    </List>
  );
}

export default ImagesNamesList;
