import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { FilterColumn } from 'pages/Dashboard/models/Settings';
import { Segment } from 'pages/Dashboard/models/CustomDimension';
import convertPredicate from 'pages/Dashboard/utils/convertPredicate';
import EditSegments from 'pages/Dashboard/components/dimension/EditSegments';

interface CurrentSegmentsProps {
  segments: Segment[];
  onSegmentsChange: (segments: Segment[]) => void;
  columns: FilterColumn[];
}

function CurrentSegments({
  segments,
  onSegmentsChange,
  columns,
}: CurrentSegmentsProps) {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingDimension, setEditingDimension] = useState('');

  const handleDelete = (dimension: string) => {
    const updatedSegments = segments.filter(seg => {
      if (seg.dimensionName === dimension) return false;
      return true;
    });
    onSegmentsChange(updatedSegments);
    return updatedSegments;
  };

  const handleClick = (dimension: string) => {
    setEditDialogOpen(true);
    setEditingDimension(dimension);
  };

  const handleEdit = (updatedSegments: Segment[]) => {
    const newSegments = handleDelete(editingDimension);
    onSegmentsChange([...newSegments, ...updatedSegments]);
  };

  return (
    <Box sx={{ pb: 1 }}>
      <Stack direction="row" spacing={1}>
        {segments.map(seg => {
          const segLabel = `${seg.dimensionName}: ${seg.segmentName} (${
            seg.columnName
          } ${convertPredicate(seg.filterKey)} ${seg.value})`;
          return (
            <Chip
              variant="outlined"
              size="small"
              label={segLabel}
              key={segLabel}
              onClick={() => handleClick(seg.dimensionName)}
            />
          );
        })}
      </Stack>
      <EditSegments
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        dimension={editingDimension}
        segments={segments.filter(
          segment => segment.dimensionName === editingDimension,
        )}
        onSegmentsUpdate={segments => handleEdit(segments)}
        columns={columns}
        onDelete={() => handleDelete(editingDimension)}
      />
    </Box>
  );
}

export default CurrentSegments;
