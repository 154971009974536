import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ACCOUNT_KEY, CAMPAIGN_KEY } from 'pages/Dashboard/utils/constants';

interface DualButtonGroupProps {
  color: 'error' | 'success';
  disabled: boolean;
  ButtonIcon: React.ReactNode;
  handleClick: (level: typeof CAMPAIGN_KEY | typeof ACCOUNT_KEY) => void;
}

function DualButtonGroup({
  color,
  disabled,
  ButtonIcon,
  handleClick,
}: DualButtonGroupProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup
        disabled={disabled}
        variant="text"
        size="small"
        aria-label="text button group"
        color={color}
      >
        <Button
          startIcon={ButtonIcon}
          onClick={() => handleClick(CAMPAIGN_KEY)}
        >
          campaign
        </Button>
        <Button
          startIcon={ButtonIcon}
          onClick={() => handleClick(ACCOUNT_KEY)}
          sx={{ pl: 1 }}
        >
          account
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default DualButtonGroup;
