import React from 'react';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

interface RefreshButtonProps {
  onRefresh: () => void;
  disabled: boolean;
}

function RefreshButton({ onRefresh, disabled }: RefreshButtonProps) {
  return (
    <IconButton disabled={disabled} onClick={onRefresh}>
      <RefreshIcon />
    </IconButton>
  );
}

export default RefreshButton;
