import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';

const ADMIN_KEY = 'bWFzdGVy';
const USER_KEY = 'dXNlcg==';
const passwordDefaultHelperText = 'Available in 1Password';

export default function Login({
  onSubmit,
}: {
  onSubmit: (userType: string, accessToken: string) => void;
}) {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState(
    passwordDefaultHelperText,
  );

  const handleLogin = () => {
    const [userTypeKey, accessToken] = password.split(':');

    if (userTypeKey === ADMIN_KEY) {
      onSubmit('admin', accessToken);
    } else if (userTypeKey === USER_KEY) {
      onSubmit('user', accessToken);
    } else {
      setPasswordHelperText('Unrecognized password');
      setPasswordError(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        component="form"
        noValidate
        sx={{
          marginTop: theme.spacing(15),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ backgroundColor: theme.palette.error.main, mb: 4 }}>
          <LockOutlinedIcon />
        </Avatar>
        <TextField
          required
          fullWidth
          variant="outlined"
          name="password"
          type="password"
          label="Password"
          autoComplete="current-password"
          helperText={passwordHelperText}
          error={passwordError}
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            setPasswordError(false);
            setPasswordHelperText(passwordDefaultHelperText);
          }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{ mt: 2 }}
        >
          Sign In
        </Button>
      </Box>
    </Container>
  );
}
