export function localStorageGet(key: string) {
  const item = localStorage.getItem(key);
  if (item !== 'undefined' && item) {
    return JSON.parse(item);
  }
}

export function localStorageSet(key: string, item: any) {
  localStorage.setItem(key, JSON.stringify(item));
}
