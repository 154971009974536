import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import { AntTab, AntTabs } from 'shared/components/AntTab';
import { extractFirstPath } from 'utils/transformURL';
import { FacebookLinkCampaign } from 'pages/Creator/models/FacebookLinkCampaign';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';
import { defaultCampaignStartTime } from 'utils/formatDate';
import {
  distributeAdAccount,
  lowestCostAdAccounts,
  makeCreationParams,
} from 'pages/Creator/utils/creationUtils';
import { GroupedTemplates } from 'pages/Creator/models/FacebookCreationTemplate';
import AdAccount from 'pages/Creator/models/AdAccount';
import AdStatus from 'pages/Creator/models/AdStatus';
import AdStatuses from 'pages/Creator/components/facebook/AdStatuses';
import CreateCampaignFromCandidates from 'pages/Creator/components/facebook/CreateCampaignFromCandidates';
import CreateCampaignFromLink from 'pages/Creator/components/facebook/CreateCampaignFromLink';
import CustomToggle from 'shared/components/CustomToggle';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';
import FacebookCreatorService from 'services/FacebookCreatorService';
import LaunchedCampaigns from 'pages/Creator/components/facebook/LaunchedCampaigns';
import LinkData from 'pages/Creator/models/LinkData';
import LoadingProgressBackdrop from 'shared/components/LoadingProgressBackdrop';
import RoundedPaper from 'shared/components/RoundedPaper';
import socket from 'services/socket';

const LINK = 'link';
const TABLE = 'table';
const STATUS = 'status';

const CREATION_TYPE_KEY = 'creation-type';
const storedCreationType = localStorageGet(CREATION_TYPE_KEY);

type CreationType = typeof LINK | typeof TABLE | typeof STATUS;

const facebookCreatorService = new FacebookCreatorService();

function FacebookCampaigns() {
  const [creationType, setCreationType] = useState(
    storedCreationType || 'TABLE',
  );
  const [adAccounts, setAdAccounts] = useState<AdAccount[]>([]);
  const [recommendedAdAccount, setRecommendedAdAccount] = useState<AdAccount>();
  const [adStatuses, setAdStatues] = useState<AdStatus[]>([]);
  const [refreshStatusRunning, setRefreshStatusRunning] = useState(false);
  const [error, setError] = useState('');
  const [launchedCampaigns, setLaunchedCampaigns] = useState<string[]>([]);
  const [campaignsCreationProgress, setCampaignsCreationProgress] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<FacebookLinkCampaign[]>([]);
  const [selectedCampaignIdx, setSelectedCampaignIdx] = useState(0);

  const newFacebookLinkCampaign = {
    link: '',
    linkData: {} as LinkData,
    campaignNameAppend: '',
    templates: [],
    groupedTemplates: {} as GroupedTemplates,
    selectedTemplates: [],
    selectedGroups: [],
    selectedAdAccount: recommendedAdAccount,
    selectedLCAdAccount: lowestCostAdAccounts[0],
    images: [],
    savedImages: [],
    batch: true,
    startTime: defaultCampaignStartTime(),
  };
  const selectedCampaign = campaigns[selectedCampaignIdx];

  useEffect(() => {
    socket.on('connect', () => {
      console.info('Client connected: ', socket.id);
      handleRefreshStatuses().then(resp => setAdStatues(resp));
    });
    socket.on('adsStatusUpdate', (data: AdStatus[]) => {
      setAdStatues(data);
      setRefreshStatusRunning(false);
    });
    socket.on('statusCheckRunning', (runningStatus: boolean) => {
      setRefreshStatusRunning(runningStatus);
    });

    facebookCreatorService.getAdAccounts().then(accounts => {
      if (accounts) {
        setAdAccounts([distributeAdAccount, ...accounts.accounts]);
        setRecommendedAdAccount(accounts.recommendedAccount);

        setCampaigns([
          {
            ...newFacebookLinkCampaign,
            selectedAdAccount: accounts.recommendedAccount,
          },
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPage = () => {
    setCampaigns([
      {
        ...newFacebookLinkCampaign,
        selectedAdAccount: recommendedAdAccount!,
      },
    ]);
    setSelectedCampaignIdx(0);
  };

  const handleRefreshStatuses = () => {
    setRefreshStatusRunning(true);
    return facebookCreatorService.refreshAdStatuses();
  };

  const updateCreationType = (type: CreationType) => {
    setCreationType(type);
    localStorageSet(CREATION_TYPE_KEY, type);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newIdx: number) => {
    setSelectedCampaignIdx(newIdx);
  };

  const handleUpdateSelectedCampaign = <K extends keyof FacebookLinkCampaign>(
    updates: { key: K; value: any }[],
  ) => {
    const updatedCampaigns = [...campaigns];

    updates.forEach(update => {
      selectedCampaign[update.key] = update.value;
    });
    updatedCampaigns[selectedCampaignIdx] = selectedCampaign;

    setCampaigns(updatedCampaigns);
  };

  const handleAddNewCampaign = () => {
    setCampaigns([
      ...campaigns,
      {
        ...newFacebookLinkCampaign,
        selectedAdAccount: recommendedAdAccount!,
      },
    ]);
    setSelectedCampaignIdx(campaigns.length);
  };

  const handleRemoveCampaign = (idx: number) => {
    if (campaigns.length === 1) {
      resetPage();
      return;
    }

    const updatedCampaigns = [...campaigns];
    updatedCampaigns.splice(idx, 1);

    if (selectedCampaignIdx >= idx && selectedCampaignIdx !== 0) {
      setSelectedCampaignIdx(selectedCampaignIdx - 1);
    }
    setCampaigns(updatedCampaigns);
  };

  const handleCreateCampaigns = async () => {
    setCreateLoading(true);

    const creationParams = await makeCreationParams(campaigns);
    const creationProgressResp =
      await facebookCreatorService.createCustomCampaigns({
        campaigns: creationParams,
      });

    if ('id' in creationProgressResp) {
      const getProgressUpdate = async () => {
        const progressUpdateResp =
          await facebookCreatorService.getCreationStatus(
            creationProgressResp.id,
          );

        if (typeof progressUpdateResp === 'number') {
          setCampaignsCreationProgress(
            progressUpdateResp === 100
              ? progressUpdateResp - 1
              : progressUpdateResp,
          );
          await getProgressUpdate();
        } else if (Array.isArray(progressUpdateResp)) {
          setLaunchedCampaigns(progressUpdateResp);
          resetPage();
        } else {
          setError(JSON.stringify(progressUpdateResp));
        }
      };

      await getProgressUpdate();
    } else {
      let creationError = creationProgressResp;
      if (creationProgressResp.description) {
        creationError = creationProgressResp.description;
      }
      setError(creationError);
    }

    setCampaignsCreationProgress(0);
    setCreateLoading(false);
  };

  function renderByCreationType(type: CreationType) {
    switch (type) {
      case LINK:
        return (
          recommendedAdAccount && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ErrorAlert
                open={error !== ''}
                onClose={() => setError('')}
                message={error}
              />
              <LaunchedCampaigns
                campaigns={launchedCampaigns}
                open={launchedCampaigns.length > 0}
                onClose={() => setLaunchedCampaigns([])}
              />
              <RoundedPaper sx={{ width: '70%', mt: 3, px: 5, pb: 4, pt: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <AntTabs
                      value={selectedCampaignIdx}
                      onChange={handleTabChange}
                      aria-label="campaign-tabs"
                      sx={{
                        alignItems: 'center',
                        minHeight: 0,
                      }}
                    >
                      {campaigns.map((campaign, idx) => (
                        <AntTab
                          key={`${idx}-tab`}
                          label={
                            campaign.link
                              ? extractFirstPath(campaign.link)
                              : `Tab ${idx + 1}`
                          }
                          icon={
                            <CloseIcon
                              onClick={event => {
                                event.stopPropagation();
                                handleRemoveCampaign(idx);
                              }}
                              sx={{ fontSize: '15px' }}
                            />
                          }
                          iconPosition="end"
                          sx={{ minHeight: 0 }}
                        />
                      ))}
                    </AntTabs>
                    <IconButton onClick={handleAddNewCampaign}>
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <LoadingButton
                    disableElevation
                    variant="contained"
                    endIcon={<BuildIcon />}
                    onClick={handleCreateCampaigns}
                    sx={{ width: 135, mt: 1, mb: -1 }}
                  >
                    create
                  </LoadingButton>
                </Box>
                <CreateCampaignFromLink
                  adAccounts={adAccounts}
                  lowestCostAdAccounts={lowestCostAdAccounts}
                  selectedCampaign={selectedCampaign}
                  selectedCampaignIdx={selectedCampaignIdx}
                  onCampaignUpdate={updates => {
                    handleUpdateSelectedCampaign(updates);
                  }}
                />
              </RoundedPaper>
              <LoadingProgressBackdrop
                open={createLoading}
                label={'Creation in progress...'}
                progress={campaignsCreationProgress}
              />
            </Box>
          )
        );
      case TABLE:
        return <CreateCampaignFromCandidates />;
      case STATUS:
        return (
          <AdStatuses
            statuses={adStatuses}
            onRefresh={handleRefreshStatuses}
            refreshDisabled={refreshStatusRunning}
          />
        );
    }
  }

  return (
    <Box>
      <CustomToggle<CreationType>
        selectedValue={creationType}
        values={[LINK, TABLE, STATUS]}
        onValueUpdate={newType => {
          updateCreationType(newType);
        }}
      />
      <Box>{renderByCreationType(creationType)}</Box>
    </Box>
  );
}

export default FacebookCampaigns;
