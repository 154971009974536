import React, { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { FACEBOOK_ACCOUNT } from 'services/FacebookCreatorService';
import { QUORA_ACCOUNT } from 'services/CreatorService';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';
import CustomToggle from 'shared/components/CustomToggle';
import CreatorAccount from 'pages/Creator/models/CreatorAccount';
import FacebookCampaigns from 'pages/Creator/components/facebook/FacebookCampaigns';
import QuoraCampaigns from 'pages/Creator/components/quora/QuoraCampaigns';

const CREATOR_ACCOUNT_KEY = 'creator-account';
const storedAccount = localStorageGet(CREATOR_ACCOUNT_KEY);

function renderCreatorByAccount(account: string) {
  switch (account) {
    case QUORA_ACCOUNT:
      return <QuoraCampaigns />;
    case FACEBOOK_ACCOUNT:
      return <FacebookCampaigns />;
  }
}

export default function Creator() {
  const [account, setAccount] = useState(storedAccount || QUORA_ACCOUNT);

  const updateCreatorAccount = (account: CreatorAccount) => {
    localStorageSet(CREATOR_ACCOUNT_KEY, account);
    setAccount(account);
  };

  return (
    <Fragment>
      <Typography variant="h3" color={'secondary.main'} align="center">
        Creator
      </Typography>
      <Box
        sx={{
          p: 3,
          mb: 3,
          mx: 1,
        }}
      >
        <CustomToggle<CreatorAccount>
          selectedValue={account}
          values={[QUORA_ACCOUNT, FACEBOOK_ACCOUNT]}
          onValueUpdate={account => {
            updateCreatorAccount(account);
          }}
        />
        {renderCreatorByAccount(account)}
      </Box>
    </Fragment>
  );
}
