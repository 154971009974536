import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface DateDropdownProps {
  onStartDateChange: (date: Dayjs) => void;
  onEndDateChange: (date: Dayjs) => void;
  presetRange: string;
  onPresetRangeUpdate: (range: string) => void;
}

function DateDropdown({
  onStartDateChange,
  onEndDateChange,
  presetRange,
  onPresetRangeUpdate,
}: DateDropdownProps) {
  const handleChange = (event: SelectChangeEvent) => {
    const range = event.target.value as string;
    const today = dayjs();

    switch (range) {
      case 'today':
        onStartDateChange(today);
        break;
      case 'yesterday':
        const yesterday = today.subtract(1, 'days');
        onStartDateChange(yesterday);
        onEndDateChange(yesterday);
        break;
      case 'week':
        onStartDateChange(today.startOf('week'));
        break;
      case 'month':
        onStartDateChange(today.startOf('month'));
        break;
      case 'year':
        onStartDateChange(today.startOf('year'));
        break;
    }

    onPresetRangeUpdate(range);
    if (range !== 'yesterday') onEndDateChange(today);
  };

  return (
    <Box sx={{ pr: 0.5 }}>
      <FormControl fullWidth>
        <InputLabel id="range-select-label">range</InputLabel>
        <Select
          id="range-select"
          value={presetRange}
          label="Range"
          onChange={handleChange}
        >
          <MenuItem value={'today'}>today</MenuItem>
          <MenuItem value={'yesterday'}>yesterday</MenuItem>
          <MenuItem value={'week'}>this week</MenuItem>
          <MenuItem value={'month'}>this month</MenuItem>
          <MenuItem value={'year'}>this year</MenuItem>
          <MenuItem value={'custom'}>custom</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default DateDropdown;
