import React from 'react';

import Box from '@mui/material/Box';
import {
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GRID_TREE_DATA_GROUPING_FIELD,
} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';

import { CampaignPerformance } from 'pages/CampaignMonitor/models/CampaignPerformance';
import { ConvertedThresholds } from 'pages/CampaignMonitor/models/Thresholds';
import { formatMetric } from 'pages/Dashboard/utils/format';
import StyledDataGrid from 'shared/components/StyledDataGrid';
import SummaryData from 'pages/Dashboard/models/SummaryData';

interface DataTableProps {
  loading: boolean;
  defaultGroupingExpansionDepth: number;
  metrics: (keyof SummaryData)[];
  performanceData: CampaignPerformance[];
  performanceDays: string[];
  thresholds: ConvertedThresholds;
}

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = row =>
  row.hierarchy;

const colHeaderPadding = ' '.repeat(8);
const colSpaces = ' '.repeat(11);

const columns = ({
  performanceDays,
  metrics,
  thresholds,
}: {
  performanceDays: string[];
  metrics: (keyof SummaryData)[];
  thresholds: ConvertedThresholds;
}): GridColDef[] =>
  performanceDays.map((currDay, idx) => {
    return {
      field: currDay,
      headerName: `${colHeaderPadding}DAY ${
        idx + 1
      }\n${colHeaderPadding}Spend  ${colSpaces}Profit${colSpaces}Margin`,
      width: 290,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if ('performance' in params.row && currDay in params.row.performance) {
          return (
            <Stack direction="row">
              {metrics.map(metric => {
                const value = params.row.performance[currDay][metric];
                const highThreshold = thresholds[metric]?.high;
                const lowThreshold = thresholds[metric]?.low;
                let className = '';
                if (highThreshold && value > highThreshold) {
                  className = 'campaign-monitor-high-metric';
                }
                if (lowThreshold && value < lowThreshold) {
                  className = 'campaign-monitor-low-metric';
                }
                return (
                  <Box
                    className={className}
                    key={metric}
                    sx={{
                      width: 80,
                      height: 30,
                      display: 'flex',
                      borderRight: 1,
                      borderColor: 'grey.200',
                      pl: 10,
                      pr: 1,
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    {formatMetric(metric, value, true)}
                  </Box>
                );
              })}
            </Stack>
          );
        }
      },
    };
  });

function DataTable({
  loading,
  defaultGroupingExpansionDepth,
  metrics,
  performanceData,
  performanceDays,
  thresholds,
}: DataTableProps) {
  const groupingColWidth = defaultGroupingExpansionDepth === 3 ? 470 : 280;

  const currentColumns = columns({
    performanceDays,
    metrics,
    thresholds,
  });

  return (
    <StyledDataGrid
      treeData
      hideFooterSelectedRowCount
      loading={loading}
      columns={currentColumns}
      rows={performanceData}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={{ minWidth: groupingColWidth }}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      columnHeaderHeight={70}
      initialState={{
        pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
      }}
      getRowClassName={(params: GridRowClassNameParams) => {
        if (params.row.hierarchy.length === 1) {
          return 'campaign-monitor-aggregate';
        }
        return 'campaign-monitor';
      }}
      getRowHeight={() => 'auto'}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          textOverflow: 'clip',
          whiteSpace: 'break-spaces',
          lineHeight: 2,
        },
      }}
    />
  );
}

export default DataTable;
