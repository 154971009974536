import React, { useState } from 'react';
import { compressImage } from 'utils/formatImages';

export default function useImages(images: File[] = []) {
  const [selectedImages, setSelectedImages] = useState<Array<File>>(images);

  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event?.target?.files;
    if (files) {
      const compressedImages = await Promise.all(
        Array.from(files).map(async image => await compressImage(image)),
      );
      setSelectedImages([...selectedImages, ...compressedImages]);
    }
  }

  function removeImage(index: number) {
    const filtered = selectedImages.filter(
      (_, imageIndex) => imageIndex !== index,
    );
    setSelectedImages([...filtered]);
  }

  return { selectedImages, onChange, setSelectedImages, removeImage };
}
