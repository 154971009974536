import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

interface MessageTextFieldProps {
  message?: string;
  onChange: (value: string) => void;
  label: string;
  multiline?: boolean;
}

export default function MessageTextField(props: MessageTextFieldProps) {
  const [message, setMessage] = useState(props.message || '');

  return (
    <TextField
      id="message"
      label={props.label}
      value={message}
      autoComplete="off"
      multiline={props.multiline || false}
      type="text"
      variant="outlined"
      size="small"
      fullWidth
      onChange={e => setMessage(e.target.value)}
      onKeyDown={e => {
        e.stopPropagation();
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          props.onChange(message);
          (e.target as HTMLInputElement | HTMLTextAreaElement).blur();
        }
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
}
