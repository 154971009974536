import React from 'react';
import Snackbar from '@mui/material/Snackbar';

import Alert from 'pages/Dashboard/components/helpers/Alert';

interface ErrorAlertProps {
  open: boolean;
  onClose: (close: boolean) => void;
  message: string;
}

function ErrorAlert({ open, onClose, message }: ErrorAlertProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={10000}
      onClose={() => onClose(false)}
    >
      <Alert
        onClose={() => onClose(false)}
        severity="warning"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorAlert;
