import { Dayjs } from 'dayjs';

import { Article } from 'pages/articles/ImageManagement/models/Article';
import { bufferToImageFile } from 'utils/formatImages';
import {
  CreateSplitTestParams,
  SplitTestParams,
} from 'pages/articles/SplitTest/models/CreateSplitTestParams';
import { FetchedImage } from 'pages/articles/ImageManagement/models/Image';
import CreatorService from 'services/CreatorService';
import { TestResult } from 'pages/articles/SplitTest/models/TestResult';

class ArticlesService extends CreatorService {
  articlesPath = 'article-images';
  splitTestPath = 'article-split-test';

  async getArticlesWithImages(
    startDate: Dayjs,
    endDate: Dayjs,
  ): Promise<Article[] | null> {
    const requestFn = async () => {
      return this.http.get(`${this.articlesPath}/articles-with-images`, {
        params: {
          startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
          endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
        },
      });
    };
    return this.handleHttpRequest<Article[]>(
      requestFn,
      'Error fetching articles with images:',
    );
  }

  async getArticlesByName(articlePart: string): Promise<Article[] | null> {
    const requestFn = async () => {
      return this.http.get(`${this.articlesPath}/articles-by-name`, {
        params: {
          articlePart,
        },
      });
    };
    return this.handleHttpRequest<Article[]>(
      requestFn,
      'Error fetching articles by name:',
    );
  }

  async getImages<T>({
    id,
    domain,
    link,
  }: {
    id?: string;
    domain?: string;
    link?: string;
  }): Promise<T | null> {
    if (link) {
      const { domain, post_id } = await this.getLinkInfo(link);
      const resp = await this.http.get(`${this.articlesPath}/${post_id}`, {
        params: {
          domain,
        },
      });

      if (resp.data) {
        return resp.data.map((image: FetchedImage) =>
          bufferToImageFile(image.image.data, image.name),
        );
      }
      return null;
    } else {
      const requestFn = async () => {
        return this.http.get(`${this.articlesPath}/${id}`, {
          params: {
            domain,
          },
        });
      };
      return this.handleHttpRequest<T>(requestFn, 'Error fetching images:');
    }
  }

  async addImages(
    id: string,
    domain: string,
    images: { imageName: string; image: string; tags: string[] }[],
  ) {
    const requestFn = async () => {
      return this.http.put(`${this.articlesPath}/${id}`, { domain, images });
    };
    return this.handleHttpRequest(requestFn, 'Error adding images:');
  }

  async deleteImages(id: string, images: { bucket: string; key: string }[]) {
    const requestFn = async () => {
      return this.http.delete(`${this.articlesPath}/${id}`, {
        data: { images },
      });
    };
    return this.handleHttpRequest(requestFn, 'Error adding images:');
  }

  async getActiveSplitTests(): Promise<SplitTestParams[] | null> {
    const requestFn = async () => this.http.get(this.splitTestPath);
    return this.handleHttpRequest(
      requestFn,
      'Error fetching active split tests',
    );
  }

  async addSplitTests({ tests }: CreateSplitTestParams) {
    const requestFn = async () =>
      this.http.post(this.splitTestPath, {
        tests,
      });
    return this.handleHttpRequest(requestFn, 'Error adding split test');
  }

  async deleteSplitTests({ tests }: CreateSplitTestParams) {
    const requestFn = async () =>
      this.http.delete(this.splitTestPath, {
        data: { tests },
      });
    return this.handleHttpRequest(requestFn, 'Error deleting split tests');
  }

  async getSplitTestResults(slug: string): Promise<TestResult[] | null> {
    const requestFn = async () =>
      this.http.get(`${this.splitTestPath}/results`, {
        params: { slug },
      });
    return this.handleHttpRequest(
      requestFn,
      'Error getting split test results',
    );
  }
}

export default ArticlesService;
