import React, { useState } from 'react';
import find from 'lodash/find';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IconButton from '@mui/material/IconButton';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';
import ScalingDiagnostic, {
  ScalingChangeTypeLabel,
  ScalingDiagnosticsQueryData,
  ScalingGraphType,
  ScalingHistory,
} from 'pages/Dashboard/models/ScalingDiagnostics';
import LoadingLinearProgress from 'pages/Dashboard/components/helpers/LoadingLinearProgress';
import DiagnosticChartToggle from 'pages/Dashboard/components/dataTable/toolbar/diagnosticButton/DiagnosticChartToggles';
import DiagnosticChartElement from 'pages/Dashboard/components/dataTable/toolbar/diagnosticButton/DiagnosticChartElement';
import DiagnosticSummaryElement from 'pages/Dashboard/components/dataTable/toolbar/diagnosticButton/DiagnosticSummaryElement';
import { caseInsensitiveStringIncludes } from 'utils/stringUtils';

interface ScalingDebugProps {
  getScalingDiagnostics: (
    data: ScalingDiagnosticsQueryData,
  ) => Promise<ScalingDiagnostic[]>;
  metrics: PerformanceData[];
  selected: string[];
}

function shouldDisable({ selected, metrics }: ScalingDebugProps) {
  const notOnlyOneSelected = selected.length !== 1;
  const selectedMetric = find(metrics, metric => metric.id === selected[0]);
  const doesntContainIds = !(
    selectedMetric?.campaign_id || selectedMetric?.adset_id
  );
  return notOnlyOneSelected || doesntContainIds;
}

function extractQueryData({
  selected,
  metrics,
}: ScalingDebugProps): ScalingDiagnosticsQueryData {
  const selectedMetric = find(metrics, metric => metric.id === selected[0]);
  return {
    adset_id: selectedMetric?.adset_id,
    campaign_id: selectedMetric?.campaign_id,
  };
}

function ScalingDiagnosticsButton(props: ScalingDebugProps) {
  const { getScalingDiagnostics } = props;
  const [diagnostics, setDiagnostics] = useState<ScalingDiagnostic[]>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [graphType, setGraphType] = useState<ScalingGraphType>(
    ScalingGraphType.Hourly,
  );
  const [changeType, setChangeType] = useState<ScalingChangeTypeLabel>();
  const [scalingHistory, setScalingHistory] = useState<ScalingHistory>();
  const [showNonChanges, setShowNonChanges] = useState(false);

  const handleOpenChart = async () => {
    setDiagnostics(undefined);
    setDialogOpen(true);
    const queryData = extractQueryData(props);
    const diagnostics = await getScalingDiagnostics(queryData);
    const hasBidChangeType = diagnostics.find(diagnostic =>
      caseInsensitiveStringIncludes(
        diagnostic.changeType,
        ScalingChangeTypeLabel.BidChange,
      ),
    );

    if (diagnostics.length > 0) {
      const changeType = hasBidChangeType
        ? ScalingChangeTypeLabel.BidChange
        : ScalingChangeTypeLabel.BudgetChange;
      setScalingHistory(diagnostics[0].history[0]);
      setChangeType(changeType);
      setDiagnostics(diagnostics);
    } else {
      setDialogOpen(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const updateGraphType = (graphType: ScalingGraphType) => {
    setGraphType(graphType);
  };

  const updateChangeType = (changeType: ScalingChangeTypeLabel) => {
    setChangeType(changeType);
  };

  const updateShowChanges = (showChanges: boolean) => {
    setShowNonChanges(showChanges);
  };

  return (
    <Box>
      <IconButton onClick={handleOpenChart} disabled={shouldDisable(props)}>
        <QueryStatsIcon />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={false}
        onClose={handleDialogClose}
        open={dialogOpen}
        PaperProps={{ sx: { borderRadius: '0.75rem', px: 3, py: 1 } }}
        scroll="body"
      >
        {diagnostics && changeType && scalingHistory ? (
          <div>
            <DiagnosticChartToggle
              diagnostics={diagnostics}
              graphType={graphType}
              changeType={changeType}
              showNonChanges={showNonChanges}
              updateGraphType={updateGraphType}
              updateChangeType={updateChangeType}
              updateShowChanges={updateShowChanges}
            />
            <DiagnosticChartElement
              type={changeType}
              diagnostics={diagnostics}
              showNonChanges={showNonChanges}
              graphType={graphType}
              setScalingHistory={setScalingHistory}
            />
            <DiagnosticSummaryElement history={scalingHistory} />
          </div>
        ) : (
          <LoadingLinearProgress />
        )}
        <div></div>
      </Dialog>
    </Box>
  );
}

export default ScalingDiagnosticsButton;
