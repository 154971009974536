export function formatLabel(label: string) {
  switch (label) {
    case 'LOWEST_COST_WITH_BID_CAP':
      return 'Bid Cap';
    case 'COST_CAP':
      return 'Cost Per Result';
    case 'LOWEST_COST_WITHOUT_CAP':
      return 'Lowest Cost';
    case 'LOWEST_COST_WITH_MIN_ROAS':
      return 'Target RoAS';
    default:
      return label;
  }
}
