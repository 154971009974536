import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from 'pages/Authorization';

interface PrivateRouteProps extends RouteProps {
  allowedTypes: string[];
}

function PrivateRoute({ allowedTypes, children, ...rest }: PrivateRouteProps) {
  const { userType } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userType && allowedTypes.includes(userType) ? (
          (children as React.ReactNode)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
