import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: { main: '#3E90B2', light: '#3E90B250' },
    secondary: { main: '#3E3E3E', light: '#3E3E3E10' },
    error: { main: '#BF1A2F', light: '#BF1A2F10' },
    warning: { main: '#EEE82C', dark: '#D6B02E' },
    info: { main: '#083D77' },
    success: { main: '#2E933C', light: '#2E933C20' },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
);
