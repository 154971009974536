import React, { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FilterListIcon from '@mui/icons-material/FilterList';
import Typography from '@mui/material/Typography';

interface DialogButtonProps {
  children: ReactNode;
  button?: ReactNode;
  label: string;
}

function DialogButton({ children, label, button }: DialogButtonProps) {
  const [open, setOpen] = useState(false);
  const buttonElement = button ?? (
    <Button
      variant="outlined"
      size="small"
      color="secondary"
      sx={{ my: 0.5, width: 125 }}
      endIcon={<FilterListIcon />}
      onClick={() => {
        setOpen(true);
      }}
    >
      <Typography fontSize={13}>{label}</Typography>
    </Button>);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { borderRadius: '0.75rem' } }}
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
      {buttonElement}
    </Box>
  );
}

export default DialogButton;
