import React, { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { CampaignFormProps } from 'pages/Creator/components/quora/CampaignForm';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';

export interface AdFormProps extends CampaignFormProps {
  headlineErrorOpen: boolean;
  bodyErrorOpen: boolean;
  onHeadlineErrorOpenChange: Dispatch<SetStateAction<boolean>>;
  onBodyErrorOpenChange: Dispatch<SetStateAction<boolean>>;
}

const formatError =
  'characters or less, and end with a period, question mark, or exclamation point.';

function AdForm({
  formData,
  onFormUpdate,
  headlineErrorOpen,
  bodyErrorOpen,
  onHeadlineErrorOpenChange,
  onBodyErrorOpenChange,
}: AdFormProps) {
  const adName = formData?.adName || '';
  const businessName = formData?.businessName || '';
  const headline = formData?.headline || '';
  const body = formData?.body || '';
  const cta = formData?.callToAction || '';
  const imageURL = formData?.imageURL || '';

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={7}>
        <ErrorAlert
          open={headlineErrorOpen}
          onClose={onHeadlineErrorOpenChange}
          message={`Headline must have 65 ${formatError}`}
        />
        <ErrorAlert
          open={bodyErrorOpen}
          onClose={onBodyErrorOpenChange}
          message={`Body must have 105 ${formatError}`}
        />
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <TextField
          id="ad-name"
          label="ad name"
          value={adName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onFormUpdate('adName', event.target.value)
          }
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <TextField
          id="media-type"
          label="media type"
          value={'image'}
          InputProps={{ readOnly: true }}
        />
        <TextField
          id="business-name"
          label="business name"
          value={businessName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onFormUpdate('businessName', event.target.value)
          }
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="cta">CTA</InputLabel>
          <Select
            label="CTA"
            id="cta"
            value={cta}
            onChange={(event: SelectChangeEvent) => {
              onFormUpdate('callToAction', event.target.value);
            }}
          >
            <MenuItem disabled value="">
              <em>Placeholder</em>
            </MenuItem>
            <MenuItem value={'Learn More'}>Learn More</MenuItem>
            <MenuItem value={'Get the App'}>Get the App</MenuItem>
            <MenuItem value={'Apply Now'}>Apply Now</MenuItem>
            <MenuItem value={'Book Now'}>Book Now</MenuItem>
            <MenuItem value={'Contact Us'}>Contact Us</MenuItem>
            <MenuItem value={'Donate Now'}>Donate Now</MenuItem>
            <MenuItem value={'Download'}>Download</MenuItem>
            <MenuItem value={'Shop Now'}>Shop Now</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack spacing={1} sx={{ my: 1 }}>
        <TextField
          fullWidth
          id="headline"
          label="headline"
          value={headline}
          error={headline.length > 65 || headlineErrorOpen}
          helperText={`${headline.length} chars (65 max)`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onFormUpdate('headline', event.target.value)
          }
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <TextField
          fullWidth
          id="body"
          label="body"
          value={body}
          error={body.length > 105 || bodyErrorOpen}
          helperText={`${body.length} chars (105 max)`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onFormUpdate('body', event.target.value)
          }
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <TextField
          fullWidth
          id="imageURL"
          label="image URL"
          value={imageURL}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onFormUpdate('imageURL', event.target.value);
          }}
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {imageURL && (
            <img src={imageURL} alt="Unable to load" width={'50%'} />
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export default AdForm;
