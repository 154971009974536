import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import Collapsible from 'react-collapsible';
import {
  KeyValue,
  ScalingHistory,
  ScalingDebugStep,
  ScalingChangeTypeLabel,
} from 'pages/Dashboard/models/ScalingDiagnostics';
import { formatNameValue } from 'pages/Dashboard/components/dataTable/toolbar/diagnosticButton/format';
import { caseInsensitiveStringIncludes } from 'utils/stringUtils';

interface DiagnosticSummaryElementProps {
  history: ScalingHistory;
}

const maxOpacity = 0.55;
const highlightedColor = 'rgba(205, 209, 228,';

const sectionStyles = {
  marginBottom: '30px',
};

const sectionHeaderStyles = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '15px',
  color: '#4a4a4a',
};

const metricStyles = {
  marginTop: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px',
  backgroundColor: 'rgba(205, 209, 228, 0.12)',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const calculationStyles = {
  marginTop: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px',
  backgroundColor: `rgba(205, 209, 228, ${maxOpacity})`,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const metricKeyStyles = {
  fontWeight: 'bold',
  marginRight: '15px',
  color: '#333',
  letterSpacing: '1px',
};

const metricValueStyles = {
  flexGrow: 1,
  textAlign: 'right',
  color: '#666',
};

function DiagnosticSummaryElement({ history }: DiagnosticSummaryElementProps) {
  const metricChanged = caseInsensitiveStringIncludes(
    history.changeType,
    ScalingChangeTypeLabel.BudgetChange,
  )
    ? 'Budget'
    : 'Bid';
  const createdAt = moment(history.createdAt.split(' ').slice(0, -1).join(' '));
  const scaleTime = createdAt.format('lll');

  const days = _.times(3)
    .map((_num, index) => {
      return createdAt.clone().subtract(index, 'days').format('YYYY-MM-DD');
    })
    .toReversed();

  const getCollapsible = (
    trigger: React.ReactElement,
    element: React.ReactElement,
    open: boolean = true,
  ) => {
    return (
      <Collapsible
        trigger={trigger}
        easing="ease-in-out"
        transitionTime={150}
        open={open}
      >
        {element}
      </Collapsible>
    );
  };

  const getCalculationElement = (
    step: ScalingDebugStep,
    calculation: KeyValue,
    depth: number = 1,
  ) => {
    const subCalculations = step.calculation.filter(otherCalculations => {
      return otherCalculations.parentKey === calculation.key;
    });
    const hasSubCalculations = subCalculations.length > 0;
    const { name, value } = formatNameValue(
      calculation.key,
      calculation.value,
      days,
    );
    const opacity = Math.max(_.round(maxOpacity - depth * 0.2, 2), 0.1);
    const stepElement = (
      <div style={{ margin: `5px 2px 5px ${depth * 15}px` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px',
            backgroundColor: hasSubCalculations
              ? `${highlightedColor} ${opacity})`
              : 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              marginRight: '15px',
              color: '#333',
            }}
          >
            {name}:
          </div>
          <div
            style={{
              flexGrow: 1,
              textAlign: 'right',
              color: '#666',
            }}
          >
            {value}
          </div>
        </div>
      </div>
    );
    if (!hasSubCalculations) {
      return stepElement;
    }
    return getCollapsible(
      stepElement,
      <div>
        {subCalculations.map(subCalculation =>
          getCalculationElement(step, subCalculation, depth + 1),
        )}
      </div>,
      false,
    );
  };

  const getStepElement = (step: ScalingDebugStep) => {
    const { name, value } = formatNameValue(step.name, step.value, days);
    const directSteps = _.uniqBy(step.calculation, 'key').filter(
      calculation => {
        return calculation.parentKey === step.name || !calculation.parentKey;
      },
    );
    return getCollapsible(
      <div style={calculationStyles}>
        <div style={metricKeyStyles}>{name}:</div>
        <div style={metricValueStyles}>{value}</div>
      </div>,
      <div>
        {directSteps.map(calculation =>
          getCalculationElement(step, calculation),
        )}
      </div>,
    );
  };

  const metricKeysToShow = [
    'Projected Margin',
    'Weighted Profit Margin Since Last Change',
    'Profit Margin Since Last Change',
    'Weighted Profit Since Last Change',
    'Spend Since Last Change',
    'Clicks Since Last Change',
    'Profit Margin Today',
    'Hours Elapsed Since Last Change',
    'Projected % CPC Change',
    'Projected % RPM Change',
  ];
  const shownMetrics = history.debug.metrics.filter(metric => {
    return metricKeysToShow.includes(metric.key);
  });
  const sortedByMetricOrder = _.sortBy(shownMetrics, metric =>
    metricKeysToShow.indexOf(metric.key),
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <h2>Scaling Diagostic for {scaleTime}</h2>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ maxWidth: '450px' }}>
            <div style={sectionHeaderStyles}>Summary</div>
            <div style={sectionStyles}>
              <div style={metricStyles}>
                <div style={metricKeyStyles}>Next {metricChanged}:</div>
                <div style={metricValueStyles}>${history.newValue}</div>
              </div>
              <div style={metricStyles}>
                <div style={metricKeyStyles}>Previous {metricChanged}:</div>
                <div style={metricValueStyles}>${history.oldValue}</div>
              </div>
            </div>
            {getCollapsible(
              <div style={sectionHeaderStyles}>Metrics</div>,
              <div style={sectionStyles}>
                <div style={metricStyles}>
                  <div style={metricKeyStyles}>Message:</div>
                  <div style={metricValueStyles}>{history.message}</div>
                </div>
                {sortedByMetricOrder.map(metric => {
                  const { name, value } = formatNameValue(
                    metric.key,
                    metric.value,
                  );
                  return (
                    <div style={metricStyles}>
                      <div style={metricKeyStyles}>{name}:</div>
                      <div style={metricValueStyles}>{value}</div>
                    </div>
                  );
                })}
              </div>,
            )}
          </div>
          <div style={{ minWidth: '50px' }}></div>
          <div style={{ minWidth: '750px' }}>
            {getCollapsible(
              <div style={sectionHeaderStyles}>Steps</div>,
              <div style={sectionStyles}>
                {history.debug.steps.map(getStepElement)}
              </div>,
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosticSummaryElement;
