import React from 'react';

import {
  GridColumnVisibilityModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';

import { dashboardService } from 'pages/Dashboard';
import {
  FACEBOOK_DATA_SOURCE,
  QUORA_DATA_SOURCE,
  TABOOLA_DATA_SOURCE,
  TABOOLA_REAL_TIME_DATA_SOURCE,
} from 'pages/Dashboard/utils/constants';
import GraphData from 'pages/Dashboard/models/GraphData';
import ScalingDiagnostic, {
  ScalingDiagnosticsQueryData,
} from 'pages/Dashboard/models/ScalingDiagnostics';
import FBAdsManagerButton from 'pages/Dashboard/components/dataTable/toolbar/FBAdsManagerButton';
import ScalingDiagnosticsButton from 'pages/Dashboard/components/dataTable/toolbar/ScalingDiagnosticsButton';
import GraphButton from 'pages/Dashboard/components/graph/GraphButton';
import OrderTableColumns from 'pages/Dashboard/components/dataTable/OrderTableColumns';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';
import TaboolaBlockSitesButton from './TaboolaBlockSitesButton';

interface TableToolbarProps {
  metricsData: PerformanceData[];
  selectionModel: string[];
  columnVisibilityModel: GridColumnVisibilityModel;
  onColumnOrderChange: (columns: GridColumnVisibilityModel) => void;
  getGraphData: () => Promise<GraphData>;
  getScalingDiagnostics: (
    data: ScalingDiagnosticsQueryData,
  ) => Promise<ScalingDiagnostic[]>;
}

function TableToolbar({
  metricsData,
  selectionModel,
  columnVisibilityModel,
  onColumnOrderChange,
  getGraphData,
  getScalingDiagnostics,
}: TableToolbarProps) {
  const dataSource = dashboardService.dataSource;

  return (
    <GridToolbarContainer>
      <Stack direction={'row'} spacing={1} sx={{ mt: 1.5, ml: 0.5 }}>
        <GridToolbarExport />
        <OrderTableColumns
          columnVisibilityModel={columnVisibilityModel}
          onColumnOrderChange={onColumnOrderChange}
        />
        {dataSource === FACEBOOK_DATA_SOURCE && (
          <GraphButton
            getGraphData={getGraphData}
            metrics={metricsData}
            selected={selectionModel}
          />
        )}
        {dataSource === FACEBOOK_DATA_SOURCE && (
          <FBAdsManagerButton metrics={metricsData} selected={selectionModel} />
        )}
        {(dataSource === FACEBOOK_DATA_SOURCE ||
          dataSource === QUORA_DATA_SOURCE) && (
          <ScalingDiagnosticsButton
            getScalingDiagnostics={getScalingDiagnostics}
            metrics={metricsData}
            selected={selectionModel}
          />
        )}
        {[TABOOLA_DATA_SOURCE, TABOOLA_REAL_TIME_DATA_SOURCE].includes(
          dataSource,
        ) && (
          <TaboolaBlockSitesButton
            metrics={metricsData}
            selected={selectionModel}
          />
        )}
      </Stack>
    </GridToolbarContainer>
  );
}

export default TableToolbar;
