import {
  GreaterThanKey,
  LessThanKey,
  EqualsNumberKey,
  IsOnOrBeforeDateKey,
  IsOnOrAfterDateKey,
  IsOnDateKey,
  ContainsKey,
  EqualsStringKey,
  DoesNotContainKey,
} from 'pages/Dashboard/utils/constants';

export default function convertPredicate(predicate: string | undefined) {
  switch (predicate) {
    case GreaterThanKey:
      return '>';
    case LessThanKey:
      return '<';
    case EqualsNumberKey:
    case IsOnDateKey:
      return '=';
    case IsOnOrBeforeDateKey:
      return '=<';
    case IsOnOrAfterDateKey:
      return '=>';
    case ContainsKey:
      return 'contains';
    case EqualsStringKey:
      return 'equals';
    case DoesNotContainKey:
      return 'does not contain';
  }
}
