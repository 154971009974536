import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { arrayContainsArray } from 'utils/transformArray';
import { formatLabel } from 'pages/Creator/utils/formatLabel';
import { GroupedTemplates } from 'pages/Creator/models/FacebookCreationTemplate';
import RoundedDialog from 'shared/components/RoundedDialog';

interface GroupSelectionProps {
  groupedTemplates: GroupedTemplates;
  selectedGroups: string[][];
  onSelectedUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    newGroups: string[],
  ) => void;
}

function GroupSelection({
  groupedTemplates,
  selectedGroups,
  onSelectedUpdate,
}: GroupSelectionProps) {
  const [open, setOpen] = useState(false);

  const renderCheckboxes = (
    data: GroupedTemplates | number[],
    prevHeaders: string[] = [],
  ): JSX.Element[] | JSX.Element => {
    const entries = Object.entries(data);
    const stop = Array.isArray(entries[0][1]);

    if (stop) {
      return (
        <Box sx={{ display: 'flex', mb: 0.5 }}>
          {entries.map(number => {
            const label = number[0];
            const currHeader = [...prevHeaders, label.toString()];
            return (
              <FormControlLabel
                key={label}
                label={String(label)}
                control={
                  <Checkbox
                    size="small"
                    checked={arrayContainsArray(selectedGroups, currHeader)}
                    onChange={event => onSelectedUpdate(event, currHeader)}
                    sx={{ p: 1 }}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    lineHeight: 1,
                  },
                }}
              />
            );
          })}
        </Box>
      );
    } else {
      return Object.entries(data).map(([key, value]) => {
        const currHeader = [...prevHeaders, key];
        return (
          <Box key={key} sx={{ mt: 0.5 }}>
            <FormControlLabel
              label={formatLabel(key)}
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0.3 }}
                  checked={arrayContainsArray(selectedGroups, currHeader)}
                  onChange={event => onSelectedUpdate(event, currHeader)}
                />
              }
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              {renderCheckboxes(value, [...prevHeaders, key])}
            </Box>
          </Box>
        );
      });
    }
  };

  return (
    <Box>
      <IconButton color="secondary" onClick={() => setOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <RoundedDialog maxWidth={'lg'} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Typography variant="h6" sx={{ px: 3, py: 2 }}>
            Select Template Groups
          </Typography>
        </Box>
        <Box sx={{ px: 4 }}>{renderCheckboxes(groupedTemplates)}</Box>
      </RoundedDialog>
    </Box>
  );
}

export default GroupSelection;
