import React from 'react';

import AppBar from '@mui/material/AppBar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useAuth } from 'pages/Authorization';

function AppBarNav() {
  const { handleLogout } = useAuth();

  return (
    <AppBar position="fixed" sx={{ zIndex: 'modal' }}>
      <Toolbar>
        <img
          src="/logo.svg"
          alt="SBLY"
          style={{ height: '30px', marginRight: '15px' }}
        />
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          Tools
        </Typography>
        <IconButton onClick={handleLogout} color="inherit">
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarNav;
