import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export default function DateTime(props: any) {
  const [displayedTime, setDisplayedTime] = useState(null);

  function handleChange(newTime: any) {
    setDisplayedTime(newTime);
    props.onChange(newTime.toISOString().split('.')[0]);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        inputVariant="outlined"
        label="publish time"
        size={props.size}
        style={{ width: '230px' }}
        value={displayedTime}
        minDate={Date()}
        onChange={newTime => {
          handleChange(newTime);
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
