import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridSortCellParams,
} from '@material-ui/data-grid';

import { sblyApiService } from 'pages/PostScheduler/service';

interface ScheduledVideosProps {
  headers: { Authorization: string };
}

interface ScheduledVideo {
  description: string;
  thumbnail_src: string;
}

const useStyles = makeStyles(theme => ({
  dataGrid: {
    margin: theme.spacing(1),
    display: 'flex',
  },
}));

const columns: GridColDef[] = [
  { field: 'owner_name', headerName: 'Page', width: 250 },
  { field: 'description', headerName: 'Message', width: 700 },
  {
    field: 'scheduled_publish_time',
    headerName: 'Scheduled Time',
    width: 200,
  },
  {
    field: 'thumbnail_src',
    headerName: 'Thumbnail',
    width: 130,
    renderCell: (params: GridSortCellParams) => {
      return (
        <img src={params.row.thumbnail_src} alt="" width="80" height="80" />
      );
    },
  },
];

export default function ScheduledVideos(props: ScheduledVideosProps) {
  const classes = useStyles();

  const [scheduledVideos, setScheduledvideos] = useState<ScheduledVideo[]>([]);

  const getScheduledVideos = useCallback(async () => {
    const response = await sblyApiService.get('facebook-videos/scheduled', {
      headers: props.headers,
    });

    return response.data;
  }, [props.headers]);

  useEffect(() => {
    getScheduledVideos().then(videos => setScheduledvideos(videos));
  });

  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        className={classes.dataGrid}
        columns={columns}
        rows={scheduledVideos}
        rowHeight={85}
        pageSize={25}
      />
    </div>
  );
}
