import React, { useState } from 'react';
import find from 'lodash/find';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { dashboardService } from 'pages/Dashboard';
import { EqualsStringKey } from 'pages/Dashboard/utils/constants';
import GraphData, { GraphKeyType } from 'pages/Dashboard/models/GraphData';
import LineChart from 'pages/Dashboard/components/graph/LineChart';
import LoadingLinearProgress from 'pages/Dashboard/components/helpers/LoadingLinearProgress';
import metricValueTypes from 'pages/Dashboard/utils/mappers/MetricValueTypes';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';
import SummaryData from 'pages/Dashboard/models/SummaryData';

const DATA_TABLE_GRAPHS_KEY = 'dataTableGraphsKey';

interface GraphButtonProps {
  getGraphData: () => Promise<GraphData>;
  metrics: PerformanceData[];
  selected: string[];
}

export interface Axes {
  leftAxis: GraphKeyType[];
  rightAxis: GraphKeyType[];
}

function GraphButton({ getGraphData, metrics, selected }: GraphButtonProps) {
  const storedGraphs = localStorage.getItem(DATA_TABLE_GRAPHS_KEY);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>();
  const [graphs, setGraphs] = useState<Axes[]>(
    storedGraphs ? JSON.parse(storedGraphs) : [{ leftAxis: [], rightAxis: [] }],
  );

  const handleOpenChart = async () => {
    setGraphData(undefined);
    setDialogOpen(true);

    const selectedMetric = find(metrics, metric => metric.id === selected[0]);
    const dimensionKeys = dashboardService.settings.dimensions.map(
      dimension => dimension.key,
    );

    for (const [key, value] of Object.entries(selectedMetric!)) {
      if (dimensionKeys.includes(key.toUpperCase())) {
        dashboardService.filters.push({
          columnKey: key.toUpperCase(),
          filterKey: EqualsStringKey,
          value,
        });
      }
    }

    if (selectedMetric?.data_source) {
      dashboardService.graphDataSource =
        selectedMetric?.data_source.toLowerCase();
    }

    const data = await getGraphData();
    if (Object.keys(data).includes('debug')) delete data.debug;

    setGraphData(data);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    localStorage.setItem(DATA_TABLE_GRAPHS_KEY, JSON.stringify(graphs));
  };

  const addNewGraph = () => {
    setGraphs([...graphs, { leftAxis: [], rightAxis: [] }]);
  };

  const deleteGraph = (idx: number) => {
    graphs.splice(idx, 1);
    setGraphs([...graphs]);
  };

  const updateGraphs = (
    idx: number,
    newAxes: { leftAxis: GraphKeyType[]; rightAxis: GraphKeyType[] },
  ) => {
    const updatedGraphs = [...graphs];
    updatedGraphs[idx] = newAxes;
    setGraphs(updatedGraphs);
  };

  return (
    <Box>
      <IconButton onClick={handleOpenChart} disabled={selected.length !== 1}>
        <TrendingUpIcon />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleDialogClose}
        open={dialogOpen}
        PaperProps={{ sx: { borderRadius: '0.75rem', px: 3, py: 1 } }}
        scroll="body"
      >
        {graphData ? (
          <div>
            {graphs.map((graph, idx) => {
              return (
                <LineChart
                  key={idx}
                  graphData={graphData}
                  leftAxisKeys={graph.leftAxis}
                  rightAxisKeys={graph.rightAxis}
                  onAxesUpdate={(newAxes: Axes) => updateGraphs(idx, newAxes)}
                  deleteGraph={() => deleteGraph(idx)}
                  deleteGraphDisabled={graphs.length === 1}
                  graphKeyTypes={Object.keys(graphData).map(key => {
                    return {
                      name: key,
                      type: metricValueTypes[key as keyof SummaryData],
                    };
                  })}
                />
              );
            })}
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                endIcon={<AddCircleOutlineIcon />}
                onClick={addNewGraph}
              >
                add new graph
              </Button>
            </Box>
          </div>
        ) : (
          <LoadingLinearProgress />
        )}
      </Dialog>
    </Box>
  );
}

export default GraphButton;
