import { GridColDef } from '@mui/x-data-grid';
import find from 'lodash/find';

import { DIMENSIONS } from 'pages/Dashboard/utils/constants';
import { dashboardService } from 'pages/Dashboard';
import { formatMetric } from 'pages/Dashboard/utils/format';
import SummaryData from 'pages/Dashboard/models/SummaryData';

const smallColumns = [
  DIMENSIONS.DOMAIN,
  DIMENSIONS.AD_TYPE,
  DIMENSIONS.DATE_PST,
];
const largeColumns = [
  DIMENSIONS.UTM_CAMPAIGN,
  DIMENSIONS.SLUG,
  DIMENSIONS.ADSET_NAME,
  DIMENSIONS.CAMPAIGN_NAME,
];

const metricColumnWidth = 100;
const dimensionColumnWidth = 250;

function formatMetricColumn(metric: string): GridColDef {
  const metricSetting = find(
    dashboardService.settings.metrics,
    setting => metric.toUpperCase() === setting.key,
  );
  return {
    field: metric,
    headerName: metricSetting?.name,
    width: metricColumnWidth,
    valueFormatter: ({ value }) => {
      return value !== null
        ? formatMetric(metric as keyof SummaryData, value)[1]
        : 'N/A';
    },
  };
}

function formatDimensionColumn(dimension: string) {
  let width = dimensionColumnWidth;
  if (largeColumns.includes(dimension)) {
    width = 2 * dimensionColumnWidth;
  } else if (smallColumns.includes(dimension)) {
    width = metricColumnWidth;
  }

  const dimensionSetting = find(
    dashboardService.settings.dimensions,
    setting => dimension.toUpperCase() === setting.key,
  );
  return {
    width,
    field: dimension,
    headerName: dimensionSetting?.name,
  };
}

function formatCustomColumn(key: string, renderFunction: any) {
  const headerName = key
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return {
    headerName,
    width: metricColumnWidth,
    field: key,
    renderCell: renderFunction,
  };
}

export default function setTableColumns(
  dataKeys: string[],
  renderFunction: any,
) {
  const metricKeys = dashboardService.settings.metrics.map(
    metric => metric.key,
  );
  const dimensionKeys = dashboardService.settings.dimensions.map(
    dimension => dimension.key,
  );

  return dataKeys.map(dataKey => {
    if (metricKeys.includes(dataKey.toUpperCase()))
      return formatMetricColumn(dataKey);
    else if (dimensionKeys.includes(dataKey.toUpperCase()))
      return formatDimensionColumn(dataKey);
    else return formatCustomColumn(dataKey, renderFunction);
  });
}
