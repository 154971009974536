import React, { Fragment, useEffect, useState } from 'react';
import find from 'lodash/find';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CachedIcon from '@mui/icons-material/Cached';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { DashboardService, DATA_SOURCE_KEY } from 'services';
import { FilterColumn } from 'pages/Dashboard/models/Settings';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';
import { requiredDimensions } from 'pages/Dashboard/utils/constants';
import { sessionStorageGet, sessionStorageSet } from 'utils/sessionStorage';
import { Segment } from 'pages/Dashboard/models/CustomDimension';
import { ScalingDiagnosticsQueryData } from 'pages/Dashboard/models/ScalingDiagnostics';
import AddCustomDimensions from 'pages/Dashboard/components/dimension/AddCustomDimensions';
import AddFilters from 'pages/Dashboard/components/filter/AddFilters';
import Alert from 'pages/Dashboard/components/helpers/Alert';
import CurrentFilters from 'pages/Dashboard/components/filter/CurrentFilters';
import CurrentSegments from 'pages/Dashboard/components/dimension/CurrentSegments';
import DataSources from 'pages/Dashboard/components/dataSources/DataSources';
import DataTable from 'pages/Dashboard/components/dataTable/DataTable';
import DateRange from 'pages/Dashboard/components/dateRangeSelection/DateRange';
import DialogButton from 'pages/Dashboard/components/helpers/DialogButton';
import LoadingLinearProgress from 'pages/Dashboard/components/helpers/LoadingLinearProgress';
import Order from 'pages/Dashboard/models/Order';
import OrderBy from 'pages/Dashboard/components/helpers/OrderBy';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';
import SelectedDimensions from 'pages/Dashboard/components/dimension/SelectedDimensions';
import SummaryData from 'pages/Dashboard/models/SummaryData';
import SummaryMetrics from 'pages/Dashboard/components/summary/SummaryMetrics';

export const dashboardService = new DashboardService();

const METRIC_FILTERS_KEY = 'metric-filters';
const DIMENSION_FILTERS_KEY = 'dimension-filters';
const CUSTOM_DIMENSION_FILTERS_KEY = 'custom-dimension-filters';

const dashboardMetricFilters = (storageKey: string) => {
  const storedMetrics = localStorageGet(storageKey);
  const storedMetricsKeys = storedMetrics?.map(
    (metric: FilterColumn) => metric.key,
  );

  return dashboardService.settings.metrics?.map(metric => {
    if (storedMetrics && storedMetricsKeys.includes(metric.key)) {
      return find(
        storedMetrics,
        storedMetric => storedMetric.key === metric.key,
      );
    } else {
      return metric;
    }
  });
};

const storedDimensionFilters = (storageKey: string) => {
  const storedDimensions = localStorageGet(storageKey);
  const storedDimensionKeys = storedDimensions?.map(
    (dimension: FilterColumn) => dimension.key,
  );

  return dashboardService.settings.dimensions?.map(
    (dimension: FilterColumn) => {
      if (storedDimensions) {
        if (storedDimensionKeys.includes(dimension.key)) {
          return find(
            storedDimensions,
            storedDimension => storedDimension.key === dimension.key,
          );
        } else {
          dimension.checked = false;
          return dimension;
        }
      } else {
        if (requiredDimensions.includes(dimension.key)) {
          dimension.checked = true;
        } else {
          dimension.checked = false;
        }
        return dimension;
      }
    },
  );
};

const storedCustomDimensionFilters = (storageKey: string) => {
  const storedCustomDimensios = localStorageGet(storageKey);
  return storedCustomDimensios ? storedCustomDimensios : [];
};

export default function Dashboard() {
  const [noDataAlert, setNoDataAlert] = useState(false);
  const [currentDataSource, setCurrentDataSource] = useState(
    sessionStorageGet(DATA_SOURCE_KEY) || 'facebook',
  );
  const [metrics, setMetrics] = useState<PerformanceData[]>([]);
  const [sumMetrics, setSumMetrics] = useState<SummaryData>();
  const [loading, setLoading] = useState(false);
  const [renderFilters, setRenderFilters] = useState(false);
  const [requestErrored, setRequestErrored] = useState(false);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<
    FilterColumn[]
  >(dashboardService.settings.metrics);
  const [selectedDimensionFilters, setSelectedDimensionFilters] = useState<
    FilterColumn[]
  >(dashboardService.settings.dimensions);
  const [customDimensions, setCustomDimensions] = useState<Segment[]>(
    dashboardService.customDimensions,
  );
  const [orderBy, setOrderBy] = useState(dashboardService.orderByKey);
  const [orderDirection, setOrderDirection] = useState<Order>(
    dashboardService.orderKey,
  );

  const METRICS_BY_DATA_SOURCE_KEY = `${METRIC_FILTERS_KEY}-${currentDataSource}`;
  const DIMENSIONS_BY_DATA_SOURCE_KEY = `${DIMENSION_FILTERS_KEY}-${currentDataSource}`;
  const CUSTOM_DIMENSIONS_BY_DATA_SOURCE_KEY = `${CUSTOM_DIMENSION_FILTERS_KEY}-${currentDataSource}`;

  useEffect(() => {
    setupInitialPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSource]);

  useEffect(() => {
    const alertUser = (event: any) => {
      event.preventDefault();
      return (event.returnValue = '');
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const getSettings = async () => {
    try {
      await dashboardService.getSettings(currentDataSource);
      setRequestErrored(false);
    } catch (err) {
      console.error(err);
      setRequestErrored(true);
    }
  };

  const setupInitialPage = async () => {
    await getSettings();

    const metricFilters = dashboardMetricFilters(METRICS_BY_DATA_SOURCE_KEY);
    const dimensionFilters = storedDimensionFilters(
      DIMENSIONS_BY_DATA_SOURCE_KEY,
    );
    const customDimensionFilters = storedCustomDimensionFilters(
      CUSTOM_DIMENSIONS_BY_DATA_SOURCE_KEY,
    );

    setSelectedMetricFilters(metricFilters);
    setSelectedDimensionFilters(dimensionFilters);
    setCustomDimensions(customDimensionFilters);

    getMetrics(metricFilters, dimensionFilters, customDimensionFilters);
  };

  const resetStoredFilters = () => {
    localStorage.removeItem(METRICS_BY_DATA_SOURCE_KEY);
    localStorage.removeItem(DIMENSIONS_BY_DATA_SOURCE_KEY);
    localStorage.removeItem(CUSTOM_DIMENSIONS_BY_DATA_SOURCE_KEY);
    dashboardService.resetService();

    setupInitialPage();
  };

  const updateCustomDimensionFilters = (
    newCustomDimensions: Segment[],
    removal = false,
  ) => {
    const updatedDimensions = removal
      ? newCustomDimensions
      : [...customDimensions, ...newCustomDimensions];

    setCustomDimensions(updatedDimensions);
  };

  const getMetrics = async (
    defaultMetrics?: FilterColumn[],
    defaultDimensions?: FilterColumn[],
    defaultCustomDimensions?: Segment[],
  ) => {
    setLoading(true);

    dashboardService.orderByKey = orderBy;
    dashboardService.orderKey = orderDirection;

    const currentMetricFilters = defaultMetrics || selectedMetricFilters;
    const currentDimensionFilters =
      defaultDimensions || selectedDimensionFilters;
    const currentCustomDimensionFilters =
      defaultCustomDimensions || customDimensions;

    try {
      const resp = await dashboardService.getMetrics(
        defaultMetrics || selectedMetricFilters,
        defaultDimensions || selectedDimensionFilters,
        defaultCustomDimensions || customDimensions,
        currentDataSource,
      );
      setRenderFilters(true);
      setRequestErrored(false);

      if (resp.data.length > 0) {
        setMetrics(resp.data);
        setSumMetrics(resp.summary);
      } else {
        setNoDataAlert(true);
      }

      localStorageSet(METRICS_BY_DATA_SOURCE_KEY, currentMetricFilters);
      localStorageSet(DIMENSIONS_BY_DATA_SOURCE_KEY, currentDimensionFilters);
      localStorageSet(
        CUSTOM_DIMENSIONS_BY_DATA_SOURCE_KEY,
        currentCustomDimensionFilters,
      );
      sessionStorageSet(DATA_SOURCE_KEY, currentDataSource);
    } catch (err) {
      console.error(err);
      setRequestErrored(true);
    } finally {
      setLoading(false);
    }
  };

  const getGraphData = async () => {
    return await dashboardService.getGraphData(
      selectedMetricFilters,
      selectedDimensionFilters,
      customDimensions,
    );
  };

  const getScalingDiagnostics = async (query: ScalingDiagnosticsQueryData) => {
    return await dashboardService.getScalingDiagnostics({
      ...query,
      dataSource: currentDataSource,
    });
  };

  return (
    <Fragment>
      <Typography variant="h5">Dashboard Overview</Typography>
      {renderFilters &&
      dashboardService.settings.filterOperations &&
      !requestErrored ? (
        <Fragment>
          <SummaryMetrics metrics={sumMetrics} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              alignItems: 'center',
              boxShadow: 2,
              borderRadius: 3,
              p: 2,
              mb: 4,
            }}
          >
            <Box>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={noDataAlert}
                autoHideDuration={10000}
                onClose={() => setNoDataAlert(false)}
              >
                <Alert
                  onClose={() => setNoDataAlert(false)}
                  severity="warning"
                  sx={{ width: '100%' }}
                >
                  {'No data found. Please update parameters and try again.'}
                </Alert>
              </Snackbar>
              <Typography variant="h6">Parameters</Typography>
              <Divider light={true} sx={{ my: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <DateRange dashboardService={dashboardService} />
                <OrderBy
                  dashboardService={dashboardService}
                  selectedMetrics={selectedMetricFilters}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  setOrderBy={setOrderBy}
                  setOrderDirection={setOrderDirection}
                />
              </Box>
              <Divider light={true} sx={{ my: 1 }} />
              <DataSources
                dataSources={[currentDataSource]}
                onDataSourcesChange={dataSources =>
                  setCurrentDataSource(dataSources[0])
                }
              />
              <Divider light={true} sx={{ my: 1 }} />
              <SelectedDimensions
                dimensions={selectedDimensionFilters}
                onDimensionsChange={newDimensions =>
                  setSelectedDimensionFilters(newDimensions)
                }
              />
            </Box>
            <Divider light={true} sx={{ my: 1 }} />
            <Box sx={{ mt: 1 }}>
              <DialogButton label="Filter">
                <AddFilters
                  filterOperations={dashboardService.settings.filterOperations}
                  metrics={selectedMetricFilters}
                  dimensions={selectedDimensionFilters}
                  onMetricFiltersChange={newMetricFilters =>
                    setSelectedMetricFilters(newMetricFilters)
                  }
                  onDimensionFiltersChange={newDimensionFilters =>
                    setSelectedDimensionFilters(newDimensionFilters)
                  }
                />
              </DialogButton>
              <CurrentFilters
                currentMetricsFilters={selectedMetricFilters}
                currentDimensionsFilters={selectedDimensionFilters}
                onMetricsFiltersChange={newMetricFilters =>
                  setSelectedMetricFilters(newMetricFilters)
                }
                onDimensionsFiltersChange={newDimensionsFilters =>
                  setSelectedDimensionFilters(newDimensionsFilters)
                }
              />
              <DialogButton label="Segment">
                <AddCustomDimensions
                  filterOperations={dashboardService.settings.filterOperations}
                  columns={[
                    ...selectedMetricFilters,
                    ...selectedDimensionFilters,
                  ]}
                  onCustomDimensionsChange={newDimensions =>
                    updateCustomDimensionFilters(newDimensions)
                  }
                />
              </DialogButton>
            </Box>
            <CurrentSegments
              segments={customDimensions}
              columns={[...selectedMetricFilters, ...selectedDimensionFilters]}
              onSegmentsChange={newCustomDimensions =>
                updateCustomDimensionFilters(newCustomDimensions, true)
              }
            />
            <Divider light={true} sx={{ my: 1 }} />
            <LoadingButton
              onClick={() => getMetrics()}
              endIcon={<CachedIcon />}
              variant="contained"
              loading={loading}
              sx={{
                borderRadius: '0.5rem',
                height: 50,
                width: 250,
                mt: 1,
                mb: 1,
              }}
            >
              <Typography fontSize={17} variant="button">
                Update
              </Typography>
            </LoadingButton>
          </Box>
          {sumMetrics && (
            <DataTable
              dataSource={currentDataSource}
              loading={loading}
              metricsData={metrics}
              getGraphData={getGraphData}
              getScalingDiagnostics={getScalingDiagnostics}
            />
          )}
        </Fragment>
      ) : (
        <div>
          {requestErrored ? (
            <Box sx={{ mt: 2 }}>
              <Alert severity="error">Error loading dashboard</Alert>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={resetStoredFilters}
                sx={{ mt: 1 }}
              >
                reset filters and reload page
              </LoadingButton>
            </Box>
          ) : (
            <LoadingLinearProgress />
          )}
        </div>
      )}
    </Fragment>
  );
}
