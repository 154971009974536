import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import find from 'lodash/find';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { facebookCreatorService } from './CreateCampaignFromLink';
import { FormattedArticleCandidate } from 'pages/Creator/models/ArticleCandidate';
import { formatPercentToDecimal } from 'utils/formatNumber';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';
import CustomNumericInput from 'pages/Creator/components/CustomNumericInput';
import DateRangePicker from 'pages/Creator/components/DateRangeSelector';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';
import FacebookCreatorService from 'services/FacebookCreatorService';
import formatCandidates from 'pages/Creator/utils/formatCandidates';
import LaunchedCampaigns from 'pages/Creator/components/facebook/LaunchedCampaigns';
import MessageTextField from 'pages/PostScheduler/Facebook/components/MessageTextField';
import MultiURLForm from 'shared/components/MultiURLForm';
import RoundedPaper from 'shared/components/RoundedPaper';

const creatorService = new FacebookCreatorService();

const CTR_KEY = 'ctr-key';
const RPM_KEY = 'rmp-key';
const storedCTR = localStorageGet(CTR_KEY);
const storedRPM = localStorageGet(RPM_KEY);

function CreateCampaignFromCandidates() {
  const [urls, setURLs] = useState<string[]>(['']);
  const [articleCandidates, setArticleCandidates] = useState<
    FormattedArticleCandidate[]
  >([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const [createCampaignsLoading, setCreateCampaignsLoading] = useState(false);
  const [hideArticlesLoading, setHideArticlesLoading] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(1, 'week'),
  );
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [minRPM, setMinRPM] = useState(storedRPM || '15');
  const [minCTR, setMinCTR] = useState(storedCTR || '3');
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [launchedCampaigns, setLaunchedCampaigns] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [createLoading, setCreateLoading] = useState(false);

  const columns: GridColDef[] = [
    { field: 'url', headerName: 'Article', width: 550 },
    { field: 'ctr', headerName: 'CTR', width: 80 },
    { field: 'rpm', headerName: 'RPM', width: 80 },
    { field: 'organic_reach', headerName: 'Reach', width: 80 },
    { field: 'cpm', headerName: 'CPM', width: 80 },
    { field: 'total_revenue', headerName: 'Revenue', width: 90 },
    { field: 'percent_reach', headerName: '% Reach', width: 80 },
    { field: 'caption', headerName: 'Caption', width: 370 },
    {
      field: 'new_caption',
      headerName: 'New Caption',
      width: 370,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <MessageTextField
            label="caption"
            multiline={true}
            onChange={caption => onCaptionChange(params.row.slug, caption)}
          />
        );
      },
    },
    { field: 'title', headerName: 'Title', width: 370 },
    { field: 'page_name', headerName: 'Page', width: 160 },
    { field: 'posted_at_pst', headerName: 'Posted', width: 110 },
    { field: 'article_type', headerName: 'Type', width: 100 },
  ];

  useEffect(() => {
    getArticleCandidates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateCampaignsFromURLs = async () => {
    setCreateLoading(true);
    const articles = await Promise.all(
      urls
        .filter(url => url !== '')
        .map(async url => {
          const data = await facebookCreatorService.getLinkInfo(url);
          const articleType = await facebookCreatorService.getArticleType(
            data.domain,
            data.slug,
          );
          return {
            domain: data.domain,
            slug: data.slug,
            caption: data.caption,
            article_type: articleType,
          };
        }),
    );

    const resp = await facebookCreatorService.createCampaigns({
      articles,
    });
    if (Array.isArray(resp) && resp.length > 0) {
      setLaunchedCampaigns(resp);
      setURLs(['']);
    }
    setCreateLoading(false);
  };

  const getArticleCandidates = async () => {
    setCandidatesLoading(true);
    const candidates = await creatorService.getArticleCandidates({
      minRPM,
      minCTR: formatPercentToDecimal(minCTR),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });

    setArticleCandidates(formatCandidates(candidates));
    setCandidatesLoading(false);
  };

  const findSelectedCandidates = () => {
    return selectionModel.map(selectedCandidate =>
      find(articleCandidates, candidate => candidate.id === selectedCandidate),
    );
  };

  const removeSelectedCandidates = () => {
    const newCandidates = articleCandidates.filter(
      article => !selectionModel.includes(article.id),
    );
    setArticleCandidates(newCandidates);
  };

  const createCampaigns = async () => {
    setCreateCampaignsLoading(true);
    const selectedCandidates = findSelectedCandidates();
    const responseData = await creatorService.createCampaigns({
      articles: selectedCandidates,
    });

    if (Array.isArray(responseData)) {
      setLaunchedCampaigns(responseData);
      removeSelectedCandidates();
    } else {
      setError(responseData.description);
    }
    setCreateCampaignsLoading(false);
  };

  const hideCandidates = async () => {
    setHideArticlesLoading(true);
    const selectedCandidates = findSelectedCandidates();
    await creatorService.hideArticleCandidates({
      articles: selectedCandidates,
    });
    removeSelectedCandidates();
    setHideArticlesLoading(false);
  };

  const updateCTR = (ctr: string) => {
    localStorageSet(CTR_KEY, ctr);
    setMinCTR(ctr);
  };

  const updateRPM = (rpm: string) => {
    localStorageSet(RPM_KEY, rpm);
    setMinRPM(rpm);
  };

  const onCaptionChange = (slug: string, caption: string) => {
    const updatedCandidates = articleCandidates.map(candidate => {
      if (candidate.slug === slug) {
        candidate.caption = caption;
      }
      return candidate;
    });
    setArticleCandidates(updatedCandidates);
  };

  return (
    <Box>
      <ErrorAlert
        open={error !== ''}
        onClose={() => setError('')}
        message={error}
      />
      <LaunchedCampaigns
        campaigns={launchedCampaigns}
        open={launchedCampaigns.length > 0}
        onClose={() => setLaunchedCampaigns([])}
      />

      <RoundedPaper
        sx={{
          width: '100%',
          p: 4,
          pt: 3,
          my: 3,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'grey.300',
            px: 10,
            width: '70%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Typography variant="h5">Create From URL</Typography>
          </Box>
          <MultiURLForm
            urls={urls}
            onURLsUpdate={urls => setURLs(urls)}
            size="small"
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <LoadingButton
              size="medium"
              disabled={createLoading}
              loading={createLoading}
              variant="contained"
              loadingPosition="end"
              endIcon={<AddIcon />}
              onClick={handleCreateCampaignsFromURLs}
              sx={{ whiteSpace: 'nowrap', width: 145 }}
            >
              create
            </LoadingButton>
          </Box>
        </Box>
      </RoundedPaper>

      <RoundedPaper
        sx={{
          width: '100%',
          p: 4,
          pt: 3,
          mt: 1,
          mb: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5">Create From Candidates</Typography>
        </Box>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={2}
          sx={{ mt: 4, mb: 3 }}
        >
          <TextField
            id="rpm"
            label="min RPM"
            value={minRPM}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateRPM(event.target.value);
            }}
            InputProps={{
              inputComponent: CustomNumericInput as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            size="small"
            sx={{ width: 90 }}
          />
          <TextField
            id="ctr"
            label="min CTR"
            value={minCTR}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateCTR(event.target.value);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            size="small"
            sx={{ width: 70 }}
          />
          <DateRangePicker
            width={310}
            size="small"
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={newDate => {
              setStartDate(newDate);
            }}
            onEndDateChange={newDate => {
              setEndDate(newDate);
            }}
          />
          <LoadingButton
            size="small"
            loading={candidatesLoading}
            variant="contained"
            onClick={getArticleCandidates}
            sx={{ width: 100 }}
          >
            update
          </LoadingButton>
        </Stack>
        <DataGrid
          autoHeight
          checkboxSelection
          rowsPerPageOptions={[15, 30, 50]}
          pageSize={15}
          getRowHeight={() => 'auto'}
          loading={candidatesLoading}
          columns={columns}
          rows={articleCandidates}
          selectionModel={selectionModel}
          onSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
        />
        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={1}
          sx={{ mt: 3 }}
        >
          <LoadingButton
            loading={createCampaignsLoading}
            variant="contained"
            onClick={createCampaigns}
            sx={{ width: 180 }}
          >
            create campaigns
          </LoadingButton>
          <LoadingButton
            loading={hideArticlesLoading}
            variant="contained"
            color="secondary"
            onClick={hideCandidates}
            sx={{ width: 180 }}
          >
            hide articles
          </LoadingButton>
        </Stack>
      </RoundedPaper>
    </Box>
  );
}

export default CreateCampaignFromCandidates;
