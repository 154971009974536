import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

function LoadingLinearProgress() {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 8,
          mb: 2,
        }}
      >
        <Typography variant="button">Loading...</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LinearProgress
          sx={{
            height: 7,
            width: '50%',
            borderRadius: '0.5rem',
            mb: 8,
          }}
        />
      </Box>
    </div>
  );
}

export default LoadingLinearProgress;
