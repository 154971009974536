import { io } from 'socket.io-client';

const wsURL =
  process.env.NODE_ENV === 'production'
    ? 'wss://api.sbly.com'
    : 'http://localhost:3000';

const socket = io(wsURL, {
  transports: ['websocket'],
});

export default socket;
