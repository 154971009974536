import React, { useEffect, useState } from 'react';
import { cloneDeep, remove } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { GridRowId } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface TimeSlotUsage {
  available: GridRowId[];
  used: GridRowId[];
}

interface PageTimeSlotsProps {
  rowSelected: boolean;
  timeSlots: string[];
  timeSlotsUsage: TimeSlotUsage;
  onChange: (value: Dayjs) => void;
  onUsageChange: (newUsage: TimeSlotUsage) => void;
}

export default function PageTimeSlots({
  rowSelected,
  timeSlots,
  timeSlotsUsage,
  onChange,
  onUsageChange,
}: PageTimeSlotsProps) {
  const [time, setTime] = useState('');

  useEffect(() => {
    const usage = cloneDeep(timeSlotsUsage) || {};
    const timeSlotsFound = Object.keys(usage).length !== 0;

    const selectTimeSlot = () => {
      if (usage.available.length === 0) {
        usage.available = [...timeSlots];
        usage.used = [];
      }

      const selectedTimeSlot = usage.available.pop();
      if (selectedTimeSlot) {
        usage.used.push(selectedTimeSlot);
        handleChange(selectedTimeSlot as string);
      }

      onUsageChange(usage);
    };

    const removeTimeSlotSelection = () => {
      setTime('');

      if (usage.used.includes(time)) {
        usage.available.push(time);
        remove(usage.used, element => element === time);
        onUsageChange(usage);
      }
    };

    if (timeSlotsFound) {
      if (rowSelected) {
        selectTimeSlot();
      } else {
        removeTimeSlotSelection();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelected]);

  const handleChange = (newTime: SelectChangeEvent | string) => {
    const selectedTime =
      typeof newTime === 'string' ? newTime : (newTime.target.value as string);
    setTime(selectedTime);
    onChange(dayjs(selectedTime, 'h:mmA'));
  };

  return (
    <Box>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="time-select-label">time slot</InputLabel>
        <Select
          labelId="time-select-label"
          id="time-select"
          size="small"
          style={{ width: '100px' }}
          value={time}
          label="Time"
          onChange={handleChange}
        >
          {timeSlots?.map(timeSlot => {
            return (
              <MenuItem key={timeSlot.toString()} value={timeSlot}>
                {timeSlot}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
