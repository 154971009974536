import React from 'react';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

interface URLFormProps {
  url: string;
  loading: boolean;
  onURLUpdate: (field: string, value: string) => void;
  onURLSubmit: (url: string) => void;
  size?: 'small' | 'medium';
}

function URLForm({
  url,
  onURLUpdate,
  loading,
  onURLSubmit,
  size,
}: URLFormProps) {
  return (
    <Box sx={{ display: 'flex', my: 2 }}>
      <Box sx={{ width: '100%', pr: 1 }}>
        <TextField
          fullWidth
          id="urlInput"
          label="URL"
          variant="outlined"
          value={url}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onURLUpdate('url', event.target.value);
          }}
          inputProps={{
            autoComplete: 'off',
          }}
          size={size ?? 'medium'}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          size="large"
          loading={loading}
          variant="contained"
          loadingPosition="end"
          endIcon={<BorderColorIcon />}
          onClick={() => {
            onURLSubmit(url);
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          fill form
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default URLForm;
