import React from 'react';
import { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';

import ControlledDatePicker from 'shared/components/ControlledDatePicker';

interface DateSelectorProps {
  onStartDateChange: (date: Dayjs) => void;
  onEndDateChange: (date: Dayjs) => void;
  onPresetRangeUpdate: (range: string) => void;
  startDate: Dayjs;
  endDate: Dayjs;
}

function DateRangeSelector({
  onStartDateChange,
  onEndDateChange,
  onPresetRangeUpdate,
  startDate,
  endDate,
}: DateSelectorProps) {
  return (
    <Stack direction="row" spacing={2} sx={{ ml: 2, mt: 1 }}>
      <ControlledDatePicker
        label="start"
        date={startDate}
        onDateChange={newDate => {
          onStartDateChange(newDate);
          onPresetRangeUpdate('custom');
        }}
      />
      <ControlledDatePicker
        label="end"
        date={endDate}
        onDateChange={newDate => {
          onEndDateChange(newDate);
          onPresetRangeUpdate('custom');
        }}
      />
    </Stack>
  );
}

export default DateRangeSelector;
