import React from 'react';
import CustomToggle from 'shared/components/CustomToggle';
import ScalingDiagnostic, {
  ScalingChangeTypeLabel,
  ScalingGraphType,
} from 'pages/Dashboard/models/ScalingDiagnostics';

interface DiagnosticChartToggleProps {
  diagnostics: ScalingDiagnostic[];
  graphType: ScalingGraphType;
  changeType: ScalingChangeTypeLabel;
  showNonChanges: boolean;
  updateGraphType: (graphType: ScalingGraphType) => void;
  updateChangeType: (graphType: ScalingChangeTypeLabel) => void;
  updateShowChanges: (showChanges: boolean) => void;
}

function DiagnosticChartToggle({
  diagnostics,
  changeType,
  graphType,
  showNonChanges,
  updateGraphType,
  updateChangeType,
  updateShowChanges,
}: DiagnosticChartToggleProps) {
  const hourlyDailyToggle = (
    <CustomToggle<ScalingGraphType>
      selectedValue={graphType}
      values={[ScalingGraphType.Daily, ScalingGraphType.Hourly]}
      onValueUpdate={graphType => {
        updateGraphType(graphType);
      }}
    />
  );

  const hasMultipleDiagnostics = diagnostics.length > 1;
  const bidBudgetToggle = hasMultipleDiagnostics ? (
    <CustomToggle<string>
      selectedValue={changeType}
      values={[
        ScalingChangeTypeLabel.BudgetChange,
        ScalingChangeTypeLabel.BidChange,
      ]}
      onValueUpdate={changeType => {
        updateChangeType(changeType as ScalingChangeTypeLabel);
      }}
    />
  ) : null;

  const showNonChangesToggle = (
    <CustomToggle<string>
      selectedValue={showNonChanges ? 'All' : 'Change Only'}
      values={['All', 'Change Only']}
      onValueUpdate={selection => {
        updateShowChanges(selection === 'All' ? true : false);
      }}
    />
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {hourlyDailyToggle}
      {bidBudgetToggle}
      {showNonChangesToggle}
    </div>
  );
}

export default DiagnosticChartToggle;
