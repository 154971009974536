import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';

const RoundedPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: 10,
  padding: 15,
  elevation: 5,
  display: 'inline-block',
}));

export default RoundedPaper;
