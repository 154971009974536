import dayjs from 'dayjs';

import {
  FACEBOOK_DATA_SOURCE,
  QUORA_DATA_SOURCE,
} from 'pages/Dashboard/utils/constants';
import BaseService from 'services/BaseService';
import QuoraCampaignParams from 'pages/Creator/models/QuoraCampaignParams';
import { convertToPST } from 'utils/formatDate';

export const QUORA_ACCOUNT = QUORA_DATA_SOURCE;

class CreatorService extends BaseService {
  creatorPath = 'creator';

  async getLinkInfo(link: string) {
    try {
      const resp = await this.http.get('wordpress/live-link-information', {
        params: { link },
      });
      return resp.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getTemplates(
    source: typeof QUORA_DATA_SOURCE | typeof FACEBOOK_DATA_SOURCE,
  ) {
    if (source === QUORA_DATA_SOURCE) {
      return [
        {
          objective: 'Conversions',
          conversionEvent: 'Purchase',
          budget: '100',
          startDate: dayjs().add(1, 'day'),
          startHour: '4:00 AM',
          locations: ['United States'],
          devicesAndBrowsers: ['Android'],
          bidAmount: '0.50',
          adDelivery: 'Optimize for conversions',
          adName: 'Conversion ad',
          format: 'image',
          businessName: 'Shareably',
          callToAction: 'Learn More',
        },
      ];
    } else if (source === FACEBOOK_DATA_SOURCE) {
      const resp = await this.http.get(
        `${this.creatorPath}/${FACEBOOK_DATA_SOURCE}/ad-templates`,
      );
      return resp.data;
    }
  }

  async createCampaign(params: QuoraCampaignParams) {
    params.startDateString = convertToPST(params.startDate)?.format(
      'MM/DD/YYYY',
    );

    try {
      const resp = await this.http.post(
        `${this.creatorPath}/${QUORA_ACCOUNT}`,
        params,
      );
      return resp.data;
    } catch (err) {
      if (err.response) {
        console.error(
          'Server responded with status code:',
          err.response.status,
        );
        console.error('Response data:', err.response.data);
      } else {
        console.error(err);
      }
    }
  }

  async getCreationStatus(id: string) {
    try {
      const resp = await this.http.get(`${this.creatorPath}/creation-status`, {
        params: {
          id,
        },
      });
      return resp.data;
    } catch (err) {
      return err;
    }
  }
}

export default CreatorService;
