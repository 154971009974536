import React, { useState } from 'react';

import { GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SplitTestParams } from 'pages/articles/SplitTest/models/CreateSplitTestParams';
import { Test } from 'pages/articles/SplitTest/models/CreateSplitTestParams';
import { TestResult } from '../models/TestResult';
import ArticlesService from 'services/ArticlesService';
import RoundedDialog from 'shared/components/RoundedDialog';
import StripedDataGrid from 'shared/components/StripedDataGrid';

interface ActiveTestsProps {
  tests: SplitTestParams[] | undefined;
  onRemoveTestGroups: (groups: SplitTestParams[]) => void;
  loading: boolean;
}

const articleService = new ArticlesService();

function ActiveTests({ tests, onRemoveTestGroups, loading }: ActiveTestsProps) {
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [testResults, setTestResults] = useState<TestResult[]>();
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);

  const handleMoreInfo = async () => {
    setResultsLoading(true);

    if (tests) {
      const selection = tests.filter(
        test => test.id && selectionModel.includes(test.id),
      );
      const url = new URL(selection[0].experiments[0].url);
      const slug = url.pathname.replaceAll('/', '');

      if (slug) {
        const results = await articleService.getSplitTestResults(slug);
        if (results) setTestResults(results);
      }
    }

    setResultsLoading(false);
    setMoreInfoOpen(true);
  };

  const handleRemove = () => {
    if (tests) {
      onRemoveTestGroups(
        tests.filter(test => test.id && selectionModel.includes(test.id)),
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'experiments',
      headerName: 'Test Groups',
      width: 2000,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ my: 2 }}>
            {params.row.experiments.map((experiment: Test, idx: number) => (
              <Stack key={idx} direction="row" spacing={3}>
                <Typography variant="button">
                  Weight: {experiment.weight * 100}%
                </Typography>
                <Typography>{experiment.url}</Typography>
              </Stack>
            ))}
          </Box>
        );
      },
    },
  ];
  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        sx={{ mb: 1 }}
      >
        <Fab
          color="info"
          size="small"
          disabled={selectionModel.length !== 1}
          onClick={handleMoreInfo}
        >
          <InfoIcon />
        </Fab>
        <Fab
          color="error"
          size="small"
          disabled={selectionModel.length === 0}
          onClick={handleRemove}
        >
          <DeleteIcon />
        </Fab>
      </Stack>

      <RoundedDialog
        open={moreInfoOpen}
        onClose={() => {
          setMoreInfoOpen(false);
          setTestResults([]);
        }}
      >
        <Stack spacing={2} sx={{ p: 3 }}>
          {testResults?.map((result, idx) => (
            <Box key={idx}>
              <Stack>
                <Typography>Variation: {result.slug}</Typography>
                <Typography>
                  Page Views: {result.total_page_views.toLocaleString()}
                </Typography>
                <Typography>
                  Revenue: ${result.total_revenue.toFixed(2)}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
      </RoundedDialog>

      <StripedDataGrid
        checkboxSelection
        autoHeight
        loading={loading || resultsLoading}
        getRowHeight={() => 'auto'}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        columns={columns}
        rows={tests ? tests : []}
        selectionModel={selectionModel}
        onSelectionModelChange={newSelection => {
          setSelectionModel(newSelection);
        }}
      />
    </Box>
  );
}

export default ActiveTests;
