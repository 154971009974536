import React, { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

interface IconButtonPopoverProps {
  children: ReactNode;
  icon: ReactNode;
}

function IconButtonPopover({ children, icon }: IconButtonPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box>
      <IconButton onClick={handlePopoverOpen}>{icon}</IconButton>
      <Popover
        anchorEl={anchorEl}
        open={openPopover}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { borderRadius: '0.75rem' } }}
      >
        {children}
      </Popover>
    </Box>
  );
}

export default IconButtonPopover;
