export const categories: string[] = [
  '--select category--',
  'animals',
  'beauty',
  'cats',
  'dance',
  'diy',
  'dogs',
  'entertainment',
  'food',
  'funny',
  'health',
  'impromptu',
  'inspiring',
  'music',
  'nature',
  'parents',
  'people',
  'rescue',
  'talent',
  'touching',
  'tours',
  'unique',
];
