import React from 'react';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import getURLParam from 'utils/getURLParam';
import RoundedDialog from 'shared/components/RoundedDialog';

interface LaunchedCampaignsProps {
  campaigns: string[];
  open: boolean;
  onClose: () => void;
}

function LaunchedCampaigns(props: LaunchedCampaignsProps) {
  const { campaigns, open, onClose } = props;

  return (
    <RoundedDialog onClose={onClose} open={open}>
      <Typography sx={{ ml: 2, mt: 1 }} variant="h6">
        Launched Campaigns:
      </Typography>
      <List>
        {campaigns.map(campaign => (
          <ListItem key={campaign}>
            <Link key={campaign} href={campaign}>
              Account {getURLParam(campaign, 'act')}
            </Link>
          </ListItem>
        ))}
      </List>
    </RoundedDialog>
  );
}

export default LaunchedCampaigns;
