import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import dayjs, { Dayjs } from 'dayjs';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TextField from '@mui/material/TextField';

import { Caption } from 'pages/PostScheduler/Facebook/models/Caption';
import { categories } from 'pages/PostScheduler/Facebook/fields/categories';
import { formatDateTimeLocal, formatDateTimeUTC } from 'utils/formatDate';
import { key } from 'pages/Authorization';
import { pages } from 'pages/PostScheduler/Facebook/fields/pages';
import { sblyApiService } from 'pages/PostScheduler/service';
import ControlledDateTimePicker from 'shared/components/ControlledDateTimePicker';

export interface ScheduledPost {
  id: string;
  queued_page: string;
  pageName: string;
  link: string;
  message: string;
  utmContent: string | null;
  publishTime: string | undefined;
  localTime: string | undefined;
  utmSource?: string;
}

interface QueuedPostsProps {
  onAddCaptions: (posts: Caption[]) => void;
}

export default function ScheduleNewPost({ onAddCaptions }: QueuedPostsProps) {
  const accessToken = localStorage.getItem(key)?.replace(/['"]+/g, '');

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [scheduledPosts, setScheduledPosts] = useState<ScheduledPost[]>([]);
  const [page, setPage] = useState(pages[0]);
  const [pageInput, setPageInput] = useState('');
  const [link, setLink] = useState('');
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const [message3, setMessage3] = useState('');
  const [category, setCategory] = useState<string>(categories[0]);
  const [categoryInput, setCategoryInput] = useState('');
  const [publishDateTime, setPublishDateTime] = useState<Dayjs>(
    dayjs().add(1, 'day'),
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isValid = Boolean(
      link &&
        message &&
        message2 &&
        message3 &&
        category !== categories[0] &&
        page.id !== pages[0].id,
    );
    setIsFormValid(isValid);
  }, [link, message, message2, message3, category, page]);

  function handleAddCaptions() {
    const url = new URL(link);
    const captionParams = {
      url: link,
      domain: url.host,
      slug: url.pathname.replace(/^\/|\/$/g, ''),
    };
    onAddCaptions([
      {
        caption: message,
        ...captionParams,
      },
      {
        caption: message2,
        ...captionParams,
      },
      {
        caption: message3,
        ...captionParams,
      },
    ]);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    handleAddCaptions();

    let post = {
      id: uuid(),
      queued_page: page.id,
      pageName: page.name,
      utmContent: category,
      publishTime: formatDateTimeUTC(publishDateTime),
      localTime: formatDateTimeLocal(publishDateTime),
      link,
      message,
    };

    let data = {
      posts: [post],
    };
    const response = await sblyApiService.post(
      'facebook-posts/schedule-posts',
      data,
      { headers },
    );

    if (response.status === 200) {
      setScheduledPosts([...scheduledPosts, post]);
      setPage(pages[0]);
      setLink('');
      setMessage('');
      setMessage2('');
      setMessage3('');
      setCategory(categories[0]);
    }

    setLoading(false);
  }

  return (
    <Box sx={{ width: '50%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="link"
              name="link"
              value={link}
              type="url"
              label="post link"
              variant="outlined"
              onChange={e => setLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message"
              name="message"
              value={message}
              type="text"
              label="top comment 1"
              variant="outlined"
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message"
              name="message"
              value={message2}
              type="text"
              label="top comment 2"
              variant="outlined"
              onChange={e => setMessage2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message"
              name="message"
              value={message3}
              type="text"
              label="top comment 3"
              variant="outlined"
              onChange={e => setMessage3(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="category"
              value={category}
              onChange={(_event: any, newValue: string | null) => {
                if (newValue) setCategory(newValue);
              }}
              inputValue={categoryInput}
              onInputChange={(_event, newInputValue) => {
                setCategoryInput(newInputValue);
              }}
              options={categories}
              renderInput={params => (
                <TextField {...params} label="category" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="page"
              value={page}
              onChange={(_event, value) => setPage(value!)}
              inputValue={pageInput}
              onInputChange={(event, newInputValue) => {
                setPageInput(newInputValue);
              }}
              options={pages}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField {...params} label="page" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledDateTimePicker
              label="publish time"
              value={publishDateTime}
              onValueChange={newValue => setPublishDateTime(newValue)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={4}>
            <LoadingButton
              fullWidth
              disabled={!isFormValid}
              loading={loading}
              variant="contained"
              type="submit"
              endIcon={<PostAddIcon />}
              size="large"
            >
              Schedule
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
