import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import Box from '@mui/material/Box';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Typography } from '@material-ui/core';

import DragDropSection from 'pages/Dashboard/components/helpers/DragDropSection';
import IconButtonPopover from 'pages/Dashboard/components/helpers/IconButtonPopover';

interface OrderTableColumnsProps {
  columnVisibilityModel: GridColumnVisibilityModel;
  onColumnOrderChange: (newVisibilityModel: GridColumnVisibilityModel) => void;
}

const reorder = (
  list: [string, boolean][],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function OrderTableColumns({
  columnVisibilityModel,
  onColumnOrderChange,
}: OrderTableColumnsProps) {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Object.entries(columnVisibilityModel),
      result.source.index,
      result.destination.index,
    );

    onColumnOrderChange(Object.fromEntries(items));
  };

  return (
    <IconButtonPopover icon={<ReorderIcon />}>
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="overline">Order Columns</Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          <DragDropSection columns={Object.entries(columnVisibilityModel)} />
        </DragDropContext>
      </Box>
    </IconButtonPopover>
  );
}

export default OrderTableColumns;
