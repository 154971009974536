import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';

interface ButtonPopoverProps extends ButtonProps {
  label: string;
  children: ReactNode;
}

function ButtonPopover({ label, children, sx }: ButtonPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <Button onClick={handlePopoverOpen} sx={sx}>
        {label}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={openPopover}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { borderRadius: '0.75rem' } }}
      >
        {children}
      </Popover>
    </Box>
  );
}

export default ButtonPopover;
