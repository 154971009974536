import React from 'react';
import remove from 'lodash/remove';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { FilterColumn } from 'pages/Dashboard/models/Settings';
import convertPredicate from 'pages/Dashboard/utils/convertPredicate';

interface CurrentFiltersProps {
  currentMetricsFilters: FilterColumn[];
  currentDimensionsFilters: FilterColumn[];
  onMetricsFiltersChange: (newMetrics: FilterColumn[]) => void;
  onDimensionsFiltersChange: (newDimensions: FilterColumn[]) => void;
}

function CurrentFilters({
  currentMetricsFilters,
  currentDimensionsFilters,
  onMetricsFiltersChange,
  onDimensionsFiltersChange,
}: CurrentFiltersProps) {
  const allFilters = [...currentMetricsFilters, ...currentDimensionsFilters]
    .filter(currFilter => currFilter.filters && currFilter.filters.length > 0)
    .map(currFilter => {
      return currFilter.filters!;
    })
    .flat();

  const handleDelete = (
    filterKey: string,
    predicate: string,
    value: string | number,
  ) => {
    const updatedMetricsFilters = currentMetricsFilters.map(metric => {
      if (metric.key === filterKey) {
        remove(
          metric.filters!,
          filter => filter.predicate === predicate && filter.value === value,
        );
      }
      return metric;
    });
    const updatedDimensionsFilters = currentDimensionsFilters.map(dimension => {
      if (dimension.key === filterKey) {
        remove(
          dimension.filters!,
          filter => filter.predicate === predicate && filter.value === value,
        );
      }
      return dimension;
    });
    onMetricsFiltersChange(updatedMetricsFilters);
    onDimensionsFiltersChange(updatedDimensionsFilters);
  };

  return (
    <Box sx={{ pb: 1 }}>
      <Stack direction="row" spacing={1}>
        {allFilters
          .filter(currFilter => currFilter !== undefined)
          .map(currFilter => {
            const filter = `${currFilter.name}  ${convertPredicate(
              currFilter.predicate,
            )} ${currFilter.value}`;
            return (
              <Chip
                variant="outlined"
                size="small"
                label={filter}
                key={`${currFilter.key}-${currFilter.value}`}
                onDelete={() =>
                  handleDelete(
                    currFilter.key,
                    currFilter.predicate,
                    currFilter.value,
                  )
                }
              />
            );
          })}
      </Stack>
    </Box>
  );
}

export default CurrentFilters;
