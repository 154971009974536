import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: `${theme.palette.primary.main}10`,
  },
  '& .campaign-monitor-aggregate': {
    backgroundColor: theme.palette.secondary.light,
    height: 40,
  },
  '& .campaign-monitor-low-metric': {
    backgroundColor: theme.palette.error.light,
  },
  '& .campaign-monitor-high-metric': {
    backgroundColor: theme.palette.success.light,
  },
}));

export default StyledDataGrid;
