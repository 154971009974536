import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface HourPickerProps {
  id: string;
  label: string;
  hour: string;
  onHourUpdate: (hour: string) => void;
}

const hours = [
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
];

function HourPicker({ hour, onHourUpdate }: HourPickerProps) {
  return (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel id="startHour">start hour</InputLabel>
      <Select
        id="startHour"
        value={hour}
        label="start hour"
        onChange={(event: SelectChangeEvent) => {
          onHourUpdate(event.target.value);
        }}
      >
        {hours.map(hour => {
          return (
            <MenuItem key={hour} value={hour}>
              {hour}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default HourPicker;
