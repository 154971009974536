import React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { formatDateTime } from 'utils/formatDate';
import AdStatus from 'pages/Creator/models/AdStatus';
import RefreshButton from 'shared/components/RefreshButton';
import StripedDataGrid from 'shared/components/StripedDataGrid';

interface AdStatusesProps {
  statuses: AdStatus[];
  onRefresh: () => void;
  refreshDisabled: boolean;
}

function AdStatuses({ statuses, onRefresh, refreshDisabled }: AdStatusesProps) {
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            href={params.row.adsManagerLink}
            color={statusColor[params.row.status as keyof typeof statusColor]}
          >
            <Typography variant="button">
              {params.row.status.replace(/_/g, ' ')}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Ad',
      width: 950,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.campaignName} - ${params.row.adName}`,
    },
    {
      field: 'createdDate',
      headerName: 'Created Time',
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        formatDateTime(params.row.createdDate),
    },
    { field: 'accountID', headerName: 'Account', width: 200 },
  ];

  const statusColor = {
    DISAPPROVED: theme.palette.error.main,
    WITH_ISSUES: theme.palette.error.main,

    IN_PROCESS: theme.palette.warning.dark,
    PENDING_BILLING_INFO: theme.palette.warning.dark,
    PENDING_REVIEW: theme.palette.warning.dark,

    ACTIVE: theme.palette.success.main,
    PREAPPROVED: theme.palette.success.main,

    ADSET_PAUSED: theme.palette.info.main,
    CAMPAIGN_PAUSED: theme.palette.info.main,
    PAUSED: theme.palette.info.main,
    ARCHIVED: theme.palette.info.main,
  };

  const statusOrder = Object.keys(statusColor);
  const sortedStatuses = [...statuses];
  sortedStatuses.sort((a, b) => {
    const indexA = statusOrder.indexOf(a.status);
    const indexB = statusOrder.indexOf(b.status);
    return indexA - indexB;
  });

  return (
    <Box>
      <RefreshButton onRefresh={onRefresh} disabled={refreshDisabled} />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: '75vh',
          overflow: 'auto',
        }}
      >
        <StripedDataGrid
          autoHeight
          disableSelectionOnClick
          columns={columns}
          rows={sortedStatuses}
          getRowId={row => row.adID}
          getRowHeight={() => 'auto'}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          sx={{
            borderRadius: 3,
            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '3px',
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default AdStatuses;
