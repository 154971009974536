import React from 'react';
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface ControlledDateTimePickerProps extends DateTimePickerProps<Dayjs> {
  label: string;
  value?: Dayjs;
  onValueChange: (newValue: Dayjs) => void;
}

function ControlledDateTimePicker({
  label,
  value,
  onValueChange,
  sx,
  ...rest
}: ControlledDateTimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={newValue => {
          if (newValue) {
            onValueChange(newValue);
          }
        }}
        sx={sx}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export default ControlledDateTimePicker;
