import React from 'react';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface ControlledDatePickerProps {
  label: string;
  date: Dayjs;
  onDateChange: (newDate: Dayjs) => void;
}

function ControlledDatePicker({
  label,
  date,
  onDateChange,
}: ControlledDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={date}
        onChange={newDate => {
          if (newDate) onDateChange(newDate);
        }}
      />
    </LocalizationProvider>
  );
}

export default ControlledDatePicker;
