import { ConvertedThresholds, Threshold } from '../models/Thresholds';

export function convertThresholds(thresholds: Threshold[]) {
  const converted: ConvertedThresholds = {};

  thresholds.forEach(threshold => {
    if (!converted[threshold.metric]) {
      converted[threshold.metric] = {
        high: Number.MAX_SAFE_INTEGER,
        low: Number.MIN_SAFE_INTEGER,
      };
    }
    const type = threshold.predicate === '>' ? 'high' : 'low';
    converted[threshold.metric]![type] = threshold.value;
  });

  return converted;
}
