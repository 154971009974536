import React, { Fragment, useEffect, useState } from 'react';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PreviewIcon from '@mui/icons-material/Preview';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { formatMetric, getMetricName } from 'pages/Dashboard/utils/format';
import { CurrencyType } from 'utils/valueTypes';
import StatCard from 'pages/Dashboard/components/summary/StatCard';
import SummaryData from 'pages/Dashboard/models/SummaryData';

const SELECTED_SUMMARY_METRICS_KEY = 'selectedSummaryMetrics';

interface SummaryMetricsProps {
  metrics: SummaryData | undefined;
}

const defaultSumMetrics = ['spend', 'profit', 'profit_margin', 'page_views'];

function SummaryMetrics({ metrics }: SummaryMetricsProps) {
  const storedSumMetrics = localStorage.getItem(SELECTED_SUMMARY_METRICS_KEY);
  const [cardGridWidth, setCardGridWidth] = useState(2);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sumMetrics, setSumMetrics] = useState<(keyof SummaryData)[]>(
    storedSumMetrics ? JSON.parse(storedSumMetrics) : defaultSumMetrics,
  );
  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    const gridWidth = 12 / sumMetrics.length;
    setCardGridWidth(gridWidth > 2 ? gridWidth : 2);

    localStorage.setItem(
      SELECTED_SUMMARY_METRICS_KEY,
      JSON.stringify(sumMetrics),
    );
  }, [metrics, sumMetrics]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    metric: keyof SummaryData,
  ) => {
    if (event.target.checked) {
      setSumMetrics([...sumMetrics, metric]);
    } else {
      setSumMetrics(sumMetrics.filter(sumMetric => sumMetric !== metric));
    }
  };

  return (
    <Fragment>
      <Box mb={2}>
        <Box
          sx={{
            width: '100%',
            pb: 1,
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="text"
            size="small"
            color="secondary"
            endIcon={<EditIcon />}
            onClick={handlePopoverOpen}
          >
            <Typography fontSize={13}>edit cards</Typography>
          </Button>
          <Popover
            anchorEl={anchorEl}
            open={openPopover}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{ sx: { borderRadius: '0.75rem' } }}
          >
            <FormGroup sx={{ p: 1, pl: 2 }}>
              {Object.keys(metrics || {}).map(metric => {
                return (
                  <FormControlLabel
                    key={metric}
                    label={getMetricName(metric as keyof SummaryData)}
                    control={
                      <Checkbox
                        size="small"
                        checked={sumMetrics.includes(
                          metric as keyof SummaryData,
                        )}
                        onChange={e =>
                          handleCheckboxToggle(e, metric as keyof SummaryData)
                        }
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </Popover>
        </Box>
        <Grid container spacing={2}>
          {sumMetrics
            .filter(metric => metric in (metrics || {}))
            .map(metric => {
              const [label, value, valueType] = formatMetric(
                metric,
                metrics ? metrics[metric] : undefined,
              );
              return (
                <Grid key={label} item xs={12} lg={cardGridWidth}>
                  <Box mb={2}>
                    <StatCard
                      icon={
                        valueType === CurrencyType ? (
                          <MonetizationOnIcon />
                        ) : (
                          <PreviewIcon />
                        )
                      }
                      label={label as string}
                      stat={value as string}
                    />
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Fragment>
  );
}

export default SummaryMetrics;
