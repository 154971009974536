// data sources
export const FACEBOOK_DATA_SOURCE = 'facebook';
export const OUTBRAIN_DATA_SOURCE = 'outbrain';
export const QUORA_DATA_SOURCE = 'quora';
export const TABOOLA_DATA_SOURCE = 'taboola';
export const TABOOLA_REAL_TIME_DATA_SOURCE = 'taboolaRT';
export const TWITTER_DATA_SOURCE = 'twitter';

// number operations
export const GreaterThanKey = 'GREATER_THAN';
export const LessThanKey = 'LESS_THAN';
export const EqualsNumberKey = 'EQUALS_NUMBER';

// date operations
export const IsOnOrBeforeDateKey = 'IS_ON_OR_BEFORE';
export const IsOnOrAfterDateKey = 'IS_ON_OR_AFTER';
export const IsOnDateKey = 'IS_ON';
export const DatePST = 'DATE_PST';
export const DateEST = 'DATE_EST';

// text operations
export const ContainsKey = 'CONTAINS';
export const EqualsStringKey = 'EQUALS_STRING';
export const DoesNotContainKey = 'DOES_NOT_CONTAIN';

// aggregation operations
export const SumAggregationKey = 'SUM';
export const ArrayAggregationKey = 'ARRAY_AGG';

export const NumberType = 'NUMBER';
export const TextType = 'TEXT';
export const DateType = 'DATE';
export const AnyType = 'ANY';

export const DescendingOrder = 'desc';
export const AscendingOrder = 'asc';

export const defaultRowsPerPage = 5000;

export const ACCOUNT_KEY = 'account';
export const CAMPAIGN_KEY = 'campaign';

export const METRICS = {
  ADSET_BUDGET: 'adset_budget',
  BUDGET_UTILIZATION: 'budget_utilization',
  AMAZON_REVENUE: 'amazon_revenue',
  CLICKS: 'clicks',
  COST_PER_CLICK: 'cost_per_click',
  CONVERSION_CLICKS: 'conversion_clicks',
  CONVERSIONS_VALUE: 'conversions_value',
  CONVERSION_VIEWS: 'conversion_views',
  COST_PER_IMPRESSION: 'cost_per_impression',
  CPA_ACTIONS_NUM: 'cpa_actions_num',
  EFFECTIVE_RPM: 'effective_rpm',
  IMPRESSIONS: 'impressions',
  IMPS_PER_PAGE_VIEW: 'imps_per_page_view',
  LINK_CTR: 'link_ctr',
  OUTBRAIN_REVENUE: 'outbrain_revenue',
  PAGE_RPM: 'page_rpm',
  PAGE_VIEWS: 'page_views',
  PRIMIS_REVENUE: 'primis_revenue',
  PROFIT: 'profit',
  PROFIT_MARGIN: 'profit_margin',
  PURCHASE_VALUE: 'purchase_value',
  REVENUE: 'revenue',
  REVENUE_IMPRESSIONS: 'revenue_impressions',
  SPEND: 'spend',
  SPENT: 'spent',
  VISIBLE_IMPRESSIONS: 'visible_impressions',
};

export const DIMENSIONS = {
  ACCOUNT_ID: 'account_id',
  ACCOUNT_NAME: 'account_name',
  AD_ID: 'ad_id',
  AD_TYPE: 'ad_type',
  ADSET_ID: 'adset_id',
  ADSET_NAME: 'adset_name',
  CAMPAIGN_ID: 'campaign_id',
  CAMPAIGN_NAME: 'campaign_name',
  CREATED_TIME: 'created_time',
  CREATED_DATE_PST: 'created_date_pst',
  DATE_PST: 'date_pst',
  DOMAIN: 'domain',
  SLUG: 'slug',
  UTM_CAMPAIGN: 'utm_campaign',
};

export const requiredDimensions = [
  DIMENSIONS.ACCOUNT_ID.toUpperCase(),
  DIMENSIONS.CAMPAIGN_ID.toUpperCase(),
];
