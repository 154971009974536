import React, { useState } from 'react';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

import { Article } from 'pages/articles/ImageManagement/models/Article';
import {
  bufferToImageFile,
  compressImage,
  imageToHash,
} from 'utils/formatImages';
import { FetchedImage } from '../models/Image';
import ArticleService from 'services/ArticlesService';
import RoundedDialog from 'shared/components/RoundedDialog';
import SelectImages from 'shared/components/SelectImages';
import UpdateButton from 'shared/components/UpdateButton';
import LoadingLinearProgress from 'pages/Dashboard/components/helpers/LoadingLinearProgress';

const articleService = new ArticleService();

interface EditArticleProps {
  showButton: boolean;
  selectedArticles: Article[];
  setError: (error: string) => void;
}

function EditArticle({
  showButton,
  selectedArticles,
  setError,
}: EditArticleProps) {
  const [open, setOpen] = useState(false);
  const [ogImages, setOgImages] = useState<FetchedImage[][]>([]);
  const [newImages, setNewImages] = useState<File[][]>([]);
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(false);

  const handleFetchImages = async () => {
    setImagesLoading(true);
    const images = await articleService.getImages<FetchedImage[]>({
      id: selectedArticles[0].wp_post_id,
      domain: selectedArticles[0].domain,
    });
    if (images) {
      setOgImages([images]);
      setNewImages([
        images.map(image => bufferToImageFile(image.image.data, image.name)),
      ]);
    }
    setImagesLoading(false);
  };

  const handleUpdateImages = async () => {
    setLoading(true);
    const ogImagesFileNames = ogImages[0].map(image => image.name);
    const newImagesFileNames = newImages[0].map(file => file.name);

    const imagesToDelete = ogImages[0]
      .filter(image => !newImagesFileNames.includes(image.name))
      .map(image => {
        return { bucket: image.bucket, key: image.key };
      });

    const imagesToAdd = newImages[0].filter(
      file => !ogImagesFileNames.includes(file.name),
    );
    const compressedImagesToAdd = await Promise.all(
      imagesToAdd.map(async image => await compressImage(image)),
    );
    const hashedImagesToAdd = await imageToHash(compressedImagesToAdd);

    if (imagesToDelete.length > 0) {
      const deleteResp = await articleService.deleteImages(
        selectedArticles[0].wp_post_id,
        imagesToDelete,
      );
      if (!deleteResp) setError('Error deleting images');
    }

    if (imagesToAdd.length > 0) {
      const addResp = await articleService.addImages(
        selectedArticles[0].wp_post_id,
        selectedArticles[0].domain,
        hashedImagesToAdd.map(image => {
          return {
            imageName: image.name,
            tags: [],
            image: image.hash,
          };
        }),
      );
      if (!addResp) setError('Error adding images');
    }

    setOgImages([]);
    setNewImages([]);
    setOpen(false);
    setLoading(false);
  };

  if (!showButton) return null;
  return (
    <div>
      <Fab
        color="secondary"
        onClick={() => {
          setOpen(true);
          handleFetchImages();
        }}
      >
        <EditIcon />
      </Fab>
      <RoundedDialog
        fullWidth
        maxWidth={'xl'}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ p: 3, pt: 2 }}>
          {selectedArticles.map((article, idx) => (
            <Box key={idx}>
              <Typography color="info" variant="h5">
                {article.slug}
              </Typography>
              {imagesLoading ? (
                <LoadingLinearProgress />
              ) : (
                <SelectImages
                  key={'image-management'}
                  images={newImages[idx] || []}
                  onChange={images => {
                    const updatedImages = [...newImages];
                    updatedImages[idx] = images;
                    setNewImages(updatedImages);
                  }}
                />
              )}
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'right', mt: 3 }}>
            <UpdateButton
              size="large"
              variant="contained"
              loading={loading}
              onClick={handleUpdateImages}
            >
              Update
            </UpdateButton>
          </Box>
        </Box>
      </RoundedDialog>
    </div>
  );
}

export default EditArticle;
