import { FACEBOOK_DATA_SOURCE } from 'pages/Dashboard/utils/constants';
import {
  CreateCampaignsParams,
  GetArticleCandidatesParams,
} from 'pages/Creator/models/FacebookCamapaignParams';
import { CreateCustomCampaignsParams } from 'pages/Creator/models/FacebookCustomCampaignParams';
import CreatorService from 'services/CreatorService';

export const FACEBOOK_ACCOUNT = FACEBOOK_DATA_SOURCE;
const FACEBOOK_ADS_PATH = 'facebook-ads';

class FacebookCreatorService extends CreatorService {
  async createCampaigns(params: CreateCampaignsParams) {
    try {
      const resp = await this.http.post(
        `${this.creatorPath}/${FACEBOOK_ACCOUNT}`,
        params,
      );
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async createCustomCampaigns(params: CreateCustomCampaignsParams) {
    try {
      const resp = await this.http.post(
        `${FACEBOOK_ADS_PATH}/create-custom-ad-campaigns`,
        params,
      );

      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async getArticleCandidates(params: GetArticleCandidatesParams) {
    try {
      const resp = await this.http.get(
        `${this.creatorPath}/${FACEBOOK_ACCOUNT}/candidates`,
        { params },
      );
      return resp.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getArticleType(domain: string, slug: string) {
    try {
      const resp = await this.http.get(
        `${this.creatorPath}/${FACEBOOK_ACCOUNT}/article-type`,
        { params: { domain, slug } },
      );
      return resp.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getAdAccounts() {
    try {
      const resp = await this.http.get(
        `${FACEBOOK_ADS_PATH}/limited-ad-accounts`,
      );
      return resp.data;
    } catch (err) {
      console.error(err);
    }
  }

  async hideArticleCandidates(params: CreateCampaignsParams) {
    try {
      const resp = await this.http.post(
        `${this.creatorPath}/${FACEBOOK_ACCOUNT}/hide-articles`,
        params,
      );
      return resp.data;
    } catch (err) {
      console.error(err);
    }
  }

  async refreshAdStatuses() {
    const resp = await this.http.get(`${this.creatorPath}/ad-statuses`);
    return resp.data;
  }
}

export default FacebookCreatorService;
