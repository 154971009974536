import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import dayjs, { Dayjs } from 'dayjs';
import remove from 'lodash/remove';

import { DateRange } from '@mui/x-date-pickers-pro';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Stack from '@mui/material/Stack';

import { CampaignPerformance } from 'pages/CampaignMonitor/models/CampaignPerformance';
import { convertThresholds } from 'pages/CampaignMonitor/utils/convertThresholds';
import { localStorageGet, localStorageSet } from 'utils/localStorageUpdate';
import { Threshold } from 'pages/CampaignMonitor/models/Thresholds';
import CampaignMonitoringService from 'services/CampaignMonitoringService';
import ControlledDateRangePicker from 'shared/components/ControlledDateRangePicker';
import DataTable from 'pages/CampaignMonitor/components/DataTable';
import NumberTextField from 'shared/components/NumberTextField';
import RoundedPaper from 'shared/components/RoundedPaper';
import SummaryData from 'pages/Dashboard/models/SummaryData';
import ThresholdGroup from 'pages/CampaignMonitor/components/ThresholdGroup';
import ToggleButtons from 'shared/components/ToggleButtons';
import UpdateButton from 'shared/components/UpdateButton';

const THRESHOLDS_KEY = 'thresholds';

const campaignMonitoringService = new CampaignMonitoringService();
const dimensions = [
  'created_date_pst',
  'article_type',
  'audience',
  'campaign_name',
];
const metrics: (keyof SummaryData)[] = ['spend', 'profit', 'profit_margin'];

const placeholderThreshold = {
  id: 'new',
  metric: metrics[0],
  predicate: '>' as '>' | '<',
};

export default function CampaignMonitor() {
  const [campaignPerformance, setCampaignPerformance] = useState<
    CampaignPerformance[]
  >([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, 'week'),
    dayjs(),
  ]);
  const [performanceWindow, setPerformanceWindow] = useState(7);
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [tableGroupingExpansionDepth, setTableToggleLevel] = useState(1);
  const [newThreshold, setNewThreshold] = useState<Threshold>({
    ...placeholderThreshold,
  });
  const [thresholds, setThresholds] = useState<Threshold[]>(
    localStorageGet(THRESHOLDS_KEY) || [
      {
        id: uuid(),
        metric: 'profit_margin',
        predicate: '>',
        value: 0.25,
      },
      {
        id: uuid(),
        metric: 'profit_margin',
        predicate: '<',
        value: -0.2,
      },
    ],
  );

  const convertedThresholds = convertThresholds(thresholds);
  const performanceDays = Array.apply(null, Array(performanceWindow)).map(
    (_, i) => `day${i + 1}`,
  );

  const handleThresholdChange = (
    id: string,
    key: 'metric' | 'predicate' | 'value',
    value: string | number,
  ) => {
    if (id === 'new') {
      setNewThreshold({
        ...newThreshold,
        [key]: value,
      });
      return;
    }

    const updatedThresholds = thresholds.map(threshold => {
      if (threshold.id === id) {
        return {
          ...threshold,
          [key]: value,
        };
      } else {
        return threshold;
      }
    });
    setThresholds(updatedThresholds);
  };

  const handleThresholdAdd = () => {
    const updatedThresholds = thresholds;
    if (newThreshold.metric && newThreshold.predicate && newThreshold.value) {
      remove(updatedThresholds, threshold => {
        return (
          threshold.metric === newThreshold.metric &&
          threshold.predicate === newThreshold.predicate
        );
      });
      setThresholds([...updatedThresholds, { ...newThreshold, id: uuid() }]);
    }

    setNewThreshold({ ...placeholderThreshold });
  };

  const handleThresholdRemove = (id: string) => {
    const updatedThresholds = [...thresholds];
    remove(updatedThresholds, threshold => threshold.id === id);
    setThresholds(updatedThresholds);
  };

  const getCampaignPerformance = async () => {
    setPerformanceLoading(true);
    try {
      const performance =
        await campaignMonitoringService.getCampaignPerformance({
          dimensions,
          metrics,
          startDate: dateRange[0]!.format('YYYY-MM-DD'),
          endDate: dateRange[1]!.format('YYYY-MM-DD'),
          window: performanceWindow,
        });

      if (performance) setCampaignPerformance(performance);
    } catch (err) {
      console.error(err);
    }
    setPerformanceLoading(false);
  };

  useEffect(() => {
    getCampaignPerformance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorageSet(THRESHOLDS_KEY, thresholds);
  }, [thresholds]);

  return (
    <Fragment>
      <Box sx={{ display: 'flex' }}>
        <Stack sx={{ mr: 2 }}>
          <RoundedPaper sx={{ mb: 2 }}>
            <ControlledDateRangePicker
              dateRange={dateRange}
              onDateRangeChange={setDateRange}
            />
            <Stack direction="row" spacing={5.5} sx={{ mt: 2 }}>
              <NumberTextField
                id="performance-window"
                label="Days"
                value={performanceWindow}
                onValueChange={newWindow => setPerformanceWindow(newWindow)}
              />
              <UpdateButton
                variant="contained"
                disabled={performanceLoading}
                endIcon={<CachedIcon />}
                onClick={getCampaignPerformance}
                sx={{ ml: 2, height: 55, width: 160 }}
              >
                Update
              </UpdateButton>
            </Stack>
          </RoundedPaper>
          <RoundedPaper sx={{ mb: 2 }}>
            <ToggleButtons
              currValue={dimensions[tableGroupingExpansionDepth]}
              toggleValues={dimensions}
              onValueChange={newValue =>
                setTableToggleLevel(dimensions.indexOf(newValue))
              }
            />
          </RoundedPaper>
        </Stack>
        <RoundedPaper sx={{ mb: 2 }}>
          <Box>
            {thresholds.map(threshold => {
              return (
                <Box key={threshold.id} sx={{ display: 'flex', mb: 2 }}>
                  <ThresholdGroup
                    metrics={metrics}
                    metricName={threshold.metric}
                    predicate={threshold.predicate}
                    value={threshold.value}
                    onUpdate={(key, value) =>
                      handleThresholdChange(threshold.id, key, value)
                    }
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleThresholdRemove(threshold.id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <ThresholdGroup
              metrics={metrics}
              metricName={newThreshold.metric}
              predicate={newThreshold.predicate}
              value={newThreshold.value}
              onUpdate={(key, value) =>
                handleThresholdChange('new', key, value)
              }
            />
            <IconButton size="small" onClick={handleThresholdAdd}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </RoundedPaper>
      </Box>
      <RoundedPaper sx={{ height: '70vh', width: '85vw' }}>
        <DataTable
          loading={performanceLoading}
          defaultGroupingExpansionDepth={tableGroupingExpansionDepth}
          metrics={metrics}
          performanceData={campaignPerformance}
          performanceDays={performanceDays}
          thresholds={convertedThresholds}
        />
      </RoundedPaper>
    </Fragment>
  );
}
