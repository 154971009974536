import React from 'react';

import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

interface CustomDatePickerProps {
  date: Dayjs;
  label: string;
  size?: 'small' | 'medium';
  onChange: (date: Dayjs) => void;
}

function CustomDatePicker({
  date,
  label,
  size,
  onChange,
}: CustomDatePickerProps) {
  return (
    <DatePicker
      label={label}
      value={date}
      onChange={(newDate: Dayjs | null) => {
        newDate && onChange(newDate);
      }}
      renderInput={params => <TextField size={size || 'medium'} {...params} />}
    />
  );
}

export default CustomDatePicker;
