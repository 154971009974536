import { Dayjs } from 'dayjs';
import {
  Caption,
  RemoveCaptionsParams,
} from 'pages/PostScheduler/Facebook/models/Caption';
import BaseService from 'services/BaseService';
import { formatDateTimeLocal } from 'utils/formatDate';

class SchedulerService extends BaseService {
  facebookSchedulerPath = 'facebook-posts';
  captionsPath = `${this.facebookSchedulerPath}/captions`;

  async getCaptions(startDate: Dayjs, endDate: Dayjs, articlePart: string) {
    const requestFn = async () => {
      return this.http.get(this.captionsPath, {
        params: {
          articlePart,
          startDate: formatDateTimeLocal(startDate),
          endDate: formatDateTimeLocal(endDate.add(1, 'day')),
        },
      });
    };

    return this.handleHttpRequest<Caption[]>(
      requestFn,
      'Error fetching captions:',
    );
  }

  async addCaptions(posts: Caption[]) {
    const requestFn = async () => {
      return this.http.post(this.captionsPath, {
        posts,
      });
    };

    return this.handleHttpRequest(requestFn, 'Error adding captions:');
  }

  async removeCaptions(posts: RemoveCaptionsParams[]) {
    const requestFn = async () => {
      return this.http.delete(this.captionsPath, {
        data: { posts },
      });
    };
    return this.handleHttpRequest(requestFn, 'Error deleting captions:');
  }
}

export default SchedulerService;
