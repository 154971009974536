import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Button, Box, Typography, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    marginTop: '15px',
    minWidth: '250px',
    alignItems: 'center',
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    columnGap: '25px',
    rowGap: '25px',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  imageWrapper: {
    display: 'inline - block',
  },
  image: {
    maxHeight: '200px',
    maxWidth: '350px',
  },
  imageCaptionContainer: {
    display: 'flex',
  },
  imageCaption: {
    textAlign: 'center',
    fontSize: '12px',
    flexGrow: 1,
    width: 0,
  },
  closeButton: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: '#ffffff',
  },
}));

interface SelectImageProps {
  images: Array<File>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
}

const SelectImage = ({ images, onChange, removeImage }: SelectImageProps) => {
  const classes = useStyles();

  function imageElements() {
    return images.map((image, index) => {
      const imageURL = URL.createObjectURL(image);
      return (
        <Box className={classes.imageWrapper} position="relative" key={index}>
          <IconButton
            className={classes.closeButton}
            onClick={() => removeImage(index)}
          >
            <CloseIcon />
          </IconButton>
          <img alt="campaign-cover" className={classes.image} src={imageURL} />
          <Tooltip title={image.name}>
            <Box className={classes.imageCaptionContainer}>
              <Typography noWrap className={classes.imageCaption}>
                {image.name}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      );
    });
  }

  return (
    <Box>
      <Box className={classes.container}>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            multiple
            onChange={onChange}
          />
          <Button
            className="btn-choose"
            variant="outlined"
            component="span"
            onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                const inputElement = event.target as HTMLInputElement;
                inputElement.click();
              }
            }}
          >
            Choose Images
          </Button>
        </label>
      </Box>
      {images.length !== 0 && (
        <Box className={classes.imagesContainer}>{imageElements()}</Box>
      )}
    </Box>
  );
};

export default React.memo(SelectImage, (prevProps, nextProps) => {
  if (prevProps.images.length === nextProps.images.length) {
    return (
      prevProps.images.map(image => image.name).join('|') ===
      nextProps.images.map(image => image.name).join('|')
    );
  }
  return false;
});
