import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { FilterColumn, Operation } from 'pages/Dashboard/models/Settings';

interface FilterSelectionProps {
  filters: FilterColumn[];
  filterOperations: Operation;
  filterType: string;
  onAddFilter: (
    selectedFilter: FilterColumn,
    selectedPredicate: string,
    filterValue: string | number,
  ) => void;
  label: string;
  presetPredicate?: string;
  presetValue?: string | number;
}

function FilterSelection({
  filters,
  filterOperations,
  filterType,
  onAddFilter,
  label,
  presetPredicate,
  presetValue,
}: FilterSelectionProps) {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [displayedFilter, setDisplayedFilter] = useState('');
  const [selectedPredicate, setSelectedPredicate] = useState(
    presetPredicate || '',
  );
  const [filterValue, setFilterValue] = useState(presetValue || '');

  const handleAddFilter = () => {
    onAddFilter(selectedFilter, selectedPredicate, filterValue);
    if (label !== 'update') {
      setSelectedPredicate('');
      setFilterValue('');
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
      <FormControl component="fieldset">
        <Autocomplete
          disableClearable
          readOnly={filters.length === 1}
          value={selectedFilter}
          onChange={(event: any, newValue: any) => setSelectedFilter(newValue)}
          inputValue={displayedFilter}
          onInputChange={(event, newInputValue) => {
            setDisplayedFilter(newInputValue);
          }}
          id="controllable-filter-selection"
          options={filters}
          getOptionLabel={option => option.name}
          sx={{ width: 240 }}
          renderInput={params => <TextField {...params} label={filterType} />}
        />
      </FormControl>
      <FormControl>
        <InputLabel>predicate</InputLabel>
        <Select
          value={selectedPredicate}
          label="predicate"
          sx={{ width: 240 }}
          onChange={e => setSelectedPredicate(e.target.value)}
        >
          {filterOperations[
            selectedFilter.columnValueType as keyof Operation
          ].map(op => {
            return (
              <MenuItem key={op.key} value={op.key}>
                {op.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl component="fieldset">
        <TextField
          autoComplete="off"
          sx={{ width: 240 }}
          id="outlined-value"
          label="value"
          value={filterValue}
          onChange={e => setFilterValue(e.target.value)}
        />
      </FormControl>
      <Button
        onClick={handleAddFilter}
        disabled={!selectedPredicate || !filterValue}
      >
        {label === 'update' ? '' : 'Add'} {label}
      </Button>
    </Box>
  );
}

export default FilterSelection;
