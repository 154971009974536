import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useLocation, Route, Link, LinkProps, Switch } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { styled } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Drawer from '@mui/material/Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import Toolbar from '@mui/material/Toolbar';
import {
  Home as HomeIcon,
  PlayCircleOutline as VideoIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';

import AppBarNav from 'AppBarNav';
import Authorization from 'pages/Authorization';
import CampaignMonitor from 'pages/CampaignMonitor';
import Creator from 'pages/Creator';
import Dashboard from 'pages/Dashboard';
import FacebookDuplicator from 'pages/FacebookDuplicator';
import FacebookPostScheduler from 'pages/PostScheduler';
import FacebookVideoScheduler from 'pages/VideoScheduler';
import ImageManagement from 'pages/articles/ImageManagement';
import PrivateRoute from 'pages/Authorization/PrivateRoute';
import SplitTest from 'pages/articles/SplitTest';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
if (MUI_LICENSE_KEY) LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const drawerWidth = '12vw';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      height: '100vh',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

const CollapseListItemButton = styled(ListItemButton)<
  ListItemButtonProps & LinkProps
>(({ theme }) => ({
  paddingLeft: theme.spacing(9),
  fontSize: '14px',
}));

export default function App() {
  const classes = useStyles();

  const [expandArticlesMenu, setExpandArticlesMenu] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Authorization>
          <React.Fragment>
            <AppBarNav />
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
            >
              <Toolbar />
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/'}
                    component={Link}
                    to="/"
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>

                <ListItemButton
                  onClick={() => setExpandArticlesMenu(!expandArticlesMenu)}
                >
                  <ListItemIcon>
                    <HistoryEduIcon />
                  </ListItemIcon>
                  <ListItemText primary="Articles" />
                  {expandArticlesMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={expandArticlesMenu} timeout="auto" unmountOnExit>
                  <CollapseListItemButton
                    selected={currentPath === '/articles/image-management'}
                    component={Link}
                    to="/articles/image-management"
                  >
                    Image Management
                  </CollapseListItemButton>
                </Collapse>
                <Collapse in={expandArticlesMenu} timeout="auto" unmountOnExit>
                  <CollapseListItemButton
                    selected={currentPath === '/articles/split-test'}
                    component={Link}
                    to="/articles/split-test"
                  >
                    A/B Tests
                  </CollapseListItemButton>
                </Collapse>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === 'campaign-monitor'}
                    component={Link}
                    to="/campaign-monitor"
                  >
                    <ListItemIcon>
                      <MonitorHeartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Campaign Monitor" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/creator'}
                    component={Link}
                    to="/creator"
                  >
                    <ListItemIcon>
                      <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="Creator" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/dashboard'}
                    component={Link}
                    to="/dashboard"
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/post-scheduler/facebook'}
                    component={Link}
                    to="/post-scheduler/facebook"
                  >
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Post Scheduler" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/video-scheduler/facebook'}
                    component={Link}
                    to="/video-scheduler/facebook"
                  >
                    <ListItemIcon>
                      <VideoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Video Scheduler" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    selected={currentPath === '/facebook/duplicator"'}
                    component={Link}
                    to="/facebook/duplicator"
                  >
                    <ListItemIcon>
                      <CopyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Facebook Ad Duplicator" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
            <main className={classes.content}>
              <Toolbar />
              <Switch>
                <PrivateRoute
                  path="/articles/image-management"
                  allowedTypes={['admin']}
                >
                  <ImageManagement />
                </PrivateRoute>

                <PrivateRoute
                  path="/articles/split-test"
                  allowedTypes={['admin']}
                >
                  <SplitTest />
                </PrivateRoute>

                <PrivateRoute path="/campaign-monitor" allowedTypes={['admin']}>
                  <CampaignMonitor />
                </PrivateRoute>
                <PrivateRoute path="/creator" allowedTypes={['admin']}>
                  <Creator />
                </PrivateRoute>
                <PrivateRoute path="/dashboard" allowedTypes={['admin']}>
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute
                  path="/post-scheduler/facebook"
                  allowedTypes={['admin', 'user']}
                >
                  <FacebookPostScheduler />
                </PrivateRoute>
                <PrivateRoute
                  path="/video-scheduler/facebook"
                  allowedTypes={['admin']}
                >
                  <FacebookVideoScheduler />
                </PrivateRoute>
                <PrivateRoute
                  path="/facebook/duplicator"
                  allowedTypes={['admin']}
                >
                  <FacebookDuplicator />
                </PrivateRoute>
                <Route path="/">Home</Route>
              </Switch>
            </main>
          </React.Fragment>
        </Authorization>
      </MuiPickersUtilsProvider>
    </div>
  );
}
