import SummaryData from 'pages/Dashboard/models/SummaryData';
import metricValueTypes from 'pages/Dashboard/utils/mappers/MetricValueTypes';
import{ GraphKeyType } from 'pages/Dashboard/models/GraphData';

export const getGraphKeyTypes = (keys: string[]) => {
  const MetricToGraphType: { [key: string]: string } = {
    'profit': 'bar',
    'revenue': 'bar',
    'spend': 'bar',
    'profit_margin': 'line'
  };

  const ColorToGraphType: { [key: string]: string } = {
    'profit': 'rgba(116, 185, 255, 0.7)',
    'revenue': 'rgba(0, 184, 148, 0.3)',
    'spend': 'rgba(250, 177, 160, 0.45)',
    'profit_margin': 'rgba(45, 52, 54, 0.85)'
  };

  return keys.map(key => {
    const graphKeyType: GraphKeyType = {
      name: key,
      type: metricValueTypes[key as keyof SummaryData],
      graphType: MetricToGraphType[key] ?? 'line'
    };

    const color = ColorToGraphType[key];
    if (color) {
      if (graphKeyType.graphType === 'line') {
        graphKeyType.borderColor = ColorToGraphType[key];
        graphKeyType.backgroundColor = ColorToGraphType[key];

      } else if (graphKeyType.graphType === 'bar') {
        graphKeyType.backgroundColor = ColorToGraphType[key];

      }
    }

    return graphKeyType;
  })
}