import React, { useState } from 'react';
import { Dayjs } from 'dayjs';

import { DateRange } from '@mui/x-date-pickers-pro';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import {
  Article,
  SearchType,
} from 'pages/articles/ImageManagement/models/Article';
import ControlledDateRangePicker from 'shared/components/ControlledDateRangePicker';
import EditArticle from 'pages/articles/ImageManagement/components/EditArticle';
import RoundedPaper from 'shared/components/RoundedPaper';
import StripedDataGrid from 'shared/components/StripedDataGrid';
import UpdateButton from 'shared/components/UpdateButton';

interface ArticlesTableProps {
  searchType: SearchType;
  startDate: Dayjs;
  endDate: Dayjs;
  articlePart: string;
  loading: boolean;
  columns: GridColDef[];
  rows: Article[];
  onSearchTypeChange: (newType: SearchType) => void;
  onDateRangeChange: (dateRange: DateRange<Dayjs>) => void;
  onArticlePartChange: (articlePart: string) => void;
  handleUpdate: () => void;
  setError: (error: string) => void;
}

function ArticlesTable({
  searchType,
  startDate,
  endDate,
  articlePart,
  loading,
  columns,
  rows,
  onSearchTypeChange,
  onDateRangeChange,
  onArticlePartChange,
  handleUpdate,
  setError,
}: ArticlesTableProps) {
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  return (
    <RoundedPaper sx={{ p: 4, pb: 13, height: '85vh', width: '55vw' }}>
      <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 2 }}>
        <Stack direction="row" spacing={3}>
          <FormControl>
            <InputLabel id="search-by-label">Search By</InputLabel>
            <Select
              label="Search By"
              value={searchType}
              onChange={(event: SelectChangeEvent) =>
                onSearchTypeChange(event.target.value as SearchType)
              }
            >
              <MenuItem value={'name'}>Name</MenuItem>
              <MenuItem value={'date'}>Date</MenuItem>
            </Select>
          </FormControl>
          {searchType === 'date' ? (
            <ControlledDateRangePicker
              dateRange={[startDate, endDate]}
              onDateRangeChange={newRange => {
                onDateRangeChange(newRange);
              }}
            />
          ) : (
            <TextField
              autoComplete="off"
              id="article-part"
              label="Name Includes"
              value={articlePart}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onArticlePartChange(event.target.value);
              }}
              sx={{ width: 350 }}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={3}>
          <EditArticle
            showButton={selectionModel.length === 1}
            selectedArticles={rows.filter(row =>
              selectionModel.includes(`${row.wp_post_id}-${row.link}`),
            )}
            setError={error => setError(error)}
          />
          <UpdateButton
            variant="contained"
            loading={loading}
            onClick={handleUpdate}
          >
            Search
          </UpdateButton>
        </Stack>
      </Stack>

      <StripedDataGrid
        checkboxSelection
        getRowHeight={() => 'auto'}
        getRowId={row => `${row.wp_post_id}-${row.link}`}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        columns={columns}
        rows={rows}
        selectionModel={selectionModel}
        onSelectionModelChange={newSelection => {
          setSelectionModel(newSelection);
        }}
      />
    </RoundedPaper>
  );
}

export default ArticlesTable;
