import axios from 'axios';

import { key, url } from 'pages/Authorization';

class BaseService {
  accessToken = localStorage.getItem(key)?.replace(/['"]+/g, '');
  http = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${this.accessToken}`,
    },
  });

  async _getAccessToken() {
    if (!this.accessToken) {
      await new Promise(r => setTimeout(r, 1000));
      this.accessToken = localStorage.getItem(key)?.replace(/['"]+/g, '');
      this.http = axios.create({
        baseURL: url,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
    }
  }

  async handleHttpRequest<T>(
    requestFn: () => Promise<any>,
    errorMessage: string,
  ): Promise<T | null> {
    try {
      const resp = await requestFn();
      return resp.data as T;
    } catch (err) {
      console.error(errorMessage, err);
      return null;
    }
  }
}

export default BaseService;
