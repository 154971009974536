import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { CampaignFormProps } from 'pages/Creator/components/quora/CampaignForm';
import CustomNumericInput from 'pages/Creator/components/CustomNumericInput';
import DropdownMultiSelectChip from 'pages/Creator/components/DropdownMultiSelectChip';

interface AdsetFormProps extends CampaignFormProps {}

const locations = ['United States', 'Canada', 'Spain', 'Brazil', 'France'];
const devicesAndBrowsers = ['iOS', 'Android', 'Chrome', 'Firefox', 'Safari'];

function AdsetForm({ formData, onFormUpdate }: AdsetFormProps) {
  const selectedLocations = formData?.locations || [];
  const selectedDevicesAndBrowsers = formData?.devicesAndBrowsers || [];

  const handleDropdownMultiSelectUpdate = (
    event: SelectChangeEvent<typeof selectedLocations>,
    field: string,
  ) => {
    const {
      target: { value },
    } = event;
    if (value)
      onFormUpdate(field, typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ mr: 1 }}>
          <DropdownMultiSelectChip
            id="locations-select"
            label="locations"
            value={selectedLocations}
            menuItems={locations}
            handleChange={event =>
              handleDropdownMultiSelectUpdate(event, 'locations')
            }
          />
        </Box>
        <Box sx={{ mr: 1 }}>
          <DropdownMultiSelectChip
            id="devices-browsers-select"
            label="devices and browsers"
            value={selectedDevicesAndBrowsers}
            menuItems={devicesAndBrowsers}
            handleChange={event =>
              handleDropdownMultiSelectUpdate(event, 'devicesAndBrowsers')
            }
          />
        </Box>
      </Box>
      <Stack direction={'row'} spacing={1}>
        <TextField
          id="genders"
          label="genders"
          value={'All genders'}
          InputProps={{ readOnly: true }}
          sx={{ width: 150 }}
        />
        <TextField
          id="placements"
          label="placements"
          value={'All placements'}
          InputProps={{ readOnly: true }}
          sx={{ width: 150 }}
        />
        <TextField
          label="bid amount"
          value={formData?.bidAmount || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onFormUpdate('bidAmount', event.target.value);
          }}
          id="bid-amount"
          InputProps={{
            inputComponent: CustomNumericInput as any,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="ad-delivery">ad delivery</InputLabel>
          <Select
            label="ad delivery"
            id="ad-delivery"
            value={formData?.adDelivery || ''}
            onChange={(event: SelectChangeEvent) =>
              onFormUpdate('adDelivery', event.target.value)
            }
          >
            <MenuItem value={'Cost per click'}>Cost per click</MenuItem>
            <MenuItem value={'Cost per 1000 impressions'}>
              Cost per 1000 impressions
            </MenuItem>
            <MenuItem value={'Optimize for conversions'}>
              Optimize for conversions
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
}

export default AdsetForm;
