import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import find from 'lodash/find';

import { pages } from '../fields/pages';
import { sblyApiService } from '../../service';

interface ScheduledPost {
  creator: string;
  domain: string;
  id: string;
  image: string;
  link: string;
  message: string;
  pageId: string;
  pageName: string | undefined;
  publishAt: string;
  publishTime: string | undefined;
  slug: string;
  targetId: string;
  type: string;
  utmCampaign: string | null;
  utmSource: string | null;
}

interface ScheduledPostsProps {
  headers: { Authorization: string };
}

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  subheader: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  dataGrid: {
    margin: theme.spacing(1),
    display: 'flex',
  },
  action: {
    marginLeft: theme.spacing(2),
  },
}));

const columns: GridColDef[] = [
  { field: 'pageName', headerName: 'Page', width: 250 },
  { field: 'publishAt', headerName: 'Publish At (PST)', width: 170 },
  { field: 'message', headerName: 'Message', width: 600 },
  { field: 'link', headerName: 'Link', width: 1500 },
];

export default function Posts(props: ScheduledPostsProps) {
  const classes = useStyles();

  const [scheduledPosts, setScheduledPost] = useState([]);
  const [postsLoading, setPostsLoading] = useState(false);

  const getScheduledPosts = useCallback(async () => {
    setPostsLoading(true);

    const response = await sblyApiService.get(
      'facebook-posts/scheduled-posts',
      { headers: props.headers },
    );

    response.data.forEach((post: ScheduledPost) => {
      post.pageName = find(pages, { id: post.id.split('_')[0] })?.name;
    });

    setPostsLoading(false);
    return response.data;
  }, [props.headers]);

  useEffect(() => {
    getScheduledPosts().then(posts => setScheduledPost(posts));
  }, [getScheduledPosts]);

  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGrid
        className={classes.dataGrid}
        loading={postsLoading}
        rows={scheduledPosts}
        columns={columns}
        pageSize={50}
      />
    </div>
  );
}
