import React, { useState } from 'react';
import AdAccount from 'pages/Creator/models/AdAccount';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface AdAccountsProps {
  id: string;
  label: string;
  selected: AdAccount;
  adAccountOptions: AdAccount[];
  onAccountUpdate: (value: AdAccount) => void;
}

function AdAccounts({
  id,
  label,
  selected,
  adAccountOptions,
  onAccountUpdate,
}: AdAccountsProps) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      id={id}
      value={selected}
      onChange={(_e: any, newAccount: AdAccount | null) => {
        if (newAccount !== null) onAccountUpdate(newAccount);
      }}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={adAccountOptions}
      getOptionLabel={option =>
        `${option.name} (${option.id.replace('act_', '')})`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => <TextField {...params} label={label} />}
      size="small"
      sx={{ mb: 2 }}
    ></Autocomplete>
  );
}

export default AdAccounts;
