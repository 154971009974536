import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';

const RoundedDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.75rem',
    padding: theme.spacing(1),
  },
}));

export default RoundedDialog;
