import { Dayjs } from 'dayjs';
import {
  FacebookCreationTemplate,
  GroupedTemplates,
} from './FacebookCreationTemplate';
import AdAccount from './AdAccount';
import LinkData from './LinkData';

export interface FacebookLinkCampaign {
  link: string;
  linkData: LinkData;
  campaignNameAppend: string;
  templates: FacebookCreationTemplate[];
  groupedTemplates: GroupedTemplates;
  selectedTemplates: string[];
  selectedGroups: string[][];
  selectedAdAccount: AdAccount;
  selectedLCAdAccount: AdAccount;
  images: File[];
  savedImages: File[];
  batch: boolean;
  startTime: Dayjs;
}

type FacebookLinkCampaignKeys = keyof FacebookLinkCampaign;
export const FB_CAMPAIGN_PARAMS: Record<
  FacebookLinkCampaignKeys,
  FacebookLinkCampaignKeys
> = {
  link: 'link',
  linkData: 'linkData',
  campaignNameAppend: 'campaignNameAppend',
  templates: 'templates',
  groupedTemplates: 'groupedTemplates',
  selectedTemplates: 'selectedTemplates',
  selectedGroups: 'selectedGroups',
  selectedAdAccount: 'selectedAdAccount',
  selectedLCAdAccount: 'selectedLCAdAccount',
  images: 'images',
  savedImages: 'savedImages',
  batch: 'batch',
  startTime: 'startTime',
};
