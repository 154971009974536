import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { pages } from 'pages/PostScheduler/Facebook/fields/pages';

const useStyles = makeStyles(theme => ({
  pageDropdown: {
    width: 250,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function FacebookPages(props: any) {
  const classes = useStyles();

  const [page, setPage] = useState(pages[0]);
  const [pageInput, setPageInput] = useState('');

  function handlePageChange(page: any) {
    setPage(page);
    props.onChange(page?.id);
  }

  return (
    <div>
      <Autocomplete
        className={classes.pageDropdown}
        id="page"
        value={page}
        onChange={(event, value) => handlePageChange(value!)}
        inputValue={pageInput}
        onInputChange={(event, newInputValue) => {
          setPageInput(newInputValue);
        }}
        options={pages}
        getOptionLabel={option => option.name}
        renderInput={params => (
          <TextField {...params} label="page" variant="outlined" />
        )}
      />
    </div>
  );
}
