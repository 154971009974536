import React from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { DashboardService } from 'services';
import { FilterColumn } from 'pages/Dashboard/models/Settings';
import Order from 'pages/Dashboard/models/Order';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';

interface OrderByProps {
  dashboardService: DashboardService;
  selectedMetrics: FilterColumn[];
  orderBy: keyof PerformanceData;
  orderDirection: Order;
  setOrderBy: (orderKey: keyof PerformanceData) => void;
  setOrderDirection: (orderDirection: Order) => void;
}

function OrderBy({
  dashboardService,
  selectedMetrics,
  orderBy,
  orderDirection,
  setOrderBy,
  setOrderDirection,
}: OrderByProps) {
  const handleMetricChange = (event: SelectChangeEvent) => {
    const orderByMetric = event.target.value as keyof PerformanceData;
    dashboardService.offset = 0;
    setOrderBy(orderByMetric);
  };

  const handleDirectionChange = (
    event: React.MouseEvent<HTMLElement>,
    order: Order,
  ) => {
    dashboardService.offset = 0;
    setOrderDirection(order);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ml: 2,
      }}
    >
      <Box sx={{ width: 250 }}>
        <FormControl fullWidth>
          <InputLabel id="order-by-select-label">order by metric</InputLabel>
          <Select
            id="order-by-select"
            label="order by metric"
            value={orderBy.toUpperCase()}
            onChange={handleMetricChange}
          >
            {selectedMetrics.map(metric => {
              return (
                <MenuItem key={metric.key} value={metric.key}>
                  {metric.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: 100 }}>
        <ToggleButtonGroup
          fullWidth
          exclusive
          size="large"
          value={orderDirection}
          onChange={handleDirectionChange}
        >
          <ToggleButton value="asc">
            <ArrowUpwardIcon />
          </ToggleButton>
          <ToggleButton value="desc">
            <ArrowDownwardIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}

export default OrderBy;
