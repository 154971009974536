export const pages: { id: string; name: string }[] = [
  { id: '0', name: '--select page--' },
  { id: '1679331785632177', name: 'Dot Watching' },
  { id: '1556259461269806', name: 'Shareably Now' },
  { id: '332530263878966', name: 'Superstar Magazine' },
  { id: '1047843558609388', name: 'Uplift Post' },
  { id: '387973724578661', name: 'Humor Is Contagious' },
  { id: '1102199946478493', name: 'Spotlight Stories' },
  { id: '332166993641234', name: 'Animal Channel' },
  { id: '1020352408000047', name: 'Jenny Brown' },
  { id: '1317911488235437', name: 'Patricia Lynn' },
  { id: '1295482317129384', name: 'Jake Manning' },
  { id: '1953720338002189', name: 'See It Live' },
  { id: '325171501147447', name: 'Home Hacks' },
  { id: '572203686296623', name: 'Relieved' },
  { id: '117977898642895', name: 'Sweet and Savory' },
  { id: '212396012495302', name: 'Hilarious Stories' },
  { id: '2082883548602292', name: 'SBLY Global' },
  { id: '1718124715105053', name: 'Crazy Cat Lovers' },
  { id: '918870098221100', name: 'Got Faith' },
  { id: '257135847977259', name: 'Open World Go' },
  { id: '879070725466068', name: 'Open World Page' },
  { id: '806049016161329', name: "Parenting Isn't Easy" },
  { id: '321288144912215', name: 'Motivational Quotes Journal' },
  { id: '339530786717', name: 'I Can Do All Things...' },
  { id: '133924409987123', name: 'Woman of God' },
  { id: '128576200914186', name: 'Tiny Home Lifestyle' },
  { id: '1000852496694320', name: "You're Beautiful" },
  { id: '461397890702603', name: 'Hair Perfect' },
];
