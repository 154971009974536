import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import { GraphKeyType } from 'pages/Dashboard/models/GraphData';
import { getGraphKeyTypes } from 'pages/Dashboard/components/dataTable/toolbar/diagnosticButton/defaults';
import LineChart from 'pages/Dashboard/components/graph/LineChart';
import ScalingDiagnostic, {
  ScalingHistory,
  ScalingGraphType,
  ScalingChangeTypeLabel,
} from 'pages/Dashboard/models/ScalingDiagnostics';
import { caseInsensitiveStringIncludes } from 'utils/stringUtils';

interface DiagnosticChartElementProps {
  type: ScalingChangeTypeLabel;
  diagnostics: ScalingDiagnostic[];
  showNonChanges: boolean;
  graphType: ScalingGraphType;
  setScalingHistory: (history: ScalingHistory) => void;
}

interface Axes {
  leftAxis: GraphKeyType[];
  rightAxis: GraphKeyType[];
}

function DiagnosticChartElement({
  type,
  diagnostics,
  showNonChanges,
  graphType,
  setScalingHistory,
}: DiagnosticChartElementProps) {
  const DefaultAxes = {
    rightAxis: getGraphKeyTypes(['profit', 'spend']),
    leftAxis: getGraphKeyTypes(['profit_margin']),
  };

  const [dailyGraph, setDailyGraph] = useState<Axes>(DefaultAxes);
  const [hourlyGraph, setHourlyGraph] = useState<Axes>(DefaultAxes);

  const updateGraph = (
    graphKey: ScalingGraphType,
    newAxes: { leftAxis: GraphKeyType[]; rightAxis: GraphKeyType[] },
  ) => {
    if (graphKey === ScalingGraphType.Hourly) {
      setHourlyGraph(newAxes);
    } else if (graphKey === ScalingGraphType.Daily) {
      setDailyGraph(newAxes);
    }
  };

  const diagnostic =
    diagnostics.find((diagnostic: ScalingDiagnostic) =>
      caseInsensitiveStringIncludes(diagnostic.changeType, type),
    ) ?? diagnostics[0];

  var annotations: any[] = [];

  const hourlyFormat = 'MM/DD/YYYY - hA';
  diagnostic.hourlyMetrics.labels = diagnostic.hourlyMetrics.hour.map(
    element => {
      return moment(element.toString()).format(hourlyFormat);
    },
  );

  diagnostic.dailyMetrics.labels = diagnostic.dailyMetrics.date.map(element => {
    return moment(element.toString()).format('MM/DD/YYYY');
  });

  annotations = diagnostic.history
    .filter(history =>
      showNonChanges ? true : history.newValue !== history.oldValue,
    )
    .map(history => {
      const value = moment(history.createdAt).format(hourlyFormat);
      if (!diagnostic.hourlyMetrics.labels.includes(value)) {
        // eslint-disable-next-line
        return;
      }
      const scaleUpChange = history.newValue > history.oldValue;
      const totalChange = _.round(history.newValue - history.oldValue, 2);
      const changeDirection =
        totalChange >= 0 ? (totalChange === 0 ? '' : '+') : '-';
      const backgroundColor =
        scaleUpChange || history.newValue === history.oldValue
          ? history.newValue === history.oldValue
            ? 'rgba(200, 200, 200'
            : 'rgba(215, 233, 206'
          : 'rgba(236, 217, 221';
      return {
        id: 'hline2',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value,
        drawTime: 'beforeDatasetsDraw',
        borderColor: `${backgroundColor}, 0.5)`, //rgba(203,195,227, 0.4)',
        borderWidth: 15,
        label: {
          display: false,
          backgroundColor: 'rgba(142,136,158, 1)',
          drawTime: 'afterDatasetsDraw',
          content: [
            `${value}`,
            ``,
            `$${history.oldValue} to $${history.newValue}`,
            `${changeDirection}$${Math.abs(
              totalChange,
            )} | ${changeDirection}${_.round(
              (Math.abs(totalChange) / history.oldValue) * 100,
              2,
            )}%`,
          ],
          font: {
            size: 16,
            lineHeight: 1.5,
            weight: 'normal',
          },
        },
        enter({ element }: { element: any }, event: any) {
          element.label.options.display = true;
          return true; // force update
        },
        leave({ element }: { element: any }, event: any) {
          element.label.options.display = false;
          return true;
        },
        click({ element }: { element: any }, event: any) {
          setScalingHistory(history);
        },
      };
    })
    .filter(Boolean);

  const graphStyles = {
    width: '85%',
    maxWidth: '1500px',
    minHeight: '500px',
  };

  return (
    <div style={{ minWidth: '1000px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {graphType === ScalingGraphType.Daily ? (
          <LineChart
            key={ScalingGraphType.Daily}
            graphData={diagnostic.dailyMetrics}
            leftAxisKeys={dailyGraph.leftAxis}
            rightAxisKeys={dailyGraph.rightAxis}
            onAxesUpdate={(newAxes: Axes) =>
              updateGraph(ScalingGraphType.Daily, newAxes)
            }
            deleteGraph={() => {}}
            deleteGraphDisabled={true}
            graphKeyTypes={getGraphKeyTypes(
              Object.keys(diagnostic.dailyMetrics),
            )}
            containerSX={graphStyles}
            chartCustomizations={{
              title: 'Daily Metrics',
            }}
          />
        ) : (
          <LineChart
            key={ScalingGraphType.Hourly}
            graphData={diagnostic.hourlyMetrics}
            leftAxisKeys={hourlyGraph.leftAxis}
            rightAxisKeys={hourlyGraph.rightAxis}
            deleteGraph={() => {}}
            onAxesUpdate={(newAxes: Axes) =>
              updateGraph(ScalingGraphType.Hourly, newAxes)
            }
            deleteGraphDisabled={true}
            graphKeyTypes={getGraphKeyTypes(
              Object.keys(diagnostic.hourlyMetrics),
            )}
            containerSX={graphStyles}
            chartCustomizations={{
              title: 'Hourly Metrics',
              annotations: annotations,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DiagnosticChartElement;
