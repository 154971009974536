export function sessionStorageGet(key: string) {
  const item = sessionStorage.getItem(key);
  if (item !== 'undefined' && item) {
    return JSON.parse(item);
  }
}

export function sessionStorageSet(key: string, item: any) {
  sessionStorage.setItem(key, JSON.stringify(item));
}
