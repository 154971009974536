import GraphData from 'pages/Dashboard/models/GraphData';

export interface ScalingDiagnosticsQueryData {
  adset_id?: string;
  campaign_id?: string;
  dataSource?: string;
}

export default interface ScalingDiagnostic {
  accountId: string;
  adsetId?: string;
  campaignId: string;
  changeType: ScalingChangeType;
  history: ScalingHistory[];
  dailyMetrics: GraphData;
  hourlyMetrics: GraphData;
}

export enum ScalingChangeType {
  BudgetChange = 'LCBudgetChange',
  BidChange = 'BidChange',
}

export enum ScalingChangeTypeLabel {
  BudgetChange = 'Budget',
  BidChange = 'Bid',
}

export enum ScalingGraphType {
  Daily = 'Daily',
  Hourly = 'Hourly',
}

export interface ScalingHistory {
  changeType: ScalingChangeType;
  createdAt: string;
  debug: ScalingDebug;
  message: string;
  newValue: number;
  oldValue: number;
}

export interface KeyValue {
  key: string;
  value: string | boolean | number;
  parentKey?: string;
}

export interface ScalingDebugStep {
  calculation: KeyValue[];
  name: string;
  value: string | boolean | number;
}

interface ScalingDebug {
  message: string;
  metrics: KeyValue[];
  steps: ScalingDebugStep[];
}
