import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import find from 'lodash/find';
import Box from '@mui/material/Box';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import sortedIndexBy from 'lodash/sortedIndexBy';
import moment from 'moment';

import { pages } from '../fields/pages';
import { sblyApiService } from 'pages/PostScheduler/service';
import FacebookPages from 'pages/VideoScheduler/components/FacebookPages';
import TimePicker from 'pages/PostScheduler/Facebook/components/TimePicker';

interface UpdateTimeSlotsProps {
  headers: { Authorization: string };
}

interface TimeSlots {
  id: string;
  name: string;
  time_slots: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    displayedSlotsBox: {
      paddingTop: '15px',
      paddingLeft: '3px',
      fontSize: theme.typography.pxToRem(16),
      width: '85px',
      textAlign: 'end',
    },
    containerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '40px',
    },
    removeIconBox: {
      paddingTop: '12px',
      paddingRight: '14px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export default function UpdateTimeSlots(props: UpdateTimeSlotsProps) {
  const classes = useStyles();

  const [timeSlots, setTimeSlots] = useState<TimeSlots[]>([]);
  const [pageID, setPageID] = useState('');
  const [displayedSlots, setDisplayedSlots] = useState<String[] | undefined>(
    [],
  );

  function handlePageChange(id: string) {
    const displayedSlots = find(timeSlots, { id })?.time_slots;
    setDisplayedSlots(displayedSlots);
    setPageID(id);
  }

  async function handleUpdateTimeSlots(timeSlot: string, updateType: string) {
    const updatedTimeSlots = [...timeSlots];
    let pageToUpdateIdx = updatedTimeSlots.findIndex(
      (page: TimeSlots) => page.id === pageID,
    );

    if (pageToUpdateIdx === -1) {
      pageToUpdateIdx = updatedTimeSlots.length;

      updatedTimeSlots.push({
        id: pageID,
        name: find(pages, { id: pageID })!.name,
        time_slots: [],
      });
    }

    if (updateType === 'add') {
      const slots = updatedTimeSlots[pageToUpdateIdx].time_slots;
      const newSlotIdx = sortedIndexBy(slots, timeSlot, (slot: string) => {
        return moment(slot, 'LT');
      });
      updatedTimeSlots[pageToUpdateIdx].time_slots.splice(
        newSlotIdx,
        0,
        timeSlot,
      );
    } else {
      updatedTimeSlots[pageToUpdateIdx].time_slots.splice(
        updatedTimeSlots[pageToUpdateIdx].time_slots.indexOf(timeSlot),
        1,
      );
    }
    const response = await sblyApiService.post(
      'facebook-posts/time-slots',
      { timeSlots: updatedTimeSlots },
      {
        headers: props.headers,
      },
    );

    if (response.status === 200) {
      setTimeSlots(updatedTimeSlots);
      setDisplayedSlots(updatedTimeSlots[pageToUpdateIdx].time_slots);
    }
  }

  useEffect(() => {
    const getTimeSlots = async () => {
      const response = await sblyApiService.get('facebook-posts/time-slots', {
        headers: props.headers,
      });
      return response.data;
    };

    getTimeSlots().then((timeSlots: TimeSlots[]) => setTimeSlots(timeSlots));
  }, [props.headers, pageID]);

  return (
    <div>
      <FacebookPages
        onChange={(page: string) => {
          handlePageChange(page);
        }}
      ></FacebookPages>
      {pageID ? (
        <TimePicker
          onChange={(newTime: string) => handleUpdateTimeSlots(newTime, 'add')}
        />
      ) : (
        <p>Select page to update time slots.</p>
      )}
      {displayedSlots?.map(timeSlot => (
        <Box component="span" className={classes.containerBox}>
          <Box
            component="span"
            className={classes.displayedSlotsBox}
            key={timeSlot.toString()}
          >
            {timeSlot}
          </Box>
          <Box className={classes.removeIconBox}>
            <IconButton
              onClick={() =>
                handleUpdateTimeSlots(timeSlot.toString(), 'remove')
              }
            >
              <RemoveCircleOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ))}
    </div>
  );
}
