import useFetch from 'use-http';
import React, { useState } from 'react';

import { Box, Typography, TextField } from '@mui/material';
import { FileCopy as CopyIcon } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@material-ui/core';

import { CampaignData } from './types';
import ArticlesService from 'services/ArticlesService';
import CampaignDisplay from './CampaignDisplay';
import SelectImage from './SelectImage';
import TextInput from './TextInput';
import useImage from '../../hooks/useImage';

import { imageToHash } from 'utils/formatImages';
import ButtonPopover from 'shared/components/ButtonPopover';
import ImagesNamesList from 'shared/components/ImagesNamesList';

const articlesService = new ArticlesService();

const TextfieldFontSize = 15;

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  outerContainer: {
    marginLeft: theme.spacing(2),
  },
  launchedCampaignsContainer: {
    marginLeft: theme.spacing(3),
  },
  campaignIdContainer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    width: '35%',
    minWidth: '250px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  bidBudgetContainer: {
    display: 'flex',
    gap: '20px',
  },
  bidBudgetInput: {
    flexGrow: 1,
  },
  campaignTextField: {
    flexGrow: 2,
    marginRight: '35px',
  },
  fetchButton: {
    flexGrow: 1,
  },
}));

interface CampaignSettings {
  headline: string;
  primaryText: string;
  bid: number;
  budget: number;
}

export default function FacebookDuplicator() {
  const [link, setLink] = useState<string>('');
  const [campaignId, setCampaignId] = useState<string | null>('');
  const [campaignData, setCampaignData] = useState<CampaignData | null>(null);
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    headline: '',
    primaryText: '',
    bid: 0.2,
    budget: 10,
  });

  const { selectedImages, onChange, setSelectedImages, removeImage } =
    useImage();
  const [allImages, setAllImages] = useState<File[]>([]);
  const unselectedImages = allImages.filter(
    image => !selectedImages.includes(image),
  );

  const {
    post: campaignInfoPost,
    loading: campaignInfoLoading,
    error: campaignInfoError,
  } = useFetch<CampaignData>('facebook-ads/campaign-info/', {
    method: 'POST',
    data: { campaignId },
  });

  const { post: duplicatePost, loading: duplicateLoading } = useFetch(
    'facebook-ads/duplicate-campaign/',
    { method: 'POST' },
  );

  const classes = useStyles();

  function handleCampaignChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCampaignId(event.target.value);
  }

  function handleCampaignNameChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const cloned = JSON.parse(JSON.stringify(campaignData));
    cloned.campaign.name = event.target.value;
    setCampaignData(cloned);
  }

  function handleCampaignSettingsChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setCampaignSettings(prevSettings => {
      if (['bid', 'budget'].includes(event.target.name)) {
        const validNumber = /^\d*\.?\d*$/.exec(event.target.value);
        if (!validNumber) {
          return prevSettings;
        }
      }

      return {
        ...prevSettings,
        [event.target.name]: processValue(
          event.target.name,
          event.target.value,
        ),
      };
    });
  }

  function processValue(name: string, value: string) {
    if (name === 'bid') {
      return parseFloat(value);
    } else if (name === 'budget') {
      return parseInt(value);
    } else {
      return value;
    }
  }

  async function handleDuplicateClicked() {
    const imagesHash = await imageToHash(selectedImages);
    const body: any = {
      campaign: {
        ...(campaignData?.campaign ?? {}),
        bid: campaignSettings.bid,
        budget: campaignSettings.budget,
      },
      creative: {
        headline: campaignSettings.headline,
        primaryText: campaignSettings.primaryText,
        link,
      },
      images: imagesHash.map(image => image.hash),
    };

    const result = await duplicatePost(body);
    const campaignLink = `https://business.facebook.com/adsmanager/manage/ads/?act=${
      result.accountId
    }&business_id=1664075030488248&selected_campaign_ids=${result.campaignIds.join(
      ',',
    )}`;
    console.log('Successfully created', campaignLink);
    setAllImages([]);
    // window.open(campaignLink, '_blank');
  }

  async function handleFetchClicked() {
    setSelectedImages([]);
    const campaignData = await campaignInfoPost({ campaignId });
    const firstAd = campaignData.adsets[0]?.ads[0];

    if (firstAd) {
      const { headline, primary_text: primaryText } = firstAd;

      const associatedImages = await articlesService.getImages<File[]>({
        link: firstAd.link,
      });
      if (associatedImages) {
        setAllImages([...associatedImages]);
        setSelectedImages(associatedImages.slice(0, 4));
      } else {
        const firstImageURL = firstAd.creative.image_url;
        const response = await fetch(firstImageURL);
        const type =
          response.headers.get('content-type') ??
          firstImageURL.split('.').slice(-1)[0];
        const blob = await response.blob();
        const file = new File([blob], firstImageURL, { type });
        setSelectedImages([file]);
      }

      setCampaignSettings({
        ...campaignSettings,
        headline: headline ?? '',
        primaryText: primaryText ?? '',
      });
      campaignData.campaign.name = formatCampaignName(
        campaignData.campaign.name,
      );
      setCampaignData(campaignData);
      setLink(firstAd.link);
    }
  }

  function formatCampaignName(name: string) {
    return name + ' DUP';
  }

  function hasError() {
    return Boolean(campaignInfoError);
  }

  function helperText() {
    return hasError() ? 'Invalid Campaign ID' : '';
  }

  function campaignDataSection() {
    if (campaignData) {
      return (
        <CampaignDisplay
          campaignData={campaignData}
          headline={campaignSettings.headline}
          primaryText={campaignSettings.primaryText}
          link={link}
        />
      );
    } else {
      return <Box></Box>;
    }
  }

  return (
    <Box>
      <header className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Facebook Duplicator
        </Typography>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!campaignData || selectedImages.length === 0}
          loading={duplicateLoading}
          onClick={handleDuplicateClicked}
          startIcon={<CopyIcon />}
        ></LoadingButton>
      </header>
      <Box className={classes.outerContainer}>
        <Box className={classes.campaignIdContainer}>
          <TextField
            variant="outlined"
            margin="none"
            size="small"
            label="Campaign Id"
            name="campaignId"
            value={campaignId}
            error={hasError()}
            helperText={helperText()}
            className={classes.campaignTextField}
            onChange={handleCampaignChange}
            inputProps={{ style: { fontSize: TextfieldFontSize } }}
            InputLabelProps={{ style: { fontSize: TextfieldFontSize } }}
          />
          <LoadingButton
            loading={campaignInfoLoading}
            className={classes.fetchButton}
            variant="outlined"
            onClick={handleFetchClicked}
          >
            Fetch
          </LoadingButton>
        </Box>
        {campaignData && (
          <Box className={classes.detailsContainer}>
            <Box className={classes.bidBudgetContainer}>
              <Box className={classes.bidBudgetInput}>
                <TextInput
                  name="budget"
                  label="Budget"
                  value={campaignSettings.budget.toString()}
                  onChange={handleCampaignSettingsChange}
                  fontSize={TextfieldFontSize}
                />
              </Box>
              <Box className={classes.bidBudgetInput}>
                <TextInput
                  name="bid"
                  label="Bid"
                  value={campaignSettings.bid.toString()}
                  onChange={handleCampaignSettingsChange}
                  fontSize={TextfieldFontSize}
                />
              </Box>
            </Box>
            <TextInput
              name="campaignName"
              label="Campaign Name"
              value={campaignData?.campaign.name}
              onChange={handleCampaignNameChange}
              fontSize={TextfieldFontSize}
            />
            <TextInput
              name="primaryText"
              label="Primary Text"
              value={campaignSettings.primaryText}
              onChange={handleCampaignSettingsChange}
              fontSize={TextfieldFontSize}
            />
            <TextInput
              name="headline"
              label="Headline"
              value={campaignSettings.headline}
              onChange={handleCampaignSettingsChange}
              fontSize={TextfieldFontSize}
            />
            {unselectedImages.length > 0 && (
              <ButtonPopover label="Additional Images" sx={{ mt: 2 }}>
                <ImagesNamesList
                  images={unselectedImages}
                  onImageClick={image =>
                    setSelectedImages([...selectedImages, image])
                  }
                />
              </ButtonPopover>
            )}
            {campaignData && (
              <SelectImage
                key="duplicator"
                images={selectedImages}
                onChange={onChange}
                removeImage={removeImage}
              />
            )}
          </Box>
        )}
      </Box>
      {campaignDataSection()}
    </Box>
  );
}
