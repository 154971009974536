import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { FilterColumn, Operation } from 'pages/Dashboard/models/Settings';
import FilterSelection from './FilterSelection';

interface AddFiltersProps {
  filterOperations: Operation;
  metrics: FilterColumn[];
  dimensions: FilterColumn[];
  onMetricFiltersChange: (newFilterMetrics: FilterColumn[]) => void;
  onDimensionFiltersChange: (newFilterMetrics: FilterColumn[]) => void;
}

function addFilter(
  selectedFilter: FilterColumn,
  filters: FilterColumn[],
  newFilter: {
    predicate: string;
    value: string | number;
    key: string;
    name: string;
  },
) {
  return filters.map(filter => {
    if (selectedFilter.key === filter.key) {
      'filters' in filter
        ? filter.filters?.push(newFilter)
        : (filter.filters = [newFilter]);
    }
    return filter;
  });
}

function AddFilters({
  filterOperations,
  metrics,
  dimensions,
  onMetricFiltersChange,
  onDimensionFiltersChange,
}: AddFiltersProps) {
  const handleAddFilter = (
    selectedFilter: FilterColumn,
    predicate: string,
    value: string | number,
  ) => {
    const newFilter = {
      predicate,
      value,
      key: selectedFilter.key,
      name: selectedFilter.name,
    };
    const updatedMetricFilters = addFilter(selectedFilter, metrics, newFilter);
    const updatedDimensionFilters = addFilter(
      selectedFilter,
      dimensions,
      newFilter,
    );
    onMetricFiltersChange(updatedMetricFilters);
    onDimensionFiltersChange(updatedDimensionFilters);
  };

  return (
    <Box>
      <Typography>Add Metrics and Dimensions Filters</Typography>
      <FilterSelection
        filters={metrics}
        filterOperations={filterOperations}
        filterType="metrics"
        onAddFilter={(filter, predicate, value) => {
          handleAddFilter(filter, predicate, value);
        }}
        label="filter"
      />
      <FilterSelection
        filters={dimensions.filter(dimension => dimension.key !== 'DATE_PST')}
        filterOperations={filterOperations}
        filterType="dimensions"
        onAddFilter={(filter, predicate, value) => {
          handleAddFilter(filter, predicate, value);
        }}
        label="filter"
      />
    </Box>
  );
}

export default AddFilters;
