export function joinInArray<T>(arr: Array<T>, element: () => T) {
  return [...arr]
    .map((e, i) => (i < arr.length - 1 ? [e, element()] : [e]))
    .reduce((a, b) => a.concat(b));
}

export function removeArrayFromArray(
  mainArray: string[][],
  elementsToRemove: string[][],
): string[][] {
  return mainArray.filter(group => {
    const strGroup = JSON.stringify(group);
    return !elementsToRemove.some(
      changedGroup => JSON.stringify(changedGroup) === strGroup,
    );
  });
}

export function arrayContainsArray(arr: any[][], target: any[]) {
  return arr.some(
    subArray =>
      subArray.length === target.length &&
      subArray.every((value, index) => value === target[index]),
  );
}

export function nestedItemsArrayFromArray(startingArray: any[]) {
  const nestedItems: string[][] = [startingArray];

  function recursivePop(arr: any[]) {
    if (arr.length < 1) {
      return;
    }
    const arrMinusLastItem = arr.slice(0, -1);
    nestedItems.push(arrMinusLastItem);
    recursivePop(arrMinusLastItem);
  }

  recursivePop(startingArray);
  return nestedItems;
}
