import React, { useState } from 'react';
import find from 'lodash/find';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import UndoIcon from '@mui/icons-material/Undo';

import { blockingParams } from 'services/TaboolaDashboardService';
import { CAMPAIGN_KEY } from 'pages/Dashboard/utils/constants';
import { TaboolaDashboardService } from 'services';
import Alert from 'pages/Dashboard/components/helpers/Alert';
import DualButtonGroup from 'pages/Dashboard/components/dataTable/toolbar/DualButtonGroup';
import PerformanceData from 'pages/Dashboard/models/PerformanceData';

interface TaboolaBlockSitesButtonProps {
  metrics: PerformanceData[];
  selected: string[];
}

const taboolaService = new TaboolaDashboardService();

function TaboolaBlockSitesButton({
  metrics,
  selected,
}: TaboolaBlockSitesButtonProps) {
  const [siteBlockAlertOpen, setSiteBlockAlertOpen] = useState(false);
  const [siteBlockMessage, setSiteBlockMessage] = useState('');

  const handleBlocking = async (level: string, operation: string) => {
    const selectedCampaign = find(metrics, metric => metric.id === selected[0]);

    if (
      selectedCampaign &&
      selectedCampaign.account_id &&
      selectedCampaign.site
    ) {
      const blockingParams: blockingParams = {
        operation,
        accountID: selectedCampaign.account_id,
        site: selectedCampaign.site,
      };
      if (level === CAMPAIGN_KEY) {
        blockingParams.campaignID = selectedCampaign?.campaign_id;
      }

      const message = await taboolaService.blockSites(blockingParams);
      setSiteBlockMessage(message);
      setSiteBlockAlertOpen(true);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={siteBlockAlertOpen}
        autoHideDuration={10000}
        onClose={() => setSiteBlockAlertOpen(false)}
      >
        <Alert onClose={() => setSiteBlockAlertOpen(false)} severity="success">
          {siteBlockMessage}
        </Alert>
      </Snackbar>
      <DualButtonGroup
        color="error"
        disabled={selected.length !== 1}
        handleClick={level => handleBlocking(level, 'ADD')}
        ButtonIcon={<BlockIcon />}
      />
      <DualButtonGroup
        disabled={selected.length !== 1}
        color="success"
        handleClick={level => handleBlocking(level, 'REMOVE')}
        ButtonIcon={<UndoIcon />}
      />
    </Box>
  );
}

export default TaboolaBlockSitesButton;
