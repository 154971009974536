import imageCompression from 'browser-image-compression';

export async function compressImage(file: File) {
  if (file.size < 500000) return file;

  const options = {
    maxSizeMB: 0.5,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Image compression error: ', error);
    return file;
  }
}

export async function imageToHash(images: File[]) {
  return Promise.all(
    images.map(async image => {
      const selectedImageUrl = URL.createObjectURL(image);
      const asBase64 = await toDataURL(selectedImageUrl);
      return {
        name: image.name.split('.')[0],
        hash: asBase64.split('base64,')[1],
      };
    }),
  );
}

export function bufferToImageFile(
  imageAsBase64: number[],
  fileName: string,
  fileType: string = 'image/jpeg',
) {
  const uint8Array = new Uint8Array(imageAsBase64);

  const blob = new Blob([uint8Array], { type: fileType });
  const file = new File([blob], fileName, { type: fileType });

  return file;
}

function toDataURL(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result?.toString() ?? '');
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}
