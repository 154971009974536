import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

interface DropdownMultiSelectChipProps {
  id: string;
  label: string;
  value: string[] | undefined;
  menuItems: any[];
  handleChange: (event: any) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  value: string,
  menuItems: readonly string[] | undefined,
  theme: Theme,
) {
  return {
    fontWeight:
      menuItems?.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function DropdownMultiSelectChip({
  id,
  label,
  value,
  menuItems,
  handleChange,
}: DropdownMultiSelectChipProps) {
  const theme = useTheme();

  return (
    <FormControl sx={{ minWidth: 300 }}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        id={id}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput id={`${id}-chip`} label={label} />}
        renderValue={selected => (
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {menuItems.map(item => (
          <MenuItem
            key={item}
            value={item}
            style={getStyles(item, value, theme)}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownMultiSelectChip;
