import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import find from 'lodash/find';

import { GridColDef, GridRowId } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { Caption } from 'pages/PostScheduler/Facebook/models/Caption';
import ControlledDateRangePicker from 'shared/components/ControlledDateRangePicker';
import RemoveButton from 'shared/components/RemoveButton';
import SchedulerService from 'services/SchedulerService';
import StripedDataGrid from 'shared/components/StripedDataGrid';
import UpdateButton from 'shared/components/UpdateButton';

const schedulerService = new SchedulerService();

const columns: GridColDef[] = [
  { field: 'url', headerName: 'Link', width: 700 },
  { field: 'caption', headerName: 'Comment', width: 1500 },
];

function Captions() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [articlePart, setArticlePart] = useState('');
  const [captions, setCaptions] = useState<Caption[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    handleGetCaptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCaptions = async () => {
    setLoading(true);

    const captions = await schedulerService.getCaptions(
      startDate,
      endDate,
      articlePart,
    );
    if (captions) setCaptions(captions);

    setLoading(false);
  };

  const handleRemoveCaptions = async () => {
    setRemoveLoading(true);

    const captionsToRemove = selectionModel
      .map(selection => {
        return find(captions, caption => caption.caption === selection);
      })
      .filter((caption): caption is Caption => caption !== undefined);

    const resp = await schedulerService.removeCaptions(captionsToRemove);
    if (resp !== null) {
      const remainingCaptions = captions.filter(
        caption => !captionsToRemove.includes(caption),
      );
      setCaptions(remainingCaptions);
      setSelectionModel([]);
    }

    setRemoveLoading(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={2}>
        <ControlledDateRangePicker
          dateRange={[startDate, endDate]}
          onDateRangeChange={newRange => {
            if (newRange[0]) setStartDate(newRange[0]);
            if (newRange[1]) setEndDate(newRange[1]);
          }}
        />
        <TextField
          autoComplete="off"
          id="article-part"
          label="Name Includes"
          value={articlePart}
          onChange={event => {
            setArticlePart(event.target.value);
          }}
          sx={{ width: 450 }}
        />
        <UpdateButton
          variant="contained"
          loading={loading}
          endIcon={<SearchIcon />}
          onClick={handleGetCaptions}
        >
          Search
        </UpdateButton>
        <RemoveButton
          disabled={selectionModel.length === 0}
          loading={removeLoading}
          onClick={handleRemoveCaptions}
        />
      </Stack>
      <Box sx={{ height: '490px', my: 2 }}>
        <StripedDataGrid
          checkboxSelection
          loading={loading}
          getRowHeight={() => 'auto'}
          getRowId={row => `${row.caption}`}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          columns={columns}
          rows={captions}
          selectionModel={selectionModel}
          onSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
        />
      </Box>
    </Box>
  );
}

export default Captions;
