import React, { useState } from 'react';
import { Dayjs } from 'dayjs';

import Backdrop from '@mui/material/Backdrop';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Box from '@mui/material/Box';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { QUORA_DATA_SOURCE } from 'pages/Dashboard/utils/constants';
import CreatorService from 'services/CreatorService';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';
import QuoraCampaignForms from 'pages/Creator/components/quora/QuoraCampaignForms';
import QuoraCampaignParams from 'pages/Creator/models/QuoraCampaignParams';
import URLForm from 'pages/Creator/components/quora/QuoraURLForm';

function QuoraCampaigns() {
  const [articleBodyErrorOpen, setArticleBodyErrorOpen] = useState(false);
  const [articleHeadlineErrorOpen, setArticleHeadlineErrorOpen] =
    useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [campaignParams, setCampaignParams] = useState<QuoraCampaignParams>({});
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  const [urlInfoLoading, setURLInfoLoading] = useState(false);

  const creatorService = new CreatorService();

  const handleURLSubmit = async (url: string) => {
    setURLInfoLoading(true);
    try {
      const {
        caption: body,
        title: headline,
        og_image: imageURL,
        slug,
      } = await creatorService.getLinkInfo(url);
      const templates = await creatorService.getTemplates(QUORA_DATA_SOURCE);
      if (templates) {
        setCampaignParams({
          url,
          body,
          headline,
          imageURL,
          slug,
          ...templates[0],
        });
      }
    } catch (err) {
      console.error(err);
      setNetworkErrorMessage('Post not found. Please ensure URL is correct.');
    }
    setURLInfoLoading(false);
  };

  const handleCampaignFormUpdate = (
    field: string,
    value: string | string[] | Dayjs,
  ) => {
    setCampaignParams({ ...campaignParams, ...{ [field]: value } });
  };

  const validParams = () => {
    campaignParams.headline = campaignParams.headline?.trim();
    campaignParams.body = campaignParams.body?.trim();

    const validEndingChars = ['.', '!', '?'];

    const validHeadline = (() => {
      const title = campaignParams.headline;
      if (
        title &&
        title.length <= 65 &&
        validEndingChars.includes(title.slice(-1))
      ) {
        return true;
      } else {
        setArticleHeadlineErrorOpen(true);
        return false;
      }
    })();

    const validBody = (() => {
      const caption = campaignParams.body;
      if (
        caption &&
        caption.length <= 105 &&
        validEndingChars.includes(caption.slice(-1))
      ) {
        return true;
      } else {
        setArticleBodyErrorOpen(true);
        return false;
      }
    })();

    return validHeadline && validBody;
  };

  const handleCampaignSubmit = async () => {
    setCampaignLoading(true);
    if (campaignParams && validParams()) {
      const campaignID = await creatorService.createCampaign(campaignParams);
      if (campaignID) {
        window.open(
          `https://www.quora.com/ads/campaign_performance?id=${campaignID}`,
        );
        setCampaignParams({});
      } else {
        setNetworkErrorMessage(
          'Unable to create campaign. Error message logged in console.',
        );
      }
    }
    setCampaignLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          borderLeft: 1,
          borderRight: 1,
          borderColor: 'grey.300',
          px: 7,
          width: '60%',
        }}
      >
        <ErrorAlert
          open={networkErrorMessage !== ''}
          onClose={() => setNetworkErrorMessage('')}
          message={networkErrorMessage}
        />
        <URLForm
          url={campaignParams.url || ''}
          onURLUpdate={handleCampaignFormUpdate}
          onURLSubmit={handleURLSubmit}
          loading={urlInfoLoading}
        />
        <QuoraCampaignForms
          formData={campaignParams}
          onFormUpdate={handleCampaignFormUpdate}
          headlineErrorOpen={articleHeadlineErrorOpen}
          bodyErrorOpen={articleBodyErrorOpen}
          onHeadlineErrorOpenChange={setArticleHeadlineErrorOpen}
          onBodyErrorOpenChange={setArticleBodyErrorOpen}
        />
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <LoadingButton
            size="large"
            loading={campaignLoading}
            variant="contained"
            loadingPosition="end"
            endIcon={<BuildIcon />}
            onClick={handleCampaignSubmit}
            sx={{ mr: 1, width: 200, whiteSpace: 'nowrap' }}
          >
            create campaign
          </LoadingButton>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            endIcon={<BackspaceIcon />}
            onClick={() => setCampaignParams({})}
            sx={{ width: 200 }}
          >
            clear form
          </Button>
        </Box>
        <Backdrop
          sx={{
            color: '#fff',
            backgroundColor: 'rgb(105,105,105,0.6)',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={campaignLoading}
          onClick={() => setCampaignLoading(false)}
        >
          <Typography variant="h5">
            Creating campaign. This may take a few minutes...
          </Typography>
          <CircularProgress thickness={3} sx={{ ml: 2 }} />
        </Backdrop>
      </Box>
    </Box>
  );
}

export default QuoraCampaigns;
