import React from 'react';
import Box from '@mui/material/Box';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Typography from '@mui/material/Typography';

import { getMetricName } from 'pages/Dashboard/utils/format';

interface DragDropSectionProps {
  columns: [string, boolean][];
}

function DragDropSection({ columns }: DragDropSectionProps) {
  return (
    <Droppable droppableId="tableColumns">
      {provided => (
        <Box {...provided.droppableProps} ref={provided.innerRef}>
          {columns.map((column: any, index: any) => (
            <Draggable key={column[0]} draggableId={column[0]} index={index}>
              {provided => (
                <Box
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 0.2,
                  }}
                >
                  <Box sx={{ pr: 2 }}>
                    <Typography variant="body2">
                      {getMetricName(column[0])}
                    </Typography>
                  </Box>
                  <DragHandleIcon />
                </Box>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
}

export default DragDropSection;
