import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { formatLabel } from 'pages/Dashboard/utils/format';

interface ToggleButtonsProps {
  currValue: string;
  toggleValues: string[];
  onValueChange: (newValue: string) => void;
  size?: 'small' | 'medium' | 'large';
}

function ToggleButtons({
  size = 'medium',
  currValue,
  toggleValues,
  onValueChange,
}: ToggleButtonsProps) {
  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) onValueChange(newValue);
  };

  return (
    <ToggleButtonGroup
      size={size}
      value={currValue}
      exclusive
      onChange={handleValueChange}
    >
      {toggleValues.map(value => {
        return (
          <ToggleButton key={value} value={value}>
            {formatLabel(value)}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default ToggleButtons;
