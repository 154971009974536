export function extractFirstPath(url: string | undefined) {
  if (url && isValidURL(url)) {
    const pathArray = new URL(url).pathname.split('-');
    const cleanPathArray = pathArray.filter(segment => segment.trim() !== '');

    if (cleanPathArray.length > 0) {
      return cleanPathArray[0].replace(/\//g, '');
    } else {
      return '';
    }
  }

  return '';
}

export function isValidURL(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function getDomain(url: string) {
  const urlObj = new URL(url);
  return urlObj.hostname;
}

export function makeValidURL(url: string) {
  const formattedURL = url.replace('www.', '');

  if (!formattedURL.startsWith('https://')) {
    return 'https://' + formattedURL;
  }

  return formattedURL;
}
