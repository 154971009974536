import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  Article,
  SearchType,
} from 'pages/articles/ImageManagement/models/Article';
import ArticleService from 'services/ArticlesService';
import ArticlesTable from 'pages/articles/ImageManagement/components/ArticlesTable';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';

const articleService = new ArticleService();
const currentDate = dayjs();

const columns: GridColDef[] = [
  { field: 'link', headerName: 'Article', width: 1000 },
];

function Articles() {
  const [searchType, setSearchType] = useState<SearchType>('date');
  const [articles, setArticles] = useState<Article[]>([]);
  const [startDate, setStartDate] = useState(currentDate.subtract(7, 'day'));
  const [endDate, setEndDate] = useState(currentDate);
  const [articlePart, setArticlePart] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleFetchArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchArticles = async () => {
    setLoading(true);
    const articles =
      searchType === 'date'
        ? await articleService.getArticlesWithImages(startDate, endDate)
        : await articleService.getArticlesByName(articlePart);

    if (articles) {
      setArticles(articles);
    } else {
      setError('No articles found.');
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Typography variant="h3" color={'secondary.main'} align="center">
        Image Management
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <ErrorAlert
          open={error !== ''}
          onClose={() => setError('')}
          message={error}
        />
        <ArticlesTable
          searchType={searchType}
          startDate={startDate}
          endDate={endDate}
          articlePart={articlePart}
          onSearchTypeChange={newType => setSearchType(newType)}
          onDateRangeChange={newRange => {
            if (newRange[0]) setStartDate(newRange[0]);
            if (newRange[1]) setEndDate(newRange[1]);
          }}
          onArticlePartChange={newPart => setArticlePart(newPart)}
          handleUpdate={handleFetchArticles}
          loading={loading}
          columns={columns}
          rows={articles}
          setError={errorMessage => setError(errorMessage)}
        />
      </Box>
    </Fragment>
  );
}

export default Articles;
