import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@mui/material/Typography';

import { Caption } from './Facebook/models/Caption';
import ErrorAlert from 'pages/Creator/components/ErrorAlert';
import FinishedPosts from './Facebook/components/FinishedPosts';
import PendingPosts from './Facebook/components/PendingPosts';
import QueuedPosts from './Facebook/components/QueuedPosts';
import ReschedulePosts from './Facebook/components/ReschedulePosts';
import ScheduleNewPost from './Facebook/components/ScheduleNewPost';
import ScheduledPosts from './Facebook/components/ScheduledPosts';
import SchedulerService from 'services/SchedulerService';
import TopPosts from './Facebook/components/TopPerformingPosts';
import UpdateTimeSlots from './Facebook/components/UpdateTimeSlots';
import Captions from './Facebook/components/Captions';
import { key } from '../Authorization';

const schedulerService = new SchedulerService();

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3),
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  accordionRoot: {
    width: '100%',
  },
}));

export default function FacebookPostScheduler() {
  const classes = useStyles();

  const [error, setError] = useState('');

  const accessToken = localStorage.getItem(key)?.replace(/['"]+/g, '');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const handleAddCaptions = async (posts: Caption[]) => {
    const resp = await schedulerService.addCaptions(posts);
    if (resp === null) {
      setError('Error adding captions, full error logged in console.');
    }
  };

  return (
    <div>
      <ErrorAlert
        open={error !== ''}
        onClose={() => setError('')}
        message={error}
      />
      <header className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Facebook Post Scheduler
        </Typography>
      </header>
      <div className={classes.accordionRoot}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-pending-content"
            id="panel-pending-header"
          >
            <Typography>Pending</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PendingPosts headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-queued-content"
            id="panel-queued-header"
          >
            <Typography>Queued</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QueuedPosts
              headers={headers}
              onAddCaptions={post => handleAddCaptions([post])}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-reschedule-content"
            id="panel-reschedule-header"
          >
            <Typography>Reschedule</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReschedulePosts headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-scheduled-content"
            id="panel-scheduled-header"
          >
            <Typography>Scheduled</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ScheduledPosts headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-new-content"
            id="panel-new-header"
          >
            <Typography>Schedule New</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ScheduleNewPost
              onAddCaptions={posts => handleAddCaptions(posts)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-completed-content"
            id="panel-completed-header"
          >
            <Typography>Top Performing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TopPosts headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-finished-content"
            id="panel-finished-header"
          >
            <Typography>Finished</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FinishedPosts headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-update-time-slots"
            id="panel-update-time-slots-header"
          >
            <Typography>Update Time Slots</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UpdateTimeSlots headers={headers} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-captions-content"
            id="panel-captions-header"
          >
            <Typography>Manage Captions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Captions />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
