import { v4 as uuid } from 'uuid';
import BaseService from 'services/BaseService';
import { RawPerformanceData } from 'pages/CampaignMonitor/models/RawPerformanceData';
import {
  CampaignPerformance,
  DayPerformance,
} from 'pages/CampaignMonitor/models/CampaignPerformance';
import {
  DIMENSIONS,
  IsOnOrAfterDateKey,
  IsOnOrBeforeDateKey,
} from 'pages/Dashboard/utils/constants';

class CampaignMonitoringService extends BaseService {
  campaignMonitoringPath = 'campaign-monitoring';

  async getCampaignPerformance({
    metrics,
    dimensions,
    startDate,
    endDate,
    window,
  }: {
    metrics: string[];
    dimensions: string[];
    startDate: string;
    endDate: string;
    window: number;
  }) {
    const params = {
      metrics,
      dimensions,
      filters: this._buildDateFilters(startDate, endDate),
      window,
    };
    try {
      const dimensionLevels = dimensions.map((_, index) =>
        dimensions.slice(0, dimensions.length - index),
      );
      const paramsByDimensionLevel = dimensionLevels.map(dimensions => {
        return { ...params, dimensions };
      });

      const performanceAllLevels = await Promise.all(
        paramsByDimensionLevel.map(async params => {
          const resp = await this.http.post(
            this.campaignMonitoringPath,
            params,
          );
          return this._formatData(resp.data, params.dimensions);
        }),
      );

      return performanceAllLevels.flat();
    } catch (err) {
      console.error(err);
    }
  }

  private _buildDateFilters(startDate: string, endDate: string) {
    return [
      {
        filterKey: IsOnOrAfterDateKey,
        columnKey: DIMENSIONS.CREATED_DATE_PST,
        value: startDate,
      },
      {
        filterKey: IsOnOrBeforeDateKey,
        columnKey: DIMENSIONS.CREATED_DATE_PST,
        value: endDate,
      },
    ];
  }

  private _formatData(
    performanceMetrics: RawPerformanceData[],
    dimensions: string[],
  ): CampaignPerformance[] {
    const formatted = performanceMetrics.map(metric => {
      const hierarchy: string[] = [];
      dimensions.forEach(dimension => {
        hierarchy.push(
          metric[dimension as keyof RawPerformanceData]! as string,
        );
      });

      const formattedPerformance: DayPerformance = {};

      metric.performance.forEach(dayPerformance => {
        const perfDay = `day${dayPerformance.days_running}`;
        formattedPerformance[perfDay] = {
          spend: dayPerformance.total_spend,
          profit: dayPerformance.profit,
          profit_margin: dayPerformance.profit_margin,
        };
      });

      return {
        hierarchy,
        id: uuid(),
        performance: formattedPerformance,
      };
    });

    return formatted;
  }
}

export default CampaignMonitoringService;
