import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import CustomNumericInput from 'pages/Creator/components/CustomNumericInput';
import HourPicker from 'pages/Creator/components/HourPicker';
import QuoraCampaignParams from 'pages/Creator/models/QuoraCampaignParams';
import ControlledDatePicker from 'shared/components/ControlledDatePicker';

export interface CampaignFormProps {
  formData: QuoraCampaignParams | undefined;
  onFormUpdate: (field: string, value: string | string[] | Dayjs) => void;
}

function CampaignForm({ formData, onFormUpdate }: CampaignFormProps) {
  const startDate = formData?.startDate || dayjs().add(1, 'day');

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ pt: 2, flexWrap: 'wrap', gap: 1 }}
    >
      <FormControl sx={{ width: 150 }}>
        <InputLabel id="objective">objective</InputLabel>
        <Select
          id="objective"
          label="objective"
          value={formData?.objective ? formData.objective : ''}
          onChange={(event: SelectChangeEvent) => {
            onFormUpdate('objective', event.target.value);
          }}
        >
          <MenuItem value={'Conversions'}>Conversions</MenuItem>
          <MenuItem value={'App installs'}>App installs</MenuItem>
          <MenuItem value={'Traffic'}>Traffic</MenuItem>
          <MenuItem value={'Awareness'}>Awareness</MenuItem>
          <MenuItem value={'Video views'}>Video views</MenuItem>
          <MenuItem value={'Lead generation'}>Lead generation</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 150 }}>
        <InputLabel id="conversion-event">conversion</InputLabel>
        <Select
          id="conversion-event"
          label="conversion"
          value={formData?.conversionEvent || ''}
          onChange={(event: SelectChangeEvent) => {
            onFormUpdate('conversionEvent', event.target.value);
          }}
        >
          <MenuItem value={'Generic'}>Generic</MenuItem>
          <MenuItem value={'Purchase'}>Purchase</MenuItem>
          <MenuItem value={'Add to Cart'}>Add to Cart</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="daily-max-budget"
        label="daily max budget"
        value={formData?.budget || ''}
        helperText="($5 minimum)"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onFormUpdate('budget', event.target.value);
        }}
        InputProps={{
          inputComponent: CustomNumericInput as any,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        sx={{ width: 150 }}
      />
      <Box>
        <ControlledDatePicker
          label="start date"
          date={startDate}
          onDateChange={newDate => {
            if (newDate) onFormUpdate('startDate', newDate);
          }}
        />
      </Box>
      <HourPicker
        id="startHour"
        label="start hour"
        hour={formData?.startHour || ''}
        onHourUpdate={newHour => onFormUpdate('startHour', newHour)}
      />
    </Stack>
  );
}

export default CampaignForm;
