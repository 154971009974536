import React from 'react';
import TextField from '@mui/material/TextField';

interface NumberTextFieldProps {
  id: string;
  label: string;
  value: number;
  onValueChange: (newValue: number) => void;
}

function NumberTextField({
  id,
  label,
  value,
  onValueChange,
}: NumberTextFieldProps) {
  return (
    <TextField
      id={id}
      label={label}
      type="number"
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(+event.target.value);
      }}
      InputProps={{
        inputProps: { min: 1 },
      }}
      sx={{ width: 160 }}
    />
  );
}

export default NumberTextField;
