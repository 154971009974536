import React, { useCallback, useEffect, useState } from 'react';
import find from 'lodash/find';
import round from 'lodash/round';

import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';

import { sblyApiService } from '../../service';
import Loader from './Loader';
import { pages } from '../fields/pages';

interface PendingPostsProps {
  headers: { Authorization: string };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '550px',
      width: '100%',
    },
    rightBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    containerBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dropdown: {
      display: 'flex',
      width: '35ch',
      marginRight: theme.spacing(1),
    },
    button: {
      height: '54px',
      width: '135px',
      marginRight: theme.spacing(1),
    },
    dataGrid: {
      height: '490px',
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  }),
);

export default function CheckboxSelectionGrid(props: PendingPostsProps) {
  const classes = useStyles();

  const columns: GridColDef[] = [
    { field: 'slug', headerName: 'Link', width: 500 },
    { field: 'formattedTime', headerName: 'Last Published', width: 170 },
    { field: 'previousPages', headerName: 'Page History', width: 300 },
    {
      field: 'ctr',
      headerName: 'CTR',
      valueGetter: (params: GridValueGetterParams) =>
        `${round(params.row.latest_page.ctr ?? 0, 2)}%`,
      width: 100,
    },
    {
      field: 'impressions',
      headerName: 'Reach',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.latest_page.impressions,
      width: 100,
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.latest_page.clicks,
      width: 100,
    },
    { field: 'twitter_title', headerName: 'Headline', width: 700 },
    { field: 'title', headerName: 'Title', width: 700 },
  ];

  const [pendingPosts, setPendingPosts] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [pageInput, setPageInput] = useState('');
  const [facebookPage, setFacebookPage] = useState(pages[0]);
  const [showFinishedLoader, setFinishedLoader] = useState(false);
  const [showTopLoader, setTopLoader] = useState(false);
  const [showQueueLoader, setQueueLoader] = useState(false);
  const [showPendingLoader, setPendingLoader] = useState(false);

  const getPendingPosts = useCallback(async () => {
    setPendingLoader(true);
    const response = await sblyApiService.get('facebook-posts/pending-posts', {
      headers: props.headers,
    });
    setPendingLoader(false);
    return response.data;
  }, [props.headers]);

  async function handleAddToQueue(event: React.MouseEvent<HTMLElement>) {
    setQueueLoader(true);
    const postsToQueue = selectionModel.map(postId => {
      return find(pendingPosts, (post: any) => post.id === postId);
    });

    const data = {
      pageId: facebookPage.id,
      postsToQueue: postsToQueue,
    };
    const response = await sblyApiService.post(
      'facebook-posts/add-post-to-queue',
      data,
      {
        headers: props.headers,
      },
    );

    if (response.status === 200) {
      const unscheduledPosts = pendingPosts.filter(
        (post: any) => !postsToQueue.includes(post),
      );

      setPendingPosts(unscheduledPosts);
      setPageInput('');
      setFacebookPage(pages[0]);
      setSelectionModel([]);
      setQueueLoader(false);
    }
  }

  async function handleAddToFinished() {
    setFinishedLoader(true);
    const finishedPosts = selectionModel.map(postId => {
      return find(pendingPosts, (post: any) => post.id === postId);
    });

    const data = {
      finishedPosts,
    };
    const response = await sblyApiService.post(
      'facebook-posts/add-posts-to-finished',
      data,
      {
        headers: props.headers,
      },
    );

    if (response.status === 200) {
      const remainingPosts = pendingPosts.filter(
        (post: any) => !finishedPosts.includes(post),
      );

      setPendingPosts(remainingPosts);
      setSelectionModel([]);
      setFinishedLoader(false);
    }
  }

  async function handleAddToTopPerforming() {
    setTopLoader(true);
    const topPosts = selectionModel.map(postId => {
      return find(pendingPosts, (post: any) => post.id === postId);
    });

    const data = {
      topPosts,
    };
    const response = await sblyApiService.post(
      'facebook-posts/add-posts-to-top-performing',
      data,
      {
        headers: props.headers,
      },
    );

    if (response.status === 200) {
      const remainingPosts = pendingPosts.filter(
        (post: any) => !topPosts.includes(post),
      );

      setPendingPosts(remainingPosts);
      setSelectionModel([]);
      setTopLoader(false);
    }
  }

  useEffect(() => {
    getPendingPosts().then(posts => setPendingPosts(posts));
  }, [getPendingPosts]);

  return (
    <div className={classes.root}>
      <div className={classes.dataGrid}>
        <DataGrid
          checkboxSelection
          loading={showPendingLoader}
          columns={columns}
          rows={pendingPosts}
          pageSize={50}
          onSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
          selectionModel={selectionModel}
        />
      </div>
      <Box component="span" className={classes.containerBox}>
        <Box component="span">
          {showFinishedLoader ? (
            <Button className={classes.button}>
              <Loader />
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleAddToFinished}
              endIcon={<NotInterestedIcon />}
              sx={{ mr: 1 }}
            >
              Finished
            </Button>
          )}
          {showTopLoader ? (
            <Button className={classes.button}>
              <Loader />
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleAddToTopPerforming}
              endIcon={<StarIcon />}
            >
              Top Post
            </Button>
          )}
        </Box>
        <Box component="span" className={classes.rightBox}>
          <Autocomplete
            className={classes.dropdown}
            id="page"
            value={facebookPage}
            onChange={(event, value) => setFacebookPage(value!)}
            inputValue={pageInput}
            onInputChange={(event, newInputValue) => {
              setPageInput(newInputValue);
            }}
            options={pages}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="page" variant="outlined" />
            )}
          />
          {showQueueLoader ? (
            <Button className={classes.button}>
              <Loader />
            </Button>
          ) : (
            <Button
              className={classes.button}
              disabled={facebookPage === pages[0]}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleAddToQueue}
              endIcon={<ArrowDownwardIcon />}
            >
              Queue
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}
