import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AdFormProps } from 'pages/Creator/components/quora/AdForm';
import AdForm from 'pages/Creator/components/quora/AdForm';
import AdsetForm from 'pages/Creator/components/quora/AdsetForm';
import CampaignForm from 'pages/Creator/components/quora/CampaignForm';

interface QuoraCampaignFormsProps extends AdFormProps {}

function QuoraCampaignForms({
  formData,
  onFormUpdate,
  ...props
}: QuoraCampaignFormsProps) {
  return (
    <Box>
      <Typography variant="h6">Campaign</Typography>
      <CampaignForm formData={formData} onFormUpdate={onFormUpdate} />

      <Typography variant="h6">Ad Set</Typography>
      <AdsetForm formData={formData} onFormUpdate={onFormUpdate} />

      <Typography variant="h6">Ad</Typography>
      <AdForm formData={formData} onFormUpdate={onFormUpdate} {...props} />
    </Box>
  );
}

export default QuoraCampaignForms;
