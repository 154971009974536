import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import find from 'lodash/find';

import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { sblyApiService } from '../../service';
import { pages } from '../fields/pages';
import ControlledDateRangePicker from 'shared/components/ControlledDateRangePicker';
import RemoveButton from 'shared/components/RemoveButton';

interface FinishedPostsProps {
  headers: { Authorization: string };
}

const currentDate = dayjs();

export default function FinishedPosts(props: FinishedPostsProps) {
  const columns: GridColDef[] = [
    { field: 'link', headerName: 'Link', width: 550 },
    { field: 'message', headerName: 'Message', width: 400 },
    { field: 'previous_pages', headerName: 'Published Pages', width: 300 },
    { field: 'original_author', headerName: 'Editor', width: 200 },
  ];

  const [startDate, setStartDate] = useState(currentDate.subtract(1, 'year'));
  const [endDate, setEndDate] = useState(currentDate);
  const [finishedPosts, setFinishedPosts] = useState([]);
  const [pageInput, setPageInput] = useState('');
  const [facebookPage, setFacebookPage] = useState(pages[0]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [showFinishedLoader, setFinishedLoader] = useState(false);
  const [showQueueLoader, setQueueLoader] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    async function getFinishedPosts() {
      setFinishedLoader(true);
      const response = await sblyApiService.get(
        'facebook-posts/finished-posts',
        {
          headers: props.headers,
          params: {
            startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      );

      setFinishedLoader(false);
      return response.data;
    }
    getFinishedPosts().then(posts => setFinishedPosts(posts));
  }, [props.headers, endDate, startDate]);

  const selectedPosts = () => {
    return selectionModel.map(postId => {
      return find(finishedPosts, (post: any) => post.id === postId);
    });
  };

  async function handleAddToQueue(event: React.MouseEvent<HTMLElement>) {
    setQueueLoader(true);
    const postsToQueue = selectedPosts();

    const data = {
      pageId: facebookPage.id,
      postsToQueue: postsToQueue,
    };
    const response = await sblyApiService.post(
      'facebook-posts/add-post-to-queue',
      data,
      {
        headers: props.headers,
      },
    );

    if (response.status === 200) {
      await handleRemoveSelectedPosts();
    }
    setQueueLoader(false);
  }

  async function handleRemoveSelectedPosts() {
    setRemoveLoading(true);

    const postsToRemove = selectedPosts();
    try {
      await sblyApiService.delete('facebook-posts/remove-from-top-performing', {
        headers: props.headers,
        data: { posts: postsToRemove },
      });

      const remainingPosts = finishedPosts.filter(
        (post: any) => !postsToRemove.includes(post),
      );

      setFinishedPosts(remainingPosts);
      setPageInput('');
      setFacebookPage(pages[0]);
      setSelectionModel([]);
    } catch (error) {
      console.log(error);
    }

    setRemoveLoading(false);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ControlledDateRangePicker
        dateRange={[startDate, endDate]}
        onDateRangeChange={newRange => {
          if (newRange[0]) setStartDate(newRange[0]);
          if (newRange[1]) setEndDate(newRange[1]);
        }}
      />
      <Box sx={{ height: '490px', width: '100%', my: 2 }}>
        <DataGrid
          checkboxSelection
          loading={showFinishedLoader}
          rows={finishedPosts}
          columns={columns}
          pageSize={50}
          onSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
          selectionModel={selectionModel}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <RemoveButton
          loading={removeLoading}
          onClick={handleRemoveSelectedPosts}
        />
        <Stack direction="row" spacing={2}>
          <Autocomplete
            id="page"
            value={facebookPage}
            onChange={(event, value) => setFacebookPage(value!)}
            inputValue={pageInput}
            onInputChange={(event, newInputValue) => {
              setPageInput(newInputValue);
            }}
            options={pages}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="page" variant="outlined" />
            )}
            sx={{ width: 300 }}
          />
          <LoadingButton
            loading={showQueueLoader}
            disabled={facebookPage === pages[0]}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleAddToQueue}
            endIcon={<LibraryAddIcon />}
          >
            Queue
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}
