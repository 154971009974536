import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

interface LoadingProgressBackdropProps {
  open: boolean;
  label: string;
  progress: number;
}

function LoadingProgressBackdrop({
  open,
  label,
  progress,
}: LoadingProgressBackdropProps) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h6">{label}</Typography>
          <CircularProgress sx={{ ml: 2 }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 7,
              width: '50%',
              borderRadius: '0.5rem',
            }}
          />
        </Box>
      </Box>
    </Backdrop>
  );
}

export default LoadingProgressBackdrop;
