import { DashboardService } from 'services';

export interface blockingParams {
  accountID: string;
  operation: string;
  site: string;
  campaignID?: string;
}

class TaboolaDashboardService extends DashboardService {
  TABOOLA_BLOCK_SITES_ROUTE = `${this.DASHBOARD_ROUTE}/block-sites-taboola`;

  async blockSites({ accountID, campaignID, site, operation }: blockingParams) {
    try {
      const resp = await this.http.post(this.TABOOLA_BLOCK_SITES_ROUTE, {
        accountID,
        campaignID,
        site,
        operation,
      });
      return resp.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default TaboolaDashboardService;
