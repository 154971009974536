import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  stat: string | number;
}

function StatCard({ icon, label, stat }: StatCardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 2,
        borderRadius: 3,
        p: 2,
      }}
    >
      <Box
        width="4rem"
        height="4rem"
        mt={-4}
        sx={{
          background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          display: 'flex',
          width: '4rem',
          height: '4rem',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 1,
          borderRadius: '0.75rem',
          color: theme.palette.primary.contrastText,
        }}
      >
        <Icon fontSize="medium">{icon}</Icon>
      </Box>
      <Box textAlign="right">
        <Typography fontSize={15} variant="overline">
          {label}
        </Typography>
      </Box>
      <Divider light={true} />
      <Box textAlign="right">
        <Typography mt={1} variant="h4" fontWeight="light">
          {stat}
        </Typography>
      </Box>
    </Box>
  );
}

export default StatCard;
