import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FilterColumn, Operation } from 'pages/Dashboard/models/Settings';
import { Segment } from 'pages/Dashboard/models/CustomDimension';
import FilterSelection from '../filter/FilterSelection';
import convertPredicate from 'pages/Dashboard/utils/convertPredicate';

interface AddCustomDimensionsProps {
  filterOperations: Operation;
  columns: FilterColumn[];
  onCustomDimensionsChange: (newDimensions: Segment[]) => void;
}

function AddCustomDimensions({
  filterOperations,
  columns,
  onCustomDimensionsChange,
}: AddCustomDimensionsProps) {
  const [dimensionName, setDimensionName] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [segments, setSegments] = useState<Segment[]>([]);

  const handleSegmentUpdate = (
    filter: FilterColumn,
    predicate: string,
    value: string | number,
  ) => {
    setSegments([
      ...segments,
      {
        dimensionName: dimensionName,
        segmentName: segmentName,
        filterKey: predicate,
        columnKey: filter.key,
        columnName: filter.name,
        value: value,
      },
    ]);
  };

  const removeSegmentField = (idx: number) => {
    const segmentFields = [...segments];
    segmentFields.splice(idx, 1);
    setSegments(segmentFields);
  };

  const handleApplySegments = () => {
    onCustomDimensionsChange(segments);
    setDimensionName('');
    setSegmentName('');
    setSegments([]);
  };

  return (
    <Box>
      <Typography>Add Custom Dimensions</Typography>
      <div>
        <TextField
          id="dimension-name"
          label="Dimension Name"
          value={dimensionName}
          onChange={event => setDimensionName(event.target.value)}
          sx={{ width: 240, mt: 2 }}
        />
      </div>
      <div>
        <TextField
          id="segment-name"
          label="Segment Name"
          value={segmentName}
          onChange={event => setSegmentName(event.target.value)}
          sx={{ width: 240, mt: 2 }}
        />
      </div>
      <FilterSelection
        filters={columns}
        filterOperations={filterOperations}
        filterType="columns"
        onAddFilter={(filter, predicate, value) =>
          handleSegmentUpdate(filter, predicate, value)
        }
        label="segment"
      />
      {segments.map((seg, idx) => {
        return (
          <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">{`${seg.dimensionName}: ${
              seg.segmentName
            } (${seg.columnName} ${convertPredicate(seg.filterKey)} ${
              seg.value
            })`}</Typography>
            <IconButton
              aria-label="delete"
              onClick={event => removeSegmentField(idx)}
              size="small"
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      })}
      <DialogActions>
        <Button variant="contained" onClick={handleApplySegments}>
          Apply
        </Button>
      </DialogActions>
    </Box>
  );
}

export default AddCustomDimensions;
