import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import round from 'lodash/round';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';

import { arrayIncludesObject } from 'pages/Dashboard/utils/arrayHelpers';
import { Axes } from 'pages/Dashboard/components/graph/GraphButton';
import { CurrencyType, NumberType, PercentType } from 'utils/valueTypes';
import { formatLabel } from 'pages/Dashboard/utils/format';
import Alert from 'pages/Dashboard/components/helpers/Alert';
import GraphData, { GraphKeyType } from 'pages/Dashboard/models/GraphData';
import GraphKeysSelection from 'pages/Dashboard/components/graph/GraphKeysSelection';

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  LineElement,
  BarElement,
  Legend,
  PointElement,
  Title,
  Tooltip,
  AnnotationPlugin,
  LineController,
  BarController
);

function options(theme: any, chartCustomizations: any) {
  return {
    responsive: true,
    plugins: {
      ...(chartCustomizations?.title ? {
        title: {
          display: true,
          text: chartCustomizations.title,
          font: {
            size: 16
          }
        }
      } : {}),
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 16,
          },
        },
      },
      datalabels: {
        color: '#fff',
        font: { weight: 'bold' as const },
        backgroundColor: `${theme.palette.info.main}80`,
        align: 'top' as const,
        offset: 3,
        clip: true,
        display: 'auto' as const,
        formatter: (value: number) => round(value, 2),
      },
      ...(chartCustomizations?.annotations ? {
        annotation: {
           drawTime: 'beforeDatasetsDraw',
           annotations: chartCustomizations.annotations
        }
      } : {})
    },
    layout: { padding: 30 },
    scales: {
      x: { ticks: { autoSkipPadding: 10 }, offset: true },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        offset: true,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        offset: true,
      },
    },
  };
}

interface LineChartProps {
  graphData: GraphData;
  leftAxisKeys: GraphKeyType[];
  rightAxisKeys: GraphKeyType[];
  onAxesUpdate: (newAxes: Axes) => void;
  deleteGraph: () => void;
  deleteGraphDisabled: boolean;
  graphKeyTypes: GraphKeyType[];
  containerSX?: React.CSSProperties;
  chartCustomizations?: any;
}

function formDatasets(
  data: GraphData,
  leftAxis: GraphKeyType[],
  rightAxis: GraphKeyType[],
  presentMetrics: GraphKeyType[],
  theme: any,
) {
  const leftAxisGraphData = leftAxis
    .filter(metric => arrayIncludesObject(presentMetrics, metric))
    .map(({ name, graphType, borderColor, backgroundColor }) => {
      return {
        label: formatLabel(name),
        data: data[name],
        type: graphType ?? 'line' as const,
        borderColor: borderColor ?? theme.palette.primary.main,
        backgroundColor: backgroundColor ?? theme.palette.primary.light,
        yAxisID: 'y',
      };
    });
  const rightAxisGraphData = rightAxis
    .filter(metric => arrayIncludesObject(presentMetrics, metric))
    .map(({ name, graphType, borderColor, backgroundColor }) => {
      return {
        label: formatLabel(name),
        data: data[name],
        type: graphType ?? 'line' as const,
        borderColor: borderColor ?? theme.palette.secondary.main,
        backgroundColor: backgroundColor ?? theme.palette.secondary.light,
        yAxisID: 'y1',
      };
    });

  return [...leftAxisGraphData, ...rightAxisGraphData];
}

function LineChart({
  graphData,
  leftAxisKeys,
  rightAxisKeys,
  onAxesUpdate,
  deleteGraph,
  deleteGraphDisabled,
  graphKeyTypes,
  containerSX,
  chartCustomizations
}: LineChartProps) {
  const theme = useTheme();

  const [typesExceededErrorOpen, setTypesExceededErrorOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateGraph = (newMetricGraph: GraphKeyType) => {
    if (arrayIncludesObject(leftAxisKeys, newMetricGraph)) {
      const updatedKeys = leftAxisKeys.filter(
        leftKey => leftKey.name !== newMetricGraph.name,
      );
      onAxesUpdate({ leftAxis: updatedKeys, rightAxis: rightAxisKeys });
    } else if (arrayIncludesObject(rightAxisKeys, newMetricGraph)) {
      const updatedKeys = rightAxisKeys.filter(
        leftKey => leftKey.name !== newMetricGraph.name,
      );
      onAxesUpdate({ leftAxis: leftAxisKeys, rightAxis: updatedKeys });
    } else {
      if (
        leftAxisKeys.length === 0 ||
        newMetricGraph.type === leftAxisKeys[0].type
      ) {
        onAxesUpdate({
          leftAxis: [...leftAxisKeys, newMetricGraph],
          rightAxis: rightAxisKeys,
        });
      } else if (
        rightAxisKeys.length === 0 ||
        newMetricGraph.type === rightAxisKeys[0].type
      ) {
        onAxesUpdate({
          leftAxis: leftAxisKeys,
          rightAxis: [...rightAxisKeys, newMetricGraph],
        });
      } else {
        setTypesExceededErrorOpen(true);
      }
    }
  };

  return (
    <div style={containerSX || {}}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={typesExceededErrorOpen}
        autoHideDuration={10000}
        onClose={() => setTypesExceededErrorOpen(false)}
      >
        <Alert
          onClose={() => setTypesExceededErrorOpen(false)}
          severity="info"
          sx={{ width: '100%' }}
        >
          {`2 graph types are already selected. To view the additional type, 
          please unselect one metric type or add it to a new graph.`}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="outlined"
          endIcon={<EditIcon />}
          onClick={handlePopoverOpen}
        >
          edit visible metrics
        </Button>
        {
          deleteGraphDisabled ? 
          null :
            <Button
              variant="outlined"
              disabled={deleteGraphDisabled}
              sx={{
                ml: 1,
              }}
              endIcon={<DeleteOutlineIcon />}
              onClick={deleteGraph}
              color="secondary"
            >
              delete graph
            </Button>
        }
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={openPopover}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { borderRadius: '0.75rem', p: 1 } }}
      >
        {[CurrencyType, NumberType, PercentType].map(type => {
          return (
            <GraphKeysSelection
              key={type}
              leftAxisKeys={leftAxisKeys}
              rightAxisKeys={rightAxisKeys}
              graphKeyTypes={graphKeyTypes}
              keyType={type}
              handleUpdateGraph={handleUpdateGraph}
            />
          );
        })}
      </Popover>
      <Chart
        options={options(theme, chartCustomizations)}
        data={{
          labels: graphData.labels,
          datasets: formDatasets(
            graphData,
            leftAxisKeys,
            rightAxisKeys,
            graphKeyTypes,
            theme,
          ),
        }}
      />
    </div>
  );
}

export default LineChart;
