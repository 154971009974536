import React from 'react';
import { CampaignData, Adset } from './types';
import { joinInArray } from '../../utils';

import { makeStyles, Box, Typography } from '@material-ui/core';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  container: {
    padding: '10px',
  },
  campaignContainer: {
    fontSize: '13px',
    padding: '0 10px 0 10px',
  },
  campaignSummaryContainer: {
    marginTop: '10px',
    display: 'flex',
  },
  campaignName: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  image: {
    width: '30%',
    minWidth: '200px',
  },
  campaignSummary: {
    marginLeft: '10px',
  },
  adsetSummary: {
    marginLeft: '15px',
  },
}));

interface CampaignDisplayProps {
  campaignData: CampaignData | null;
  headline: string;
  primaryText: string;
  link: string;
}

export default function CampaignDisplay({
  campaignData,
  headline,
  primaryText,
  link,
}: CampaignDisplayProps) {
  const classes = useStyles();

  function imageSource({ ads }: Adset) {
    return ads[0]?.creative.image_url;
  }

  function adsetSecondaryTextElement({ ads, id, promoted_object }: Adset) {
    var pixelName;
    switch (promoted_object.pixel_id) {
      case '223096179960284':
        pixelName = 'Refiring Pixel';
        break;
      case '147680529123495':
        pixelName = 'Main V1';
        break;
      case '1527069297678889':
        pixelName = 'Main V2';
        break;
      default:
        pixelName = `Unknown (${promoted_object.pixel_id})`;
        break;
    }

    var conversionTarget = `Unable to Parse (${promoted_object.pixel_rule})`;

    const regexMatch = /(\d.*)"/.exec(promoted_object.pixel_rule);
    if (regexMatch) {
      const parsed = parseFloat(regexMatch[1]);
      const greaterThanZero = parsed > 0;
      const rpmValue = greaterThanZero ? parsed : parsed * 1000;
      conversionTarget = `${rpmValue} ${greaterThanZero ? 'eCPM' : 'RPM'}`;
    }
    return (
      <React.Fragment>
        <Typography component={'span'} variant="body2">
          {pixelName}
        </Typography>
        <br />
        {conversionTarget}
        <br />
        <br />
        Headline: {headline}
        <br />
        Primary Text: {primaryText}
        <br />
        Link: {link}
        <br />
      </React.Fragment>
    );
  }

  function adsetNameElements() {
    const listItemElements =
      campaignData?.adsets?.map(adset => {
        return (
          <ListItem alignItems="flex-start" key={adset.id}>
            <img
              alt="creative"
              className={classes.image}
              src={imageSource(adset)}
            ></img>
            <ListItemText
              className={classes.adsetSummary}
              primary={adset.name}
              secondary={adsetSecondaryTextElement(adset)}
            />
          </ListItem>
        );
      }) ?? [];

    function divider() {
      return <Divider key={Math.random()} variant="inset" component="li" />;
    }

    return joinInArray(listItemElements, divider);
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.subtitle}>
        Campaign Details
      </Typography>
      <Box className={classes.campaignContainer}>
        <Box>
          <Box className={classes.campaignName}>
            {campaignData?.campaign.name}
          </Box>
          <Box>{campaignData?.campaign.id}</Box>
        </Box>

        <Box className={classes.campaignSummaryContainer}>
          <Box className={classes.campaignSummary}>
            <Typography variant="body2">
              Total Adsets: {campaignData?.adsets.length}
            </Typography>
            <List>{adsetNameElements()}</List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
