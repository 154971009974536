import axios from 'axios';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.sbly.com/'
    : 'http://localhost:3000/';

export const sblyApiService = axios.create({
  baseURL: baseUrl,
});
