import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

import { sblyApiService } from 'pages/PostScheduler/service';

interface TimeslotProps {
  headers: { Authorization: string };
  setSuccess: (x: boolean) => void;
  setError: (x: boolean) => void;
  setErrorMessage: (x: string) => void;
}

interface PageTimeslot {
  id: string;
  pageId: string;
  pageName: string;
  timeslot: string;
  formattedPubTime: string;
  message: string;
  selected: boolean;
  pubTime?: string;
  page?: string;
}

interface Timeslot {
  id: string;
  timeslots: PageTimeslot[];
}

const useStyles = makeStyles(theme => ({
  linkTextField: {
    marginBottom: theme.spacing(3),
    width: 900,
  },
  messageTextField: {
    marginBottom: theme.spacing(3),
    width: 450,
  },
  timeslotContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageTime: {
    textAlign: 'right',
    marginRight: '40px',
    fontSize: '17px',
    color: '#525252',
  },
  pageName: {
    fontSize: '17px',
    color: '#525252',
  },
  pageGrid: {
    alignItems: 'center',
  },
  containerGrid: {
    height: '65px',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

export default function Timeslots(props: TimeslotProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const [selectedDate, handleDateChange] = useState<Date | null>(new Date());
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);
  const [presetGroup, setPresetGroup] = useState(0);
  const [selectedPresetGroup, setSelectedPresetGroup] =
    useState<PageTimeslot[]>();

  const handleLoadingBackdropClose = () => {
    setLoading(false);
  };

  const handlePresetChange = (event: SelectChangeEvent) => {
    const group = parseInt(event.target.value);
    setPresetGroup(group);
    setSelectedPresetGroup(cloneDeep(timeslots[group].timeslots));
  };

  const handleCheckboxToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    timeslotId: string,
  ) => {
    const updatedGroupWithSelected = selectedPresetGroup?.map(timeslot => {
      if (timeslot.id === timeslotId) {
        timeslot.selected = event.target.checked;
      }
      return timeslot;
    });

    setSelectedPresetGroup(updatedGroupWithSelected);
  };

  const handleMessageChange = (message: string, timeslotId: string) => {
    const updatedGroupWithMessages = selectedPresetGroup?.map(timeslot => {
      if (timeslot.id === timeslotId) {
        timeslot.message = message;
      }
      return timeslot;
    });

    setSelectedPresetGroup(updatedGroupWithMessages);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const selectedTimeslots = selectedPresetGroup?.filter(
      page => page.selected === true,
    );

    if (selectedTimeslots) {
      for (const pageTimeslot of selectedTimeslots) {
        if (pageTimeslot.message === '') {
          props.setError(true);
          props.setErrorMessage(
            `Please add a message for the ${pageTimeslot.pageName} page.`,
          );
          setLoading(false);
          return;
        }
        const pubTime = moment
          .tz(
            selectedDate?.toDateString() + ' ' + pageTimeslot.timeslot,
            'ddd MMM DD YYYY HH:mm',
            'America/Los_Angeles',
          )
          .utc()
          .format()
          .slice(0, -1);

        if (new Date(pubTime) < new Date()) {
          props.setError(true);
          props.setErrorMessage('Please select a valid date.');
          setLoading(false);
          return;
        }

        pageTimeslot.pubTime = pubTime;
        pageTimeslot.page = pageTimeslot.pageId;
      }
    }

    const data = {
      link,
      details: selectedTimeslots,
    };

    let failed: [];
    let resp: any;
    try {
      resp = await sblyApiService.post(
        'facebook-videos/schedule-videos',
        data,
        {
          headers: props.headers,
        },
      );
      ({ failed } = resp.data);
      if (failed.length === 0) {
        setLink('');
        setPresetGroup(0);
        setSelectedPresetGroup(cloneDeep(timeslots[0].timeslots));
        props.setSuccess(true);
      } else {
        props.setError(true);
      }
    } catch (e) {
      console.log(`Error from API: ${e}`);
      props.setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getTimeslots() {
      const resp = await sblyApiService.get('facebook-videos/timeslots', {
        headers: props.headers,
      });

      const timeslots = resp.data;
      timeslots.forEach((timeslot: Timeslot) =>
        timeslot.timeslots.forEach(page => {
          page.message = '';
          page.selected = true;
        }),
      );

      return timeslots;
    }

    getTimeslots().then(timeslots => {
      setTimeslots(timeslots);
      setPresetGroup(0);
      setSelectedPresetGroup(cloneDeep(timeslots[0].timeslots));
    });
  });

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleLoadingBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit}>
        <TextField
          autoComplete="off"
          required
          id="link"
          name="link"
          className={classes.linkTextField}
          value={link}
          type="url"
          label="video link"
          onChange={e => setLink(e.target.value)}
        />
        <div>
          <Fragment>
            <KeyboardDatePicker
              clearable
              value={selectedDate}
              onChange={date => handleDateChange(date)}
              minDate={new Date()}
              format="MM/dd/yyyy"
            />
          </Fragment>
        </div>
        <Box sx={{ width: 230, marginTop: 3 }}>
          <FormControl variant="standard" fullWidth>
            <Select
              labelId="preset-label"
              id="preset-select"
              value={presetGroup.toString()}
              onChange={handlePresetChange}
            >
              <MenuItem value={0}>Preset 1</MenuItem>
              <MenuItem value={1}>Preset 2</MenuItem>
              <MenuItem value={2}>Preset 3</MenuItem>
              <MenuItem value={3}>Preset 4</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {selectedPresetGroup?.map(page => (
          <Box key={page.id} className={classes.timeslotContainer}>
            <Grid container spacing={2} className={classes.containerGrid}>
              <Grid item xs={1}>
                <Checkbox
                  checked={page.selected}
                  onChange={e => handleCheckboxToggle(e, page.id)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item xs={3}>
                <div className={classes.pageName}>{page.pageName}</div>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.pageTime}>{page.formattedPubTime}</div>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete="off"
                  id="message"
                  name="message"
                  className={classes.messageTextField}
                  value={page.message}
                  type="text"
                  label="message"
                  onChange={e => handleMessageChange(e.target.value, page.id)}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        <Button
          variant="contained"
          type="submit"
          endIcon={<PostAddIcon />}
          sx={{ mt: 2 }}
        >
          Schedule
        </Button>
      </form>
    </div>
  );
}
