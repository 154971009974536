import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { CurrencyType } from 'utils/valueTypes';
import { formatLabel } from 'pages/Dashboard/utils/format';
import { formatPercentToDecimal } from 'utils/formatNumber';
import CustomNumericInput from 'pages/Creator/components/CustomNumericInput';
import MetricValueTypes from 'pages/Dashboard/utils/mappers/MetricValueTypes';
import SummaryData from 'pages/Dashboard/models/SummaryData';

interface ThresholdGroupProps {
  metrics: (keyof SummaryData)[];
  metricName?: keyof SummaryData;
  predicate?: '>' | '<';
  value?: number;
  onUpdate: (
    key: 'metric' | 'predicate' | 'value',
    value: string | number,
  ) => void;
}

function ThresholdGroup({
  metrics,
  metricName,
  predicate,
  value,
  onUpdate,
}: ThresholdGroupProps) {
  const selectedMetric = metricName || metrics[0];
  const selectedMetricType = MetricValueTypes[selectedMetric];

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mr: 1 }}>
        <FormControl>
          <InputLabel>Metric</InputLabel>
          <Select
            size="small"
            labelId="threshold-metric"
            id="threshold-metric"
            value={selectedMetric}
            label="metric"
            onChange={(event: SelectChangeEvent) =>
              onUpdate('metric', event.target.value)
            }
            sx={{ width: 150 }}
          >
            {metrics.map(metric => {
              return (
                <MenuItem key={metric} value={metric}>
                  {formatLabel(metric)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <ToggleButtonGroup
        size="small"
        value={predicate || '<'}
        exclusive
        onChange={(e, newValue) => {
          onUpdate('predicate', newValue);
        }}
      >
        <ToggleButton key="high" value=">">
          <KeyboardArrowRightIcon />
        </ToggleButton>
        <ToggleButton key="low" value="<">
          <KeyboardArrowLeftIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ mx: 1 }}>
        {selectedMetricType === CurrencyType ? (
          <TextField
            id="threshold-value-currency"
            label="value"
            value={value || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onUpdate('value', event.target.value);
            }}
            InputProps={{
              inputComponent: CustomNumericInput as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            size="small"
            sx={{ width: 120 }}
          />
        ) : (
          <TextField
            id="threshold-value-percent"
            label="value"
            value={value ? value * 100 : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onUpdate('value', formatPercentToDecimal(+event.target.value));
            }}
            InputProps={{
              inputComponent: CustomNumericInput as any,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputProps={{
              decimalScale: 0,
            }}
            size="small"
            sx={{ width: 120 }}
          />
        )}
      </Box>
    </Box>
  );
}

export default ThresholdGroup;
