import _ from 'lodash';
import moment from 'moment';

export function formatNameValue(
  name: string,
  value: any,
  timeLabels: string[] = [],
) {
  const formatAsPercentage = (value: number) => {
    return `${_.round(value * 100, 2)}%`;
  };

  const formatAsCurrency = (value: number) => {
    return `$${_.round(value, 2)}`;
  };

  const formatAsArrayOvertime = (formatFn: any, timeLabels: string[]) => {
    return (values: any[]) => {
      const reversed = values.toReversed();
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: `${85 * timeLabels.length}px`,
            marginLeft: 'auto',
          }}
        >
          {reversed.map((value, index) => (
            <div>
              <div>{formatFn(value)}</div>
              <div style={{ fontSize: '12px', color: 'gray' }}>
                {timeLabels[index]}
              </div>
            </div>
          ))}
        </div>
      );
    };
  };

  const formatAsTimeArray = (arr: string[]) => {
    const reversed = arr.toReversed();
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: `${85 * arr.length}px`,
          marginLeft: 'auto',
        }}
      >
        {reversed.map((value, index) => {
          const prevValue = reversed[index - 1];
          const showDate = prevValue
            ? moment(prevValue).format('MM/DD/YYYY') !==
              moment(value).format('MM/DD/YYYY')
            : true;
          return (
            <div>
              {showDate ? <div>{moment(value).format('MM/DD/YYYY')}</div> : '-'}
              <div>{moment(value).format('ha')}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const NameMapping: { [key: string]: string } = {
    'Should Scale': 'Eligible To Scale',
    'Candidate Inactive': 'Active',
    'Low Spend Per Hour Treshold': 'Low Spend Per Hour Benchmark',
    'Expected Spend Per Hour': 'Target Spend Per Hour',
    'Averaged Spend Per Hour': 'Hourly Budget (Estimated)',
    'Weighted Profit Margin Since Last Change': 'W. Profit Margin (SLC)',
    'Weighted Profit Since Last Change': 'W. Profit (SLC)',
    'Spend Since Last Change': 'Spend (SLC)',
    'Clicks Since Last Change': 'Clicks (SLC)',
  };

  const ValueMapping: { [key: string]: (value: any) => any } = {
    'Candidate Inactive': (value: boolean) => {
      return (!value).toString();
    },
    'Min. Target Spend Per Hour': formatAsCurrency,
    'Min Margin To Increase': formatAsPercentage,
    'Min Margin To Decrease': formatAsPercentage,
    'Lowest Base Budget': formatAsCurrency,
    'Projected Margin': formatAsPercentage,
    'Profit Margin Today': formatAsPercentage,
    'Weighted Profit Margin Since Last Change': formatAsPercentage,
    'Min Profit Margin Threshold To Decrease Bid': formatAsPercentage,
    'Min Profit Margin Threshold To Increase Bid': formatAsPercentage,
    'Proposed % Bid Adjustment (Encourage Spend)': formatAsPercentage,
    'Prorated Utilization Today': formatAsPercentage,
    'Max Decrease %': formatAsPercentage,
    'Max Increase %': formatAsPercentage,
    'Proposed % Bid Adjustment (Increase)': formatAsPercentage,
    'Final % Bid Adjustment': formatAsPercentage,
    'Decrease Budget Adjustment %': formatAsPercentage,
    'Percent of Day Completed': formatAsPercentage,
    'Baseline Min. Spend To Change Bids': formatAsCurrency,
    'Last 3 Days Profit': formatAsCurrency,
    'Next Bid Value': formatAsCurrency,
    'Prefinalized Adjustment': formatAsPercentage,
    'Start Of Day Budget': formatAsCurrency,
    'Last 3 Days Spend': formatAsCurrency,
    'Lowest Budget': formatAsCurrency,
    '% of Starting Budget': formatAsPercentage,
    'Next Budget': formatAsCurrency,
    'Max Bid Increase This Run': formatAsCurrency,
    'Bid Amount @ Day Start': formatAsCurrency,
    'Max Bid Bump Daily': formatAsCurrency,
    'Max Bid Bump For Low Spend Daily': formatAsCurrency,
    'Max Bid Cap': formatAsCurrency,
    'Max Bid Decrease This Run': formatAsCurrency,
    'Bid Difference From Start of Day': formatAsCurrency,
    'Current Bid Amount': formatAsCurrency,
    'Low Spend Per Hour Treshold': formatAsCurrency,
    'Expected Spend Per Hour': formatAsCurrency,
    'Averaged Spend Per Hour': formatAsCurrency,
    'Daily Budget': formatAsCurrency,
    'Campaign Daily Budget': formatAsCurrency,
    'Spend Per Hour': formatAsCurrency,
    'Spend Today': formatAsCurrency,
    'Spend Since Last Change': formatAsCurrency,
    'Profit Margin Since Last Change': formatAsPercentage,
    'Weighted Profit Since Last Change': formatAsCurrency,
    'Profit Today': formatAsCurrency,
    'Final Budget Value': formatAsCurrency,
    'Profit History by Day': formatAsArrayOvertime(
      formatAsCurrency,
      timeLabels,
    ),
    'Spend History By Day': formatAsArrayOvertime(formatAsCurrency, timeLabels),
    'Profit Margin History By Day': formatAsArrayOvertime(
      formatAsPercentage,
      timeLabels,
    ),
    'Hour by Hour': formatAsTimeArray,
  };

  var generalizedFormat = (value: any) => value.toString();
  if (_.some(['%'], keyPart => name.includes(keyPart))) {
    generalizedFormat = formatAsPercentage;
  } else if (_.some(['%'], keyPart => name.includes(keyPart))) {
    generalizedFormat = formatAsCurrency;
  }

  return {
    name: NameMapping[name] ?? name,
    value:
      ValueMapping[name]?.(value) ??
      ValueMapping[NameMapping[name]]?.(value) ??
      generalizedFormat?.(value),
  };
}
