import React from 'react';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface CustomToggleProps<T> {
  selectedValue: T;
  values: T[];
  onValueUpdate: (account: T) => void;
}

function CustomToggle<T extends string>({
  selectedValue,
  values,
  onValueUpdate,
}: CustomToggleProps<T>) {
  const handleUpdate = (
    event: React.MouseEvent<HTMLElement>,
    newValue: T,
  ) => {
    if (newValue !== null) onValueUpdate(newValue);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 1,
      }}
    >
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={handleUpdate}
        color="primary"
        sx={{ ml: 1 }}
      >
        {values.map(currValue => {
          return (
            <ToggleButton key={currValue} value={currValue}>
              {currValue}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}

export default CustomToggle;
