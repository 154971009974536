import React from 'react';
import _ from 'lodash';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';

import PerformanceData from 'pages/Dashboard/models/PerformanceData';

interface FBAdsManagerButtonProps {
  metrics: PerformanceData[];
  selected: string[];
}

function FBAdsManagerButton({ metrics, selected }: FBAdsManagerButtonProps) {
  const handleOpenFBAdsManager = () => {
    const fbAdsManagerLink =
      'https://adsmanager.facebook.com/adsmanager/manage/adsets/?';

    const selectedRows = selected.map(selectedID => {
      return _.find(metrics, metric => metric.id === selectedID);
    });
    selectedRows.forEach(row => {
      window.open(
        `${fbAdsManagerLink}act=${row?.account_id}&selected_campaign_ids=${row?.campaign_id}&selected_adset_ids=${row?.adset_id}`,
      );
    });
  };

  return (
    <IconButton onClick={handleOpenFBAdsManager}>
      <FacebookIcon />
    </IconButton>
  );
}

export default FBAdsManagerButton;
