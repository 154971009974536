import React, { memo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { FacebookCreationTemplate } from 'pages/Creator/models/FacebookCreationTemplate';

interface CustomFormProps {
  template: FacebookCreationTemplate;
  onFormUpdate: (
    field: keyof FacebookCreationTemplate,
    newValue: string,
  ) => void;
}

function CustomForm({ template, onFormUpdate }: CustomFormProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        fullWidth
        id="campaign-name"
        label="Campaign Name"
        value={template.campaignName}
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onFormUpdate('campaignName', event.target.value);
        }}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        id="title"
        label="Title"
        value={template.title}
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onFormUpdate('title', event.target.value);
        }}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        id="caption"
        label="Caption"
        value={template.caption}
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onFormUpdate('caption', event.target.value);
        }}
        sx={{ mb: 2 }}
      />
    </Box>
  );
}

export default memo(CustomForm);
