import React, { useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TextField from '@mui/material/TextField';

import { pages } from '../fields/pages';
import { sblyApiService } from '../../service';
import Loader from './Loader';

interface ReschedulePostsProps {
  headers: { Authorization: string };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '70px',
      width: '100%',
    },
    rightBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    containerBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dropdown: {
      display: 'flex',
      width: '35ch',
      marginRight: theme.spacing(1),
    },
    button: {
      height: '54px',
      width: '210px',
      marginRight: theme.spacing(1),
    },
  }),
);

export default function ReschedulePosts(props: ReschedulePostsProps) {
  const classes = useStyles();

  const [pageInput, setPageInput] = useState('');
  const [facebookPage, setFacebookPage] = useState(pages[0]);
  const [showLoader, setLoader] = useState(false);

  async function handleReschedulePosts() {
    setLoader(true);

    const data = {
      page: facebookPage.id,
    };
    await sblyApiService.post('facebook-posts/reschedule-posts', data, {
      headers: props.headers,
    });

    setFacebookPage(pages[0]);
    setLoader(false);
  }

  return (
    <div className={classes.root}>
      <Box component="span" className={classes.containerBox}>
        <Box component="span" className={classes.rightBox}>
          <Autocomplete
            className={classes.dropdown}
            id="page"
            value={facebookPage}
            onChange={(event, value) => setFacebookPage(value!)}
            inputValue={pageInput}
            onInputChange={(event, newInputValue) => {
              setPageInput(newInputValue);
            }}
            options={pages}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField {...params} label="page" variant="outlined" />
            )}
          />
          {showLoader ? (
            <Button className={classes.button}>
              <Loader />
            </Button>
          ) : (
            <Button
              className={classes.button}
              disabled={facebookPage === pages[0]}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleReschedulePosts}
              endIcon={<PostAddIcon />}
            >
              Reschedule Posts
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}
