import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import moment from 'moment';

interface TimePickerProps {
  onChange: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '53px',
      width: '100px',
      marginLeft: theme.spacing(1),
    },
    timePicker: {
      width: '142px',
    },
  }),
);

export default function TimePicker(props: TimePickerProps) {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState<Date | null>(new Date());

  function handleSubmit() {
    const time = selectedTime?.toTimeString().split(' ')[0];
    props.onChange(moment(time, 'HH:mm:ss').format('LT'));
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          label="New slot (PST)"
          value={selectedTime}
          onChange={newTime => {
            setSelectedTime(newTime);
          }}
          renderInput={params => (
            <TextField className={classes.timePicker} {...params} />
          )}
        />
      </LocalizationProvider>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleSubmit}
        endIcon={<AddCircleOutlineIcon />}
        sx={{ ml: 2 }}
      >
        Add
      </Button>
    </div>
  );
}
