import find from 'lodash/find';

import { dashboardService } from 'pages/Dashboard';
import { formatAsPercent, formatCurrency } from 'utils/formatNumber';
import SummaryData from 'pages/Dashboard/models/SummaryData';
import MetricValueTypes from 'pages/Dashboard/utils/mappers/MetricValueTypes';
import { CurrencyType, PercentType } from 'utils/valueTypes';

export function formatLabel(name: string) {
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getMetricName(metricKey: string) {
  const upperKey = metricKey.toUpperCase();
  const metricKeys = dashboardService.settings.metrics.map(
    metric => metric.key,
  );
  const dimensionKeys = dashboardService.settings.dimensions.map(
    dimension => dimension.key,
  );
  if (metricKeys.includes(upperKey)) {
    const metric = find(
      dashboardService.settings.metrics,
      setting => upperKey === setting.key,
    );
    return metric!.name;
  } else if (dimensionKeys.includes(upperKey)) {
    const dimension = find(
      dashboardService.settings.dimensions,
      setting => upperKey === setting.key,
    );
    return dimension!.name;
  } else {
    return metricKey
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}

export function formatMetric(
  metricKey: keyof SummaryData,
  metricValue: number | undefined,
  valueOnly?: boolean,
) {
  if (metricValue === undefined)
    return [metricKey, 'Please include in selected metrics to see summary.'];

  let value = metricValue.toLocaleString('en-US');
  const valueType = MetricValueTypes[metricKey];
  if (metricKey === 'cost_per_click') {
    value = `$${metricValue.toFixed(3)}`;
  } else if (valueType === CurrencyType) {
    value = formatCurrency(metricValue);
  } else if (valueType === PercentType) {
    value = formatAsPercent(metricValue);
  }

  if (valueOnly) return value;

  const metricName = getMetricName(metricKey);
  return [metricName, value, valueType];
}
