import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface SelectImagesProps {
  images: Array<File>;
  onChange: (newImages: Array<File>) => void;
}

function SelectImages({ images, onChange }: SelectImagesProps) {
  const handleRemove = (index: number) => {
    const filtered = images.filter((_, imageIndex) => imageIndex !== index);
    onChange(filtered);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (files) {
      onChange([...images, ...Array.from(files)]);
    }
  };

  const imageElements = () => {
    return images.map((image, index) => {
      const imageURL = URL.createObjectURL(image);
      return (
        <Box key={index} sx={{ display: 'inline-block', position: 'relative' }}>
          <IconButton
            onClick={() => handleRemove(index)}
            sx={{
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              backgroundColor: '#ffffff',
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            alt="campaign-cover"
            src={imageURL}
            style={{ maxHeight: '200px', maxWidth: '350px' }}
          />
          <Tooltip title={image.name}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                noWrap
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  flexGrow: 1,
                  width: 0,
                }}
              >
                {image.name}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      );
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          mt: '15px',
          minWidth: '250px',
          alignItems: 'center',
        }}
      >
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            multiple
            onChange={handleChange}
          />
          <Button
            className="btn-choose"
            variant="outlined"
            color="secondary"
            component="span"
            onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                const inputElement = event.target as HTMLInputElement;
                inputElement.click();
              }
            }}
          >
            Choose Images
          </Button>
        </label>
      </Box>
      {images.length !== 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
            columnGap: '25px',
            rowGap: '25px',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {imageElements()}
        </Box>
      )}
    </Box>
  );
}

export default SelectImages;
